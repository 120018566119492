import { CLIENT_ACTIVE } from './constants';
import { ajaxPromise, confirmDialog, displayNotification, getDtRowData, highlight, logerror, setTimeoutPromise } from './utils';

if ($('#superadmin_container').length) {
	const initBackups = () => {
		const backupsDtOptions: DataTables.Settings = {
			ajax: {
				url: '/superadmin/datatable/backups',
			},
			buttons: [
				{
					text: '<i class="fa fa-plus" style="margin-right: 1rem;"></i>Add Backup',
					className: 'add ml-2 btn btn-sm btn-success',
					action: () => editBackup(),
				},
			],
			columns: [
				{
					title: 'Client',
					data: 'cli_name',
				},
				{
					title: 'Backup',
					data: 'bkup_meta',
				},
				{
					title: 'Actions',
					data: 'buttons',
					className: 'text-right',
					orderable: false,
					searchable: false,
				},
			],
		};

		const backupsDt = $('#superadmin_backups_table').DataTable(backupsDtOptions);

		$('#superadmin_backups_table').on('click', '.backup_edit', ({ target }) => {
			const backup = getDtRowData(backupsDt, target);
			editBackup(backup);
		});

		const resetBackupForm = () => {
			$('#superadmin_backup_meta').val('');
			$('#superadmin_backup_submit').off('click');
			$('#superadmin_backup_delete').off('click').hide();
		};

		const editBackup = (backup = null) => {
			resetBackupForm();
			setTimeout(() => {
				highlight($('#superadmin_backup_form'));
				$(window).scrollTop($('#superadmin_backup_form').offset()!.top - 400);
			}, 400);

			if (backup) {
				$('#superadmin_backup_client').val(backup.cli_id);
				$('#superadmin_backup_meta').val(backup.bkup_meta);
				$('#superadmin_backup_key').val(backup.bkup_key);

				$('#superadmin_backup_delete').show().on('click', async () => {
					const confirmed = await confirmDialog({
						dialogTitle: 'Backup Delete',
						bodyText: 'Are you sure you would like to delete this Backup?',
						confirmText: 'Delete',
						confirmStyle: 'danger',
					});
					if (!confirmed) return;

					try {
						const res = await ajaxPromise('/superadmin/submit/backup_delete', {data: JSON.stringify({bkup_id: +backup.bkup_id})});
						if (res.rc !== 'OK') throw res;

						displayNotification('Backup Delete', 'Backup deleted successfully.', 'success');
						backupsDt.ajax.reload();
						$('#superadmin_backup_form').collapse('hide');
					} catch (error) {
						displayNotification('Backup Delete', 'There was an error deleting this backup.', 'danger');
						logerror('backup delete', error);
					}
				});
			}

			$('#superadmin_backup_submit').on('click', async () => {
				$('#superadmin_backup_submit').prop('disabled', true);

				try {
					const postData = {
						bkup_id: backup ? +backup.bkup_id : null,
						client: +$('#superadmin_backup_client').val() || null,
						meta: $('#superadmin_backup_meta').val().toString().trim(),
						key: $('#superadmin_backup_key').val().toString().trim(),
					};
					const res = await ajaxPromise('/superadmin/submit/backup_save', {data: JSON.stringify(postData)});
					if (res.rc !== 'OK') throw Error(res);

					displayNotification('Backup Save', 'Backup saved successfully.', 'success');
					backupsDt.ajax.reload();
					$('#superadmin_backup_form').collapse('hide');
				} catch (error) {
					switch (error.message) {
						default:
							displayNotification('Backup Save', 'There was an error saving this backup.', 'danger');
							break;
					}
					logerror('backup submit', error);
				}

				$('#superadmin_backup_submit').prop('disabled', false);
			});

			$('#superadmin_backup_form').collapse('show');
		};

		$('#superadmin_backup_form').on('hidden.bs.collapse', () => resetBackupForm());

		$('#superadmin_backup_cancel').off('click').on('click', () => $('#superadmin_backup_form').collapse('hide'));
	};

	$('#superadmin_backups_tab').one('show.bs.tab', () => initBackups());

	const initClients = () => {
		const clientsDtOptions: DataTables.Settings = {
			ajax: {
				url: '/superadmin/datatable/clients',
			},
			buttons: [
				{
					text: '<i class="fa fa-plus" style="margin-right: 1rem;"></i>Add Client',
					className: 'add ml-2 btn btn-sm btn-success',
					action: () => editClient(),
				},
				{
					text: '<i class="fa fa-refresh me-2"></i> Refresh',
					className: 'add ml-2 btn btn-sm btn-primary',
					action: () => clientsDt.ajax.reload(),
				},
			],
			columns: [
				{
					title: 'Name',
					data: 'cli_name',
				},
				{
					title: 'Status',
					data: 'cli_status_pretty',
				},
				{
					title: 'Document Storage',
					data: 'docs_size_pretty',
					type: 'num',
					render: (data, type, row) => type === 'sort' ? row.docs_size : data,
				},
				{
					title: 'Actions',
					data: 'buttons',
					className: 'text-right',
					orderable: false,
					searchable: false,
				},
			],
			language: {
				infoEmpty: 'Refresh to view data',
			},
		};

		const clientsDt = $('#superadmin_clients_table').DataTable(clientsDtOptions);

		$('#superadmin_clients_table').on('click', '.edit', ({ target }) => {
			const client = getDtRowData(clientsDt, target);
			editClient(client);
		});

		const resetClientForm = () => {
			$('#superadmin_client_name').val('');
			$('#superadmin_client_status').val(CLIENT_ACTIVE);
			$('#superadmin_client_subdomain').val('');
			$('#superadmin_client_domain').val('');
			$('#superadmin_client_dbinfo').val('');
			$('#superadmin_client_form').off('submit');
		};

		const editClient = (client = null) => {
			resetClientForm();
			setTimeout(() => {
				highlight($('#superadmin_client_form'));
				$(window).scrollTop($('#superadmin_client_form').offset()!.top - 400);
			}, 400);

			if (client) {
				$('#superadmin_client_name').val(client.cli_name);
				$('#superadmin_client_status').val(client.cli_status);
				$('#superadmin_client_subdomain').val(client.cli_subdomain);
				$('#superadmin_client_domain').val(client.cli_domain);
				$('#superadmin_client_dbinfo').val(client.cli_dbinfo);
			}

			$('#superadmin_client_form').on('submit', async (event) => {
				event.preventDefault();

				$('#superadmin_client_submit').prop('disabled', true);

				try {
					const postData = {
						cli_id: client ? +client.cli_id : null,
						cli_name: $('#superadmin_client_name').val().toString().trim(),
						cli_status: +$('#superadmin_client_status').val().toString().trim(),
						cli_subdomain: $('#superadmin_client_subdomain').val().toString().trim(),
						cli_domain: $('#superadmin_client_domain').val().toString().trim(),
						cli_dbinfo: $('#superadmin_client_dbinfo').val().toString().trim(),
					};
					const res = await ajaxPromise('/superadmin/submit/client_save', {data: JSON.stringify(postData)});
					if (res.rc !== 'OK') throw Error(res);

					displayNotification('Client Save', 'Client saved successfully.', 'success');
					clientsDt.ajax.reload();
					$('#superadmin_client_form').collapse('hide');
				} catch (error) {
					switch (error.message) {
						default:
							displayNotification('Client Save', 'There was an error saving this client.', 'danger');
							break;
					}
					logerror('client submit', error);
				}

				$('#superadmin_client_submit').prop('disabled', false);
			});

			$('#superadmin_client_form').collapse('show');
		};

		$('#superadmin_client_form').on('hidden.bs.collapse', () => resetClientForm());

		$('#superadmin_client_cancel').off('click').on('click', () => $('#superadmin_client_form').collapse('hide'));
	};

	initClients();

	const initUsers = () => {
		const usersDtOptions: DataTables.Settings = {
			ajax: {
				url: '/superadmin/datatable/users',
			},
			buttons: [
				{
					text: '<i class="fa fa-plus" style="margin-right: 1rem;"></i>Add User',
					className: 'add ml-2 btn btn-sm btn-success',
					action: () => editUser(),
				},
			],
			columns: [
				{
					title: 'Name',
					data: 'admin_name',
				},
				{
					title: 'Email',
					data: 'admin_email',
				},
				{
					title: 'Actions',
					data: 'buttons',
					className: 'text-right',
					orderable: false,
					searchable: false,
				},
			],
		};

		const usersDt = $('#superadmin_users_table').DataTable(usersDtOptions);

		$('#superadmin_users_table').on('click', '.edit', ({ target }) => {
			const user = getDtRowData(usersDt, target);
			editUser(user);
		});

		const resetUserForm = () => {
			$('#superadmin_user_first').val('');
			$('#superadmin_user_last').val('');
			$('#superadmin_user_email').val('');
			$('#superadmin_user_form').off('submit');

			$('#superadmin_user_pass_cont').hide();
			$('#superadmin_user_old_pass').val('');
			$('#superadmin_user_pass').val('').trigger('keyup');
			$('#superadmin_user_pass_confirm').val('');
		};

		const editUser = (user = null) => {
			resetUserForm();
			setTimeout(() => {
				highlight($('#superadmin_user_form'));
				$(window).scrollTop($('#superadmin_user_form').offset()!.top - 400);
			}, 400);

			if (user) {
				$('#superadmin_user_first').val(user.admin_first);
				$('#superadmin_user_last').val(user.admin_last);
				$('#superadmin_user_email').val(user.admin_email);

				if (user.is_self) {
					$('#superadmin_user_pass_cont').show();

					$('#superadmin_user_pass_meter').zxcvbnProgressBar({
						passwordInput: '#superadmin_user_pass',
						scoreInput: '#superadmin_user_pass_score',
						warningTarget: '#superadmin_user_pass_warning',
						suggestionsTarget: '#superadmin_user_pass_suggestions',
					});

					$('#superadmin_user_pass, #superadmin_user_pass_confirm').on('blur', async ({target}) => {
						await setTimeoutPromise(10);
						if ((target.id == 'superadmin_user_pass' && !$('#superadmin_user_pass_confirm').is(':focus')) || target.id == 'superadmin_user_pass_confirm') {
							if ($('#superadmin_user_pass').val() != '' && +$('#superadmin_user_pass_score').val() < 2) {
								$('#superadmin_user_pass').closest('.form-group').find('[class^=col-]').addClass('has-error');
								$('#superadmin_user_pass_help').html('The password is not strong enough.');
							} else if ($('#superadmin_user_pass').val() != $('#superadmin_user_pass_confirm').val()) {
								$('#superadmin_user_pass').closest('.form-group').find('[class^=col-]').addClass('has-error');
								$('#superadmin_user_pass_help').html("The passwords you've entered do not match.");
							} else {
								$('#superadmin_user_pass').closest('.form-group').find('[class^=col-]').removeClass('has-error');
								$('#superadmin_user_pass_help').html('<strong>OPTIONAL:</strong> If you would like to change your password, fill out all the password fields.');
							}
						}
					});
				}
			}

			$('#superadmin_user_form').on('submit', async (event) => {
				event.preventDefault();

				$('#superadmin_user_submit').prop('disabled', true);

				try {
					const postData = {
						admin_id: user ? +user.admin_id : null,
						first: $('#superadmin_user_first').val().toString().trim(),
						last: $('#superadmin_user_last').val().toString().trim(),
						email: $('#superadmin_user_email').val().toString().trim(),
						old_pass: $('#superadmin_user_old_pass').val().toString().trim(),
						pass: $('#superadmin_user_pass').val().toString().trim(),
						pass_confirm: $('#superadmin_user_pass_confirm').val().toString().trim(),
					};
					const res = await ajaxPromise('/superadmin/submit/user_save', {data: JSON.stringify(postData)});
					if (res.rc !== 'OK') throw Error(res);

					displayNotification('User Save', 'User saved successfully.', 'success');
					usersDt.ajax.reload();
					$('#superadmin_user_form').collapse('hide');
				} catch (error) {
					switch (error.message) {
						default:
							displayNotification('User Save', 'There was an error saving this user.', 'danger');
							break;
					}
					logerror('superadmin user submit', error);
				}

				$('#superadmin_user_submit').prop('disabled', false);
			});

			$('#superadmin_user_form').collapse('show');
		};

		$('#superadmin_user_form').on('hidden.bs.collapse', () => resetUserForm());

		$('#superadmin_user_cancel').off('click').on('click', () => $('#superadmin_user_form').collapse('hide'));
	};

	$('#superadmin_users_tab').one('show.bs.tab', () => initUsers());
}

if ($('#superadmin_login_form').length) {
	$('#superadmin_login_form').on('submit', async (event) => {
		event.preventDefault();

		$('#superadmin_login_submit').prop('disabled', true);

		try {
			const postData = {
				email: $('#superadmin_login_form_email').val().toString().trim(),
				password: $('#superadmin_login_form_password').val().toString().trim(),
			};
			const res = await ajaxPromise('/login/superadmin/login', {data: JSON.stringify(postData)});
			if (res.rc !== 'OK') throw res;

			window.location.reload();
		} catch (error) {
			switch (error.rc) {
				case 'BAD_LOGIN':
					displayNotification('Login', 'Login failed.', 'danger');
					break;
				default:
					displayNotification('Login', 'There was an error logging in.', 'danger');
					logerror('superadmin login submit', error);
			}
		}

		$('#superadmin_login_submit').prop('disabled', false);
	});

	$('#superadmin_login_form_forgot').off('click').on('click', (event) => {
		event.preventDefault();
		$('#superadmin_login_forgot_email').val($('#superadmin_login_form_email').val());
		$('#superadmin_login_forgot_modal').modal('show');

		$('#superadmin_login_forgot_send').off('click').on('click',  async () => {
			if ($('#superadmin_login_forgot_email').val().toString() == '') {
				displayNotification('Forgot Password', 'Please enter the email address your account was registered with.', 'danger');
				return;
			}

			$('#superadmin_login_forgot_send').prop('disabled', true);

			try {
				const postData = {
					email: $('#superadmin_login_forgot_email').val().toString().trim(),
				};
				const res = await ajaxPromise('/login/superadmin/forgot_password', {data: JSON.stringify(postData)});
				if (res.rc !== 'OK') throw res;

				displayNotification('Forgot Password', 'Temporary password has been sent.', 'info');
				$('#superadmin_login_forgot_modal').modal('hide');
			} catch (error) {
				displayNotification('Forgot Password', 'An error occurred while sending this request.', 'danger');
				logerror('forgotpassword', error);
			}

			$('#superadmin_login_forgot_send').prop('disabled', false);
		});
	});
}

if ($('#superadmin_mfa_form').length) {
	$('#superadmin_mfa_form').off('submit').on('submit', async (event) => {
		event.preventDefault();

		$('#superadmin_mfa_form_spinner').show();
		$('#superadmin_mfa_form_submit').prop('disabled', true);

		try {
			const postData = {
				code: $('#superadmin_mfa_form_code').val().toString(),
			};

			const res = await ajaxPromise('/login/superadmin/mfa', {data: JSON.stringify(postData)});
			if (res.rc !== 'OK') throw res.rc;

			window.location.href = '/superadmin';
		} catch (error) {
			switch (error) {
				case 'NO_MFA_LOGIN':
					window.location.href = '';
					break;
				case 'INVALID_CODE':
					displayNotification('Login', 'Incorrect code.', 'danger');
					break;
				default:
					displayNotification('Login', 'An error occured while logging in.', 'danger');
					logerror('superadmin mfa_form_submit', error);
			}
		}

		$('#superadmin_mfa_form_spinner').hide();
		$('#superadmin_mfa_form_submit').prop('disabled', false);
	});

	$('#superadmin_mfa_form_resend').off('click').on('click', async (event) => {
		event.preventDefault();

		$('#superadmin_mfa_form_spinner').show();
		$('#superadmin_mfa_form_resend').prop('disabled', true);

		try {
			const res = await ajaxPromise('/login/superadmin/send_mfa');
			if (res.rc !== 'OK') throw res.rc;

			displayNotification('Login', 'A new code has been sent.', 'success');
		} catch (error) {
			switch (error) {
				case 'NO_MFA_LOGIN':
					window.location.href = '';
					break;
				default:
					displayNotification('Login', 'An error occured while sending the code.', 'danger');
					logerror('superadmin mfa_form_resend', error);
			}
		}

		$('#superadmin_mfa_form_spinner').hide();
		$('#superadmin_mfa_form_resend').prop('disabled', false);
	});
};