import 'jquery-ui/ui/widgets/sortable';
import 'jquery-ui/ui/disable-selection';

import { ACCESS_ASSESSMENT, ACCESS_CONTRACTS, ACCESS_DETAILS, ACCESS_DILIGENCE, ACCESS_DOCUMENTS, ACCESS_INHERENT, ACCESS_REVIEW, ACCESS_VALUE, ASSESSMENT_VENDORVALUE, GROUP_ACTIVE, GROUP_DELETED, KPI_RATINGS_LIMIT, KPI_SCORING_LIMIT, NOTIFY_AUTO, NOTIFY_MANUAL, NOTIFY_MANUAL_AND_AUTO, NOTIFY_NONE, PRIV_MANAGE, PRIV_NONE, PRIV_READ, PRIV_WRITE, RATING_KPI, RATING_SLA, RATING_VENDORVALUE, RELOWNER_ANALYST, RELOWNER_NONE, RELOWNER_PRIMARY, RELOWNER_SECONDARY, SERVICE_ACTIVE, SERVICE_DELETED, SITEROLE_ADMIN, SITEROLE_ALERTUSR, SITEROLE_AUDITOR, SITEROLE_MANAGER, SITEROLE_READONLY, SITEROLE_RELOWNER, SITEROLE_USER, SLA_RATINGS_LIMIT, SLA_SCORING_LIMIT, THRESHOLD_INHERENT, THRESHOLD_KPI, THRESHOLD_RESIDUAL, THRESHOLD_SLA, THRESHOLD_VENDORVALUE, USER_ACTIVE, USER_INACTIVE } from './constants';
import { questionSetLoadModal } from './main';
import { ajaxPromise, confirmDialog, displayNotification, dtColumnIndicies, getDtRowData, highlight, htmlEsc, htmlToElement, inputFloatMinMax, inputIntMinMax, logerror, logme, numberToLetter, optionsDialog, post, setCollapse, setTimeoutPromise, toggleSlide, triggerWindowResize, validEmail } from './utils';

if ($('#setup_form').length == 1) {
	var setup_params = JSON.parse(atob($('#setup_form').data('params')));
	init_setup();
}

var catdt: DataTables.Api = null;
if ($('#categories_table').length == 1) {
	const showtable_categories = () => {
		if (catdt == null) {
			init_categories();
		} else {
			catdt.ajax.reload();
		}
	}
	if ($('#tab-vendorcats').hasClass('active')) {
		showtable_categories();
	}
	$('a[href="#tab-vendorcats"]').on('show.bs.tab', function (e) {
		showtable_categories();
	});
}

function init_categories() {
	//logme(setup_params);
	if (!$.isEmptyObject(setup_params) && setup_params.hasOwnProperty('cat_name') && setup_params.hasOwnProperty('cat_description') && setup_params.hasOwnProperty('cat_status')) {
		setTimeout(function () {
			category_edit({
				id: setup_params.cat_id,
				name: setup_params.cat_name,
				description: setup_params.cat_description,
				status: parseInt(setup_params.cat_status),
			});
		}, 500);
	}

	var dtoptions: DataTables.Settings = {
		ajax: {
			url: '/data/categories_load',
			data: (postData) => ({
				...postData,
				archived: $('#category_search_archived').is(':checked') ? 1 : 0,
			}),
		},
		columns: [
			{
				title: 'Name',
				data: 'cat_name',
			},
			{
				title: 'Description',
				data: 'cat_description',
			},
			{
				title: 'Status',
				data: 'nice_cat_status',
			},
			{
				title: 'Actions',
				data: 'buttons',
				className: 'text-right',
				orderable: false,
				searchable: false,
			},
		],
		columnDefs: [
			{ responsivePriority: 1, targets: 0 },
			{ responsivePriority: 2, targets: -1 },
		],
		order: [[0, 'asc']],
	};

	catdt = $('#categories_table').DataTable(dtoptions);

	$('#categories_table').on('click', '.category_edit', ({ target }) => {
		const data = getDtRowData(catdt, target);
		category_edit({
			id: data.cat_id,
			name: data.cat_name,
			description: data.cat_description,
			status: parseInt(data.cat_status),
		});
	});

	$('#categories_table').on('click', '.category_reactivate', ({ target }) => {
		const data = getDtRowData(catdt, target);
		categoryArchive(+data.cat_id, SERVICE_ACTIVE);
	});

	$('#category_search_archived').on('change', function () {
		setTimeout(function () {
			catdt.ajax.reload();
		}, 500);
	});

	$('#category_add').off('click');
	$('#category_add').on('click', function (e) {
		category_edit({
			id: 0,
		});
		e.preventDefault();
	});

	const categoryArchive = async (catId: number, status: number) => {
		const language: {[status: string]: {[key: string]: string, style: BootstrapFlavor}} = {
			[SERVICE_ACTIVE]: {
				title: 'Reinstate',
				verb: 'reinstate',
				action: 'reinstated',
				style: 'success',
			},
			[SERVICE_DELETED]: {
				title: 'Archive',
				verb: 'archive',
				action: 'archived',
				style: 'warning',
			},
		};
		const { title, verb, action, style } = language[status];

		const confirmed = await confirmDialog({
			dialogTitle: `Vendor Category ${title}`,
			bodyText: `Are you sure you would like to ${verb} this Vendor Category?`,
			confirmText: title,
			confirmStyle: style,
		});
		if (!confirmed) return;

		try {
			const postData = {
				type: 'category_archive',
				data: {
					id: catId,
					status,
					force: 0,
				},
			};
			const res = await ajaxPromise('/form/submit', postData);
			if (!['IN_USE', 'OK'].includes(res.rc)) throw res;

			if (res.rc === 'IN_USE') {
				await setTimeoutPromise(500);
				const confirmed = await confirmDialog({
					dialogTitle: `Vendor Category ${title}`,
					bodyText: `This Vendor Category is in use by a Vendor. Are you still sure you would like to ${verb} this Category?`,
					confirmText: title,
					confirmStyle: 'warning',
				});
				if (!confirmed) return;

				postData.data.force = 1;
				const res = await ajaxPromise('/form/submit', postData);
				if (res.rc !== 'OK') throw res;
			}

			catdt.ajax.reload();
			closeform();
			displayNotification(`${title} Success`, `The category was ${action} successfully.`, 'success');
		} catch (error) {
			displayNotification(`${title} Error`, `The category cannot be ${action}.`, 'danger');
			logerror('category archive', error);
		}
	};

	const categoryCopy = async (catId: number) => {
		const confirmed = await confirmDialog({
			dialogTitle: 'Vendor Category Copy',
			bodyText: 'Are you sure you would like to copy this Vendor Category?',
			confirmText: 'Copy',
			confirmStyle: 'info',
		});
		if (!confirmed) return;

		try {
			const postData = {
				type: 'category_copy',
				data: { id: catId },
			};
			const res = await ajaxPromise('/form/submit', postData);
			if (res.rc !== 'OK') throw res;

			displayNotification('Copy Success', 'The category was copied successfully.', 'success');
			catdt.ajax.reload();
			category_edit(res.data); // load category edit from the returned data
		} catch (error) {
			displayNotification('Copy Error', 'The category cannot be copied.', 'danger');
			logerror('category copy', error);
		}
	};

	function closeform() {
		$('#category_form :input').val('');
		$('#category_edit_container').collapse('hide');
		$('#category_add').parent().show();
	}

	var categoryreqdocdt: DataTables.Api = null;

	function category_edit(category) {
		//logme('category_edit:');
		//logme(category);
		setTimeout(function () {
			highlight($('#category_edit_container'));
			$(window).scrollTop($('#category_edit_container').offset().top - 400);
		}, 400);

		if (categoryreqdocdt !== null) {
			categoryreqdocdt.destroy();
			categoryreqdocdt = null;
		}

		if (category.id == 0) {
			$('#category_form_submit').html('Save');
			$('#category_form_name').val('');
			$('#category_form_description').val('');
			$('#category_form_archive').off('click').hide();
			$('#category_form_copy').off('click').hide();
			$('#category_form_export').off('click').hide();

			$('#category_form_diligence_edit').off('click').hide();
			$('#category_form_review_edit').off('click').hide();

			$('#category_form_reqdocs_container').hide();
			$('#category_form_add_hint').show();
		} // edit
		else {
			$('#category_form_submit').html('Update');
			$('#category_form_name').val(category.name);
			$('#category_form_description').val(category.description);

			if (category.status == SERVICE_ACTIVE) {
				$('#category_form_diligence_edit').show();
				$('#category_form_diligence_edit').off('click');
				$('#category_form_diligence_edit').on('click', function (e) {
					post('/ui/assessment_edit', {
						type: 2,
						cat_id: category.id,
					});
					e.preventDefault();
				});
				$('#category_form_review_edit').show();
				$('#category_form_review_edit').off('click');
				$('#category_form_review_edit').on('click', function (e) {
					post('/ui/assessment_edit', {
						type: 3,
						cat_id: category.id,
					});
					e.preventDefault();
				});
				$('#category_form_export').show();
				$('#category_form_export').off('click');
				$('#category_form_export').on('click', function (e) {
					post('/export/category', {
						cat_id: category.id,
					});
					e.preventDefault();
				});
			} else {
				// Archived Category, prevent edit access
				$('#category_form_diligence_edit').off('click').hide();
				$('#category_form_review_edit').off('click').hide();
				$('#category_form_export').off('click').hide();
			}

			switch (category.status) {
				case SERVICE_ACTIVE:
					$('#category_form_archive').html('Archive');
					$('#category_form_archive').removeClass('btn-success').addClass('btn-warning');
					$('#category_form_archive').data('status', SERVICE_DELETED);
					break;
				case SERVICE_DELETED:
					$('#category_form_archive').html('Reinstate');
					$('#category_form_archive').removeClass('btn-warning').addClass('btn-success');
					$('#category_form_archive').data('status', SERVICE_ACTIVE);
					break;
			}

			$('#category_form_archive').show().off('click').on('click', (event) => {
				event.preventDefault();
				categoryArchive(+category.id, SERVICE_DELETED);
			});

			$('#category_form_copy').show().off('click').on('click', (event) => {
				event.preventDefault();
				categoryCopy(+category.id);
			});

			// Start Category Required Documents
			var dtoptions: DataTables.Settings = {
				ajax: {
					url: '/data/categories_reqdocs_load',
					type: 'POST',
					data: (postData) => ({
						...postData,
						mode: 0,
						params: {
							categoryid: category.id,
						},
					}),
				},
				columns: [
					{
						title: 'Document Type',
						data: 'doct_info',
					},
					{
						title: 'Requirement',
						data: 'requirement_select',
						searchable: false,
					},
					{
						title: 'Notify',
						data: 'notify_select',
						searchable: false,
					},
					{
						title: 'Emails',
						data: 'reqdoc_emails_nice',
						searchable: false,
					},
				],
				language: {
					info: "Showing <span class='txt-color-darken'>_START_</span> to <span class='txt-color-darken'>_END_</span> of <span class='text-primary'>_TOTAL_</span> document types",
					infoEmpty: 'No document types to show',
					infoFiltered: "(filtered from <span class='txt-color-darken'>_MAX_</span> total document types)",
					lengthMenu: 'Show _MENU_ document types',
				},
				lengthMenu: [5, 10, 15, 25],
				order: [[1, 'desc']],
				pageLength: 5,
			};

			const setColorActive = (tar) => {
				var value = tar.val();
				if (value > 0) {
					tar.addClass('btn-info');
				} else {
					tar.removeClass('btn-info');
				}
			}

			const changeReqdoc = async ($btn: $, data: any) => {
				let success = false;

				try {
					$btn.prop('disabled', true);
					$('#spinner').show();

					const postData = {
						type: 'category_reqdoc_add',
						data,
					};
					const res = await ajaxPromise('/form/submit', postData);
					if (res.rc !== 'OK') throw res;

					displayNotification('Requirement Edit', 'The requirement has been changed successfully.', 'success');
					categoryreqdocdt.ajax.reload();
					success = true;
				} catch (error) {
					displayNotification('Requirement Edit Error', 'There was an error changing the requirement.', 'danger');
					logerror('category reqdoc add submit', error);
				}

				$('#spinner').hide();
				$btn.prop('disabled', false);

				return success;
			}

			// modal clear
			$('#category_reqdocs_emails_cancel').off('click');
			$('#category_reqdocs_emails_cancel').on('click', function () {
				$('#setup_category_reqdocs_emails_container').modal('hide');
			});
			$('#setup_category_reqdocs_emails_container').off('hidden.bs.modal');
			$('#setup_category_reqdocs_emails_container').on('hidden.bs.modal', function () {
				$('#setup_category_reqdocs_emails_input').val('');
			});

			categoryreqdocdt = $('#category_form_reqdocs')
				.on('init.dt, draw.dt', function () {
					$(this).find('i[data-toggle="popover"]').popover('destroy');
					$(this).find('i[data-toggle="popover"]').popover({
						placement: 'top',
						trigger: 'hover focus',
					});

					$('#category_form_reqdocs')
						.find('select')
						.each(function () {
							setColorActive($(this));
						});

					$('#category_form_reqdocs').find('select.reqdoc_req').off('change');
					$('#category_form_reqdocs')
						.find('select.reqdoc_req')
						.on('change', function (e) {
							setColorActive($(this));
							var data = JSON.parse(JSON.stringify(categoryreqdocdt.row($(this).closest('tr')).data()));

							var change = {
								cat_id: category.id,
								doct_id: data.doct_id,
								reqdoc_id: data.reqdoc_id,
								reqdoc_active: $(this).val(),
							};
							changeReqdoc($(this), change);

							e.preventDefault();
						});

					$('#category_form_reqdocs').find('select.reqdoc_notify').off('change');
					$('#category_form_reqdocs')
						.find('select.reqdoc_notify')
						.on('change', function (e) {
							setColorActive($(this));
							var data = JSON.parse(JSON.stringify(categoryreqdocdt.row($(this).closest('tr')).data()));

							var change = {
								cat_id: category.id,
								doct_id: data.doct_id,
								reqdoc_id: data.reqdoc_id,
								reqdoc_notify: $(this).val(),
							};
							changeReqdoc($(this), change);

							e.preventDefault();
						});

					$('#category_form_reqdocs').find('button.reqdoc_emails').off('click');
					$('#category_form_reqdocs')
						.find('button.recdoc_emails')
						.on('click', function (e) {
							var data = JSON.parse(JSON.stringify(categoryreqdocdt.row($(this).closest('tr')).data()));

							// open the modal
							$('#setup_category_reqdocs_emails_input').val(data.reqdoc_emails);

							var hint = '';

							switch (parseInt(data.reqdoc_notify)) {
								case NOTIFY_NONE:
									hint = '<strong>None:</strong> No notifications will be able to be sent for documents of this type.';
									break;
								case NOTIFY_MANUAL:
									hint = '<strong>Manual:</strong> Enter email addresses that you want to send this document type to. Separate each with a comma. Additional email addresses can be added when the document type is attached to the vendor record.';
									break;
								case NOTIFY_AUTO:
									hint = '<strong>Auto:</strong> Enter email addresses that you want this document type sent to automatically. Separate each with a comma.';
									break;
								case NOTIFY_MANUAL_AND_AUTO:
									hint = '<strong>Manual & Auto:</strong> Enter email addresses that you want this document type sent to automatically. Separate each with a comma. Additional email addresses can be added when the document is attached.';
									break;
							}

							$('#setup_category_reqdocs_emails_hint').html(hint);
							$('#setup_category_reqdocs_emails_target').html(category.name + ' - ' + data.doct_name);

							$('#setup_category_reqdocs_emails_container').modal({ backdrop: 'static' });

							$('#category_reqdocs_emails_submit').off('click').on('click', async (event) => {
								event.preventDefault();
								const change: {
									cat_id: number;
									doct_id: number;
									reqdoc_id: number;
									reqdoc_emails?: string;
									clear_emails?: boolean;
								} = {
									cat_id: +category.id,
									doct_id: +data.doct_id,
									reqdoc_id: +data.reqdoc_id,
								};
								let emails = $('#setup_category_reqdocs_emails_input').val().toString();

								if (emails.length > 0) {
									change.reqdoc_emails = emails;
								} else {
									change.clear_emails = true;
								}

								const success = await changeReqdoc($(event.target), change);
								if (success) $('#setup_category_reqdocs_emails_container').modal('hide');
							});

							e.preventDefault();
						});
				})
				.DataTable(dtoptions);
			$('#category_form_reqdocs_container').show();
			$('#category_form_add_hint').hide();
			// End Category Required Documents
		}

		$('#category_edit_container').collapse('show');
		$('#category_add').parent().hide();

		$('#category_form_cancel').off('click');
		$('#category_form_cancel').on('click', function () {
			closeform();
		});

		$('#category_form_submit').off('click').on('click', async (event) => {
			event.preventDefault();

			const data = {
				...JSON.parse(JSON.stringify(category)),
				name: $('#category_form_name').val(),
				description: $('#category_form_description').val(),
			};

			let bad = false;

			if (data.name == null || data.name.length == 0) {
				bad = true;
				displayNotification('Save Error', 'Please fill out the Category Name.', 'danger');
			}

			if (bad) return;

			$('#category_form_submit').prop('disabled', true);
			$('#spinner').show();

			try {
				const postData = {
					type: 'category_save',
					data,
				};
				const res = await ajaxPromise('/form/submit', postData);
				if (res.rc !== 'OK') throw res;

				displayNotification('Category Saved', 'The category was saved.', 'success');
				catdt.ajax.reload();
				closeform();
			} catch (error) {
				displayNotification('Save Error', 'There was an error saving the category.', 'danger');
				logerror('category submit', error);
			}

			$('#spinner').hide();
			$('#category_form_submit').prop('disabled', false);
		});
	}
}

var attrdt: DataTables.Api = null;
if ($('#attributes_table').length == 1) {
	$('a[href="#tab-vendorattributes"]').on('show.bs.tab', function (e) {
		if (attrdt == null) {
			init_attributes();
		} else {
			attrdt.ajax.reload();
		}
	});
}

function init_attributes() {
	var dtoptions: DataTables.Settings = {
		ajax: {
			url: '/data/attributes_load',
		},
		columns: [
			{
				title: 'Name',
				data: 'attrm_name',
			},
			{
				title: 'Actions',
				data: 'buttons',
				className: 'text-right',
				orderable: false,
				searchable: false,
			},
		],
		order: [[0, 'desc']],
	};

	attrdt = $('#attributes_table')
		.on('init.dt, draw.dt', function () {})
		.DataTable(dtoptions);

	$('#attributes_table').on('click', '.attribute_edit', ({ target }) => {
		const data = getDtRowData(attrdt, target);
		attribute_edit({
			id: data.attrm_id,
			name: data.attrm_name,
		});
	});

	$('#attribute_add').off('click');
	$('#attribute_add').on('click', function (e) {
		attribute_edit({
			id: 0,
		});
		e.preventDefault();
	});

	function attribute_edit(attribute) {
		//logme('attribute_edit:');
		//logme(attribute);
		setTimeout(function () {
			highlight($('#attribute_edit_container'));
			$(window).scrollTop($('#attribute_edit_container').offset().top - 400);
		}, 400);
		if (attribute.id === 0) {
			$('#attribute_form_submit').html('Save');
			$('#attribute_form_name').val('');
			$('#attribute_form_archive').off('click');
			$('#attribute_form_archive').hide();
		} // edit
		else {
			$('#attribute_form_submit').html('Update');
			$('#attribute_form_name').val(attribute.name);
			$('#attribute_form_archive').show().off('click').on('click', async (event) => {
				event.preventDefault();

				const confirmed = await confirmDialog({
					dialogTitle: 'Vendor Attribute Delete',
					bodyText: 'Are you sure you would like to delete this Vendor Attribute?',
					confirmText: 'Delete',
					confirmStyle: 'danger',
				});
				if (!confirmed) return;

				try {
					const postData = {
						type: 'attribute_archive',
						data: attribute.id,
					};
					const res = await ajaxPromise('/form/submit', postData);
					if (res.rc !== 'OK') throw res;

					attrdt.ajax.reload();
					closeform();
					displayNotification('Delete Success', 'The vendor attribute was deleted successfully.', 'success');
				} catch (error) {
					switch (error.rc) {
						case 'IN_USE':
							displayNotification('Delete Error', 'This vendor attribute is being referenced by a vendor and cannot be deleted.', 'danger');
							break;
						default:
							displayNotification('Delete Error', 'There was an error deleting this vendor attribute.', 'danger');
							logerror('attribute archive', error);
							break;
					}
				}
			});
		}
		$('#attribute_edit_container').collapse('show');
		$('#attribute_add').parent().hide();

		function closeform() {
			$('#attribute_form :input').val('');
			$('#attribute_edit_container').collapse('hide');
			$('#attribute_add').parent().show();
		}

		$('#attribute_form_cancel').off('click');
		$('#attribute_form_cancel').on('click', function () {
			closeform();
		});

		$('#attribute_form_submit').off('click').on('click', async (event) => {
			event.preventDefault();
			const data = {
				...JSON.parse(JSON.stringify(attribute)),
				name: $('#attribute_form_name').val(),
			};
			$('#attribute_form_submit').prop('disabled', true);
			$('#spinner').show();

			try {
				const postData = {
					type: 'attribute_save',
					data,
				};
				const res = await ajaxPromise('/form/submit', postData);
				if (res.rc !== 'OK') throw res;

				displayNotification('Save Success', 'The vendor attribute was saved successfully.', 'success');
				attrdt.ajax.reload();
				closeform();
			} catch (error) {
				displayNotification('Save Error', 'There was an error saving this vendor attribute.', 'danger');
				logerror('attribute submit', error);
			}

			$('#spinner').hide();
			$('#attribute_form_submit').prop('disabled', false);
		});
	}
}

let fourthPartyDt: DataTables.Api = null;
if ($('#fourthparty_table').length === 1) {
	$('a[href="#tab-fourthparty"]').on('show.bs.tab', () => {
		if (fourthPartyDt === null) initFourthParty();
		else fourthPartyDt.ajax.reload();
	});
}

const initFourthParty = () => {
	const dtOptions: DataTables.Settings = {
		ajax: {
			url: '/data/fourthparties_load',
		},
		columns: [
			{
				title: 'Name',
				data: 'fpm_name',
			},
			{
				title: 'Actions',
				data: 'actions',
				className: 'text-right',
				orderable: false,
				searchable: false,
			},
		],
		order: [[0, 'ASC']],
	};

	fourthPartyDt = $('#fourthparty_table')
		.on('init.dt, draw.dt', () => {})
		.DataTable(dtOptions);

	$('#fourthparty_table').on('click', '.fourthparty_edit', ({ target }) => {
		const data = getDtRowData(fourthPartyDt, target);
		fourthPartyEdit({
			id: data.fpm_id,
			name: data.fpm_name,
		});
	});

	$('#fourthparty_add')
		.off('click')
		.on('click', (event) => {
			fourthPartyEdit({ id: 0 });
			event.preventDefault();
		});

	$('#fourthparty_general_save').off('click').on('click', async () => {
		$('#fourthparty_general_save').prop('disabled', true);

		try {
			const postData = {
				type: 'fourthparty_general_save',
				data: {
					fourthparty_user_add: $('#fourthparty_user_add').is(':checked') ? 1 : 0,
				},
			};
			const res = await ajaxPromise('/form/submit', postData);
			if (res.rc !== 'OK') throw res;

			displayNotification('Save Success', 'The fourth party settings have been changed.', 'success');
		} catch (error) {
			displayNotification('Save Error', 'There was an error saving the fourth party settings.', 'danger');
			logerror('fourthparty user add submit', error);
		}

		$('#fourthparty_general_save').prop('disabled', false);
	});

	const fourthPartyEdit = (fp) => {
		setTimeout(() => {
			highlight($('#fourthparty_edit_container'));
			$(window).scrollTop($('#fourthparty_edit_container').offset().top - 400);
		}, 400);
		if (fp.id === 0) {
			$('#fourthparty_form_submit').html('Save');
			$('#fourthparty_form_name').val('');
			$('#fourthparty_form_archive').off('click');
			$('#fourthparty_form_archive').hide();
		} else {
			$('#fourthparty_form_submit').html('Update');
			$('#fourthparty_form_name').val(fp.name);
			$('#fourthparty_form_archive').show().off('click').on('click', async (event) => {
				event.preventDefault();

				const confirmed = await confirmDialog({
					dialogTitle: 'Fourth Party Delete',
					bodyText: 'Are you sure you would like to delete this Fourth Party?',
					confirmText: 'Delete',
					confirmStyle: 'danger',
				});
				if (!confirmed) return;

				try {
					const postData = {
						type: 'fourthparty_archive',
						data: fp.id,
					};
					const res = await ajaxPromise('/form/submit', postData);
					if (res.rc !== 'OK') throw res;

					displayNotification('Delete Success', 'The vendor fourth party was deleted successfully.', 'success');
					fourthPartyDt.ajax.reload();
					closeFourthPartyForm();
				} catch (error) {
					switch (error.rc) {
						case 'IN_USE':
							displayNotification('Delete Error', 'This vendor fourth party is being referenced by a vendor and cannot be deleted.', 'danger');
							break;
						default:
							displayNotification('Delete Error', 'There was an error deleting this vendor fourth party.', 'danger');
							logerror('fourthparty archive', error);
							break;
					}
				}
			});
		}

		$('#fourthparty_edit_container').collapse('show');
		$('#fourthparty_add').parent().hide();

		const closeFourthPartyForm = () => {
			$('#fourthparty_form :input').val('');
			$('#fourthparty_edit_container').collapse('hide');
			$('#fourthparty_add').parent().show();
		};

		$('#fourthparty_form_cancel')
			.off('click')
			.on('click', () => closeFourthPartyForm());

		$('#fourthparty_form_submit').off('click').on('click', async (event) => {
			event.preventDefault();
			const postData = JSON.parse(JSON.stringify(fp));

			postData.name = $('#fourthparty_form_name').val();

			$('#fourthparty_form_submit').prop('disabled', true);
			$('#spinner').show();

			try {
				const res = await ajaxPromise('/form/submit', {
					type: 'fourthparty_save',
					data: postData,
				});
				if (res.rc !== 'OK') throw res;

				displayNotification('Save Success', 'The vendor fourth party was saved successfully.', 'success');
				closeFourthPartyForm();
				fourthPartyDt.ajax.reload();
			} catch (error) {
				switch (error.rc) {
					case 'DUPLICATE_EXISTS':
						displayNotification('Save Error', 'That fourth party already exists.', 'danger');
						break;
					default:
						displayNotification('Save Error', 'There was an error saving this vendor fourth party.', 'danger');
						logerror('fourthparty submit', error);
						break;
				}
			}

			$('#fourthparty_form_submit').prop('disabled', false);
			$('#spinner').hide();
		});
	};
};

let watchReasonsDt: DataTables.Api = null;
if ($('#watch_reasons_table').length === 1) {
	$('a[href="#tab-watch-reasons"]').on('show.bs.tab', () => {
		if (watchReasonsDt === null) initWatchReasons();
		else watchReasonsDt.ajax.reload();
	});
}

const initWatchReasons = () => {
	const dtOptions: DataTables.Settings = {
		ajax: {
			url: '/data/watch_reasons_load',
			data: (postData) => ({
				...postData,
				filters: {
					type: 0,
				},
			}),
		},
		buttons: [{
			text: '<i class="fa fa-plus me-2"></i> Add Watch List Reason',
			className: 'adml-2 btn btn-sm btn-success',
			action: () => watchReasonEdit(),
		}],
		columns: [
			{
				title: 'Name',
				data: 'reason_title',
			},
			{
				title: 'Actions',
				data: 'actions',
				className: 'text-right',
				orderable: false,
				searchable: false,
			},
		],
		order: [[0, 'ASC']],
	};

	watchReasonsDt = $('#watch_reasons_table').DataTable(dtOptions);

	$('#watch_reasons_table').on('click', '.watch_reason_edit', ({ target }) => {
		const data = getDtRowData(watchReasonsDt, target);
		watchReasonEdit({
			id: data.reason_id,
			name: data.reason_title,
		});
	});

	const clearWatchReasonEdit = () => {
		$('#watch_reason_form').off('submit').trigger('reset');
		$('#watch_reason_archive').off('click').hide();
		$('#watch_reason_submit').text('Save').prop('disabled', false);
		$('#watch_reason_cancel').off('click');
	};

	const closeWatchReasonEdit = () => {
		$('#watch_reason_cont').collapse('hide');
		setTimeout(() => clearWatchReasonEdit(), 400);
	};

	const watchReasonEdit = (reason = null) => {
		clearWatchReasonEdit();

		$('#watch_reason_cont').collapse('show');

		setTimeout(() => {
			highlight($('#watch_reason_cont'));
			$(window).scrollTop($('#watch_reason_cont').offset().top - 400);
		}, 400);

		if (reason) {
			$('#watch_reason_submit').html('Update');
			$('#watch_reason_name').val(reason.name);
			$('#watch_reason_archive').show().on('click', async (event) => {
				event.preventDefault();

				const confirmed = await confirmDialog({
					dialogTitle: 'Watch List Reason Delete',
					bodyText: 'Are you sure you would like to delete this Watch List Reason?',
					confirmText: 'Delete',
					confirmStyle: 'danger',
				});
				if (!confirmed) return;

				try {
					const postData = {
						type: 'watch_reason_archive',
						data: reason.id,
					};
					const res = await ajaxPromise('/form/submit', postData);
					if (res.rc !== 'OK') throw res;

					displayNotification('Delete Success', 'The watch list reason was deleted successfully.', 'success');
					closeWatchReasonEdit();
					watchReasonsDt.ajax.reload();
				} catch (error) {
					switch (error.rc) {
						case 'IN_USE':
							displayNotification('Delete Error', 'This watch list reason is being referenced by a vendor and cannot be deleted.', 'danger');
							break;
						default:
							displayNotification('Delete Error', 'There was an error deleting this watch list reason.', 'danger');
							logerror('watch reason archive', error);
							break;
					}
				}
			});
		}

		$('#watch_reason_cancel').on('click', () => closeWatchReasonEdit());

		$('#watch_reason_form').on('submit', async (event) => {
			event.preventDefault();

			$('#watch_reason_submit').prop('disabled', true);
			$('#spinner').show();

			try {
				const postData = {
					type: 'watch_reason_save',
					data: JSON.stringify({
						id: reason ? +reason.id : null,
						name: $('#watch_reason_name').val().trim(),
						type: 0,
					}),
					json: true,
				};
				const res = await ajaxPromise('/form/submit', postData);
				if (res.rc !== 'OK') throw res;

				displayNotification('Save Success', 'The watch list reason was saved successfully.', 'success');
				closeWatchReasonEdit();
				watchReasonsDt.ajax.reload();
			} catch (error) {
				switch (error.rc) {
					case 'DUPLICATE_EXISTS':
						displayNotification('Save Error', 'That watch list reason already exists.', 'danger');
						break;
					default:
						displayNotification('Save Error', 'There was an error saving this watch list reason.', 'danger');
						logerror('watch reason submit', error);
						break;
				}
			}

			$('#watch_reason_submit').prop('disabled', false);
			$('#spinner').hide();
		});
	};
};

let watchRemovalDt: DataTables.Api = null;
if ($('#watch_removals_table').length === 1) {
	$('a[href="#tab-watch-removal-reasons"]').on('show.bs.tab', () => {
		if (watchRemovalDt === null) initWatchRemovals();
		else watchRemovalDt.ajax.reload();
	});
}

const initWatchRemovals = () => {
	const dtOptions: DataTables.Settings = {
		ajax: {
			url: '/data/watch_reasons_load',
			data: (postData) => ({
				...postData,
				filters: {
					type: 1,
				},
			}),
		},
		buttons: [{
			text: '<i class="fa fa-plus me-2"></i> Add Watch List Removal Reason',
			className: 'adml-2 btn btn-sm btn-success',
			action: () => watchRemovalEdit(),
		}],
		columns: [
			{
				title: 'Name',
				data: 'reason_title',
			},
			{
				title: 'Actions',
				data: 'actions',
				className: 'text-right',
				orderable: false,
				searchable: false,
			},
		],
		order: [[0, 'ASC']],
	};

	watchRemovalDt = $('#watch_removals_table').DataTable(dtOptions);

	$('#watch_removals_table').on('click', '.watch_reason_edit', ({ target }) => {
		const data = getDtRowData(watchRemovalDt, target);
		watchRemovalEdit({
			id: data.reason_id,
			name: data.reason_title,
		});
	});

	const clearWatchRemovalEdit = () => {
		$('#watch_removal_form').off('submit').trigger('reset');
		$('#watch_removal_archive').off('click').hide();
		$('#watch_removal_submit').text('Save').prop('disabled', false);
		$('#watch_removal_cancel').off('click');
	};

	const closeWatchRemovalEdit = () => {
		$('#watch_removal_cont').collapse('hide');
		setTimeout(() => clearWatchRemovalEdit(), 400);
	};

	const watchRemovalEdit = (reason = null) => {
		clearWatchRemovalEdit();

		$('#watch_removal_cont').collapse('show');

		setTimeout(() => {
			highlight($('#watch_removal_cont'));
			$(window).scrollTop($('#watch_removal_cont').offset().top - 400);
		}, 400);

		if (reason) {
			$('#watch_removal_submit').html('Update');
			$('#watch_removal_name').val(reason.name);
			$('#watch_removal_archive').show().on('click', async (event) => {
				event.preventDefault();

				const confirmed = await confirmDialog({
					dialogTitle: 'Watch List Removal Reason Delete',
					bodyText: 'Are you sure you would like to delete this Watch List Removal Reason?',
					confirmText: 'Delete',
					confirmStyle: 'danger',
				});
				if (!confirmed) return;

				try {
					const postData = {
						type: 'watch_reason_archive',
						data: reason.id,
					};
					const res = await ajaxPromise('/form/submit', postData);
					if (res.rc !== 'OK') throw res;

					displayNotification('Delete Success', 'The watch list removal reason was deleted successfully.', 'success');
					closeWatchRemovalEdit();
					watchRemovalDt.ajax.reload();
				} catch (error) {
					switch (error.rc) {
						case 'IN_USE':
							displayNotification('Delete Error', 'This watch list removal reason is being referenced by a vendor and cannot be deleted.', 'danger');
							break;
						default:
							displayNotification('Delete Error', 'There was an error deleting this watch list removal reason.', 'danger');
							logerror('watch removal archive', error);
							break;
					}
				}
			});
		}

		$('#watch_removal_cancel').on('click', () => closeWatchRemovalEdit());

		$('#watch_removal_form').on('submit', async (event) => {
			event.preventDefault();

			$('#watch_removal_submit').prop('disabled', true);
			$('#spinner').show();

			try {
				const postData = {
					type: 'watch_reason_save',
					data: JSON.stringify({
						id: reason ? +reason.id : null,
						name: $('#watch_removal_name').val().trim(),
						type: 1,
					}),
					json: true,
				};
				const res = await ajaxPromise('/form/submit', postData);
				if (res.rc !== 'OK') throw res;

				displayNotification('Save Success', 'The watch list removal reason was saved successfully.', 'success');
				closeWatchRemovalEdit();
				watchRemovalDt.ajax.reload();
			} catch (error) {
				switch (error.rc) {
					case 'DUPLICATE_EXISTS':
						displayNotification('Save Error', 'That watch list removal reason already exists.', 'danger');
						break;
					default:
						displayNotification('Save Error', 'There was an error saving this watch list removal reason.', 'danger');
						logerror('watch removal submit', error);
						break;
				}
			}

			$('#watch_removal_submit').prop('disabled', false);
			$('#spinner').hide();
		});
	};
};

let taskPrioritiesDt: DataTables.Api;
if ($('#task_priorities_table').length === 1) {
	$('a[href="#tab-task-priorities"]').on('show.bs.tab', () => {
		if (!taskPrioritiesDt) initTaskPriorities();
		else taskPrioritiesDt.ajax.reload();
	});
}

const initTaskPriorities = () => {
	const dtColumns: DataTables.ColumnSettings[] = [
		{
			title: 'Name',
			data: 'name',
		},
		{
			title: 'Actions',
			data: 'actions',
			className: 'text-right',
			orderable: false,
			searchable: false,
		},
	];
	const dtOptions: DataTables.Settings = {
		ajax: {
			url: '/data/task_priorities_load',
		},
		buttons: [{
			text: '<i class="fa fa-plus me-2"></i> Add Priority',
			className: 'adml-2 btn btn-sm btn-success',
			action: () => priorityEdit(),
		}],
		columns: dtColumns,
		order: [[0, 'ASC']],
	};

	taskPrioritiesDt = $('#task_priorities_table').DataTable(dtOptions);

	$('#task_priorities_table').on('click', '.edit', ({ target }) => {
		const priority = getDtRowData(taskPrioritiesDt, target);
		priorityEdit(priority);
	});

	const clearPriorityEdit = () => {
		$('#task_priority_name').val('');
		$('#task_priority_form').off('submit');
		$('#task_priority_delete').off('click').hide();
	};

	const closePriorityEdit = () => {
		$('#task_priority_cont').collapse('hide');
		setTimeout(() => clearPriorityEdit(), 400);
	}

	const priorityEdit = (priority = null) => {
		setTimeout(() => {
			highlight($('#task_priority_cont'));
			$(window).scrollTop($('#task_priority_cont').offset().top - 400);
		}, 400);
		clearPriorityEdit();
		$('#task_priority_cont').collapse('show');

		if (priority) {
			$('#task_priority_name').val(priority.name);
			$('#task_priority_delete').show().on('click', async () => {
				const confirmed = await confirmDialog({
					dialogTitle: 'Priority Delete',
					bodyText: 'Are you sure you would like to delete this task & action item priority?',
					confirmText: 'Delete',
					confirmStyle: 'danger',
				});
				if (!confirmed) return;

				try {
					const postData = {
						type: 'task_priority_delete',
						json: true,
						data: JSON.stringify({
							id: +priority.id,
						}),
					};
					const res = await ajaxPromise('/form/submit', postData);
					if (res.rc !== 'OK') throw res;

					displayNotification('Delete Success', 'The task priority was deleted successfully.', 'success');
					taskPrioritiesDt.ajax.reload();
					closePriorityEdit();
				}
				catch (error) {
					switch (error.rc) {
						case 'IN_USE':
							displayNotification('Delete Error', 'This task priority is being referenced by a task and cannot be deleted.', 'danger');
							break;
						default:
							displayNotification('Delete Error', 'There was an error deleting this task priority.', 'danger');
							logerror('task priority delete', error);
					}
				}
			});
		}

		$('#task_priority_form').on('submit', async (event) => {
			event.preventDefault();

			$('#task_priority_save').prop('disabled', true);
			$('#spinner').show();

			try {
				const postData = {
					type: 'task_priority_save',
					json: true,
					data: JSON.stringify({
						id: priority ? +priority.id : null,
						name: $('#task_priority_name').val().toString().trim(),
					}),
				};

				const res = await ajaxPromise('/form/submit', postData);
				if (res.rc !== 'OK') throw res;

				displayNotification('Save Success', 'The task priority was saved successfully.', 'success');
				taskPrioritiesDt.ajax.reload();
				closePriorityEdit();
			} catch (error) {
				switch (error.rc) {
					case 'DUPLICATE_EXISTS':
						displayNotification('Save Error', 'That task priority already exists.', 'danger');
						break;
					default:
						displayNotification('Save Error', 'There was an error saving this task priority.', 'danger');
						logerror('task priority submit', error);
				}
			}

			$('#task_priority_save').prop('disabled', false);
			$('#spinner').hide();
		});
	};

	$('#task_priority_cancel').on('click', () => closePriorityEdit());
};

let taskTypesDt: DataTables.Api;
if ($('#task_types_table').length === 1) {
	$('a[href="#tab-task-types"]').on('show.bs.tab', () => {
		if (!taskTypesDt) initTaskTypes();
		else taskTypesDt.ajax.reload();
	});
}

const initTaskTypes = () => {
	const dtColumns: DataTables.ColumnSettings[] = [
		{
			title: 'Name',
			data: 'name',
		},
		{
			title: 'Actions',
			data: 'actions',
			className: 'text-right',
			orderable: false,
			searchable: false,
		},
	];
	const dtOptions: DataTables.Settings = {
		ajax: {
			url: '/data/task_types_load',
		},
		buttons: [{
			text: '<i class="fa fa-plus me-2"></i> Add Task Type',
			className: 'adml-2 btn btn-sm btn-success',
			action: () => typeEdit(),
		}],
		columns: dtColumns,
		order: [[0, 'ASC']],
	};

	taskTypesDt = $('#task_types_table').DataTable(dtOptions);

	$('#task_types_table').on('click', '.edit', ({ target }) => {
		const type = getDtRowData(taskTypesDt, target);
		typeEdit(type);
	});

	const clearTypeEdit = () => {
		$('#task_type_name').val('');
		$('#task_type_form').off('submit');
		$('#task_type_delete').off('click').hide();
	};

	const closeTypeEdit = () => {
		$('#task_type_cont').collapse('hide');
		setTimeout(() => clearTypeEdit(), 400);
	}

	const typeEdit = (type = null) => {
		setTimeout(() => {
			highlight($('#task_type_cont'));
			$(window).scrollTop($('#task_type_cont').offset().top - 400);
		}, 400);
		clearTypeEdit();
		$('#task_type_cont').collapse('show');

		if (type) {
			$('#task_type_name').val(type.name);
			$('#task_type_delete').show().on('click', async () => {
				const confirmed = await confirmDialog({
					dialogTitle: 'Task Type Delete',
					bodyText: 'Are you sure you would like to delete this action item?',
					confirmText: 'Delete',
					confirmStyle: 'danger',
				});
				if (!confirmed) return;

				try {
					const postData = {
						type: 'task_type_delete',
						json: true,
						data: JSON.stringify({
							id: +type.id,
						}),
					};
					const res = await ajaxPromise('/form/submit', postData);
					if (res.rc !== 'OK') throw res;

					displayNotification('Delete Success', 'The action item was deleted successfully.', 'success');
					taskTypesDt.ajax.reload();
					closeTypeEdit();
				}
				catch (error) {
					switch (error.rc) {
						case 'IN_USE':
							displayNotification('Delete Error', 'This action item is being referenced by a task and cannot be deleted.', 'danger');
							break;
						default:
							displayNotification('Delete Error', 'There was an error deleting this action item.', 'danger');
							logerror('task type delete', error);
					}
				}
			});
		}

		$('#task_type_form').on('submit', async (event) => {
			event.preventDefault();

			$('#task_type_save').prop('disabled', true);
			$('#spinner').show();

			try {
				const postData = {
					type: 'task_type_save',
					json: true,
					data: JSON.stringify({
						id: type ? +type.id : null,
						name: $('#task_type_name').val().toString().trim(),
					}),
				};

				const res = await ajaxPromise('/form/submit', postData);
				if (res.rc !== 'OK') throw res;

				displayNotification('Save Success', 'The action item was saved successfully.', 'success');
				taskTypesDt.ajax.reload();
				closeTypeEdit();
			} catch (error) {
				switch (error.rc) {
					case 'DUPLICATE_EXISTS':
						displayNotification('Save Error', 'That action item already exists.', 'danger');
						break;
					default:
						displayNotification('Save Error', 'There was an error saving this action item.', 'danger');
						logerror('task type submit', error);
				}
			}

			$('#task_type_save').prop('disabled', false);
			$('#spinner').hide();
		});
	};

	$('#task_type_cancel').on('click', () => closeTypeEdit());
};

var doctdt: DataTables.Api = null;
if ($('#doctypes_table').length == 1) {
	$('a[href="#tab-doctypes"]').on('show.bs.tab', function (e) {
		if (doctdt == null) {
			init_doctypes();
		} else {
			doctdt.ajax.reload();
		}
	});
}

function init_doctypes() {
	var dtoptions: DataTables.Settings = {
		ajax: {
			url: '/data/doctypes_load',
		},
		columns: [
			{
				title: 'Name',
				data: 'doct_name',
			},
			{
				title: 'Description',
				data: 'doct_description',
			},
			{
				title: 'Actions',
				data: 'buttons',
				className: 'text-right',
				orderable: false,
				searchable: false,
			},
		],
		columnDefs: [
			{ responsivePriority: 1, targets: 0 },
			{ responsivePriority: 2, targets: -1 },
		],
		order: [[0, 'desc']],
	};

	doctdt = $('#doctypes_table')
		.on('init.dt, draw.dt', function () {})
		.DataTable(dtoptions);

	$('#doctypes_table').on('click', '.doctype_edit', ({ target }) => {
		const data = getDtRowData(doctdt, target);
		doctype_edit({
			id: data.doct_id,
			name: data.doct_name,
			description: data.doct_description,
			notify: data.doct_notify,
			emails: data.doct_emails,
		});
	});

	$('#doctype_add').off('click');
	$('#doctype_add').on('click', function (e) {
		doctype_edit({
			id: 0,
		});
		e.preventDefault();
	});

	function doctype_edit(doctype) {
		//logme('doctype_edit:');
		//logme(doctype);
		setTimeout(function () {
			highlight($('#doctype_edit_container'));
			$(window).scrollTop($('#doctype_edit_container').offset().top - 400);
		}, 400);

		if (doctype.id === 0) {
			$('#doctype_form_submit').html('Save');
			$('#doctype_form_name').val('');
			$('#doctype_form_description').val('');
			$('#doctype_form_archive').off('click');
			$('#doctype_form_archive').hide();
		} // edit
		else {
			$('#doctype_form_submit').html('Update');
			$('#doctype_form_name').val(doctype.name);
			$('#doctype_form_description').val(doctype.description);

			$('#doctype_form_archive').show().off('click').on('click', async (event) => {
				event.preventDefault();

				const confirmed = await confirmDialog({
					dialogTitle: 'Document Type Delete',
					bodyText: 'Are you sure you would like to delete this Document Type?',
					confirmText: 'Delete',
					confirmStyle: 'danger',
				});
				if (!confirmed) return;

				try {
					const postData = {
						type: 'doctype_archive',
						data: doctype.id,
					};
					const res = await ajaxPromise('/form/submit', postData);
					if (res.rc !== 'OK') throw res;

					displayNotification('Delete Success', 'The Document Type was deleted successfully.', 'success');
					doctdt.ajax.reload();
					closeform();
				} catch (error) {
					switch (error.rc) {
						case 'IN_USE':
							displayNotification('Delete Error', 'This Document Type is being referenced by a document and cannot be deleted.', 'danger');
							break;
						default:
							displayNotification('Delete Error', 'There was an error deleting this Document Type.', 'danger');
							logerror('doctype archive', error);
					}
				}
			});
		}
		$('#doctype_edit_container').collapse('show');
		$('#doctype_add').parent().hide();

		function closeform() {
			$('#doctype_form_name').val('');
			$('#doctype_form_description').val('');
			$('#doctype_form_notification').val(0);
			$('#doctype_edit_container').collapse('hide');
			$('#doctype_add').parent().show();
		}

		$('#doctype_form_cancel').off('click');
		$('#doctype_form_cancel').on('click', function () {
			closeform();
		});

		$('#doctype_form_submit').off('click').on('click', async (event) => {
			event.preventDefault();

			const data = {
				...JSON.parse(JSON.stringify(doctype)),
				name: $('#doctype_form_name').val(),
				description: $('#doctype_form_description').val(),
			};

			if (data.name == null || data.name.length == 0) {
				displayNotification('Save Error', 'Please fill out the Document Type.', 'danger');
				return;
			}

			$('#doctype_form_submit').prop('disabled', true);
			$('#spinner').show();

			try {
				const postData = {
					type: 'doctype_save',
					data,
				};
				const res = await ajaxPromise('/form/submit', postData);
				if (res.rc !== 'OK') throw res;

				displayNotification('Save Success', 'The Document Type was saved successfully.', 'success');
				doctdt.ajax.reload();
				closeform();
			} catch (error) {
				displayNotification('Save Error', 'There was an error saving this Document Type.', 'danger');
				logerror('doctype submit', error);
			}

			$('#doctype_form_submit').prop('disabled', false);
			$('#spinner').hide();
		});
	}
}

var weightdt: DataTables.Api = null;
if ($('#weight_table').length == 1) {
	$('a[href="#tab-serviceimportance"]').on('show.bs.tab', function (e) {
		if (weightdt == null) {
			init_weight();
		} else {
			weightdt.ajax.reload();
		}
	});
}

function init_weight() {
	// Service Importance
	var dtoptions: DataTables.Settings = {
		ajax: {
			url: '/data/weights_load',
		},
		columns: [
			{
				title: 'Title',
				data: 'weight_title',
			},
			{
				title: 'Value',
				data: 'weight_value',
			},
			{
				title: 'Actions',
				data: 'buttons',
				className: 'text-right',
				orderable: false,
				searchable: false,
			},
		],
		columnDefs: [
			{ responsivePriority: 1, targets: 0 },
			{ responsivePriority: 2, targets: -1 },
		],
		order: [[1, 'asc']],
	};

	weightdt = $('#weight_table')
		.on('init.dt, draw.dt', function () {})
		.DataTable(dtoptions);

	$('#weight_table').on('click', '.weight_edit', ({ target }) => {
		const data = getDtRowData(weightdt, target);
		weight_edit({
			id: data.weight_id,
			title: data.weight_title,
			value: data.weight_value,
		});
	});

	$('#weight_add').off('click');
	$('#weight_add').on('click', function (e) {
		weight_edit({
			id: 0,
		});
		e.preventDefault();
	});

	function weight_edit(weight) {
		//logme('weight_edit:');
		//logme(weight);
		setTimeout(function () {
			highlight($('#weight_edit_container'));
			$(window).scrollTop($('#weight_edit_container').offset().top - 400);
		}, 400);

		inputFloatMinMax($('#weight_form_value'), 1, 50);

		if (weight.id === 0) {
			$('#weight_form_submit').html('Save');
			$('#weight_form_name').val('');
			$('#weight_form_archive').off('click');
			$('#weight_form_archive').hide();
		} // edit
		else {
			$('#weight_form_submit').html('Update');
			$('#weight_form_title').val(weight.title);
			$('#weight_form_value').val(weight.value);
			$('#weight_form_archive').show().off('click').on('click', async (event) => {
				event.preventDefault();

				const confirmed = await confirmDialog({
					dialogTitle: 'Contract Importance Delete',
					bodyText: 'Are you sure you would like to delete this Contract Importance?',
					confirmText: 'Delete',
					confirmStyle: 'danger',
				});
				if (!confirmed) return;

				try {
					const postData = {
						type: 'weight_archive',
						data: weight.id,
					};
					const res = await ajaxPromise('/form/submit', postData);
					if (res.rc !== 'OK') throw res;

					displayNotification('Delete Success', 'The importance was deleted successfully.', 'success');
					weightdt.ajax.reload();
					closeform();
				} catch (error) {
					switch (error.rc) {
						case 'IN_USE':
							displayNotification('Delete Error', 'This contract importance is being referenced and cannot be deleted.', 'danger');
							break;
						default:
							displayNotification('Delete Error', 'There was an error deleting this importance.', 'danger');
							logerror('weight archive', error);
					}
				}
			});
		}
		$('#weight_edit_container').collapse('show');
		$('#weight_add').parent().hide();

		function closeform() {
			$('#weight_form :input').val('');
			$('#weight_edit_container').collapse('hide');
			$('#weight_add').parent().show();
		}

		$('#weight_form_cancel').off('click');
		$('#weight_form_cancel').on('click', function () {
			closeform();
		});

		$('#weight_form_submit').off('click').on('click', async () => {
			const postData = {
				id: weight.id,
				title: $('#weight_form_title').val().toString().trim(),
				value: Math.abs(parseFloat($('#weight_form_value').val().toString())),
			};

			let badData = false;
			if (postData.title === '') {
				displayNotification('Save Error', 'Please enter a valid title.', 'danger');
				badData = true;
			}

			if (Number.isNaN(postData.value)) {
				displayNotification('Save Error', 'Please enter a valid value.', 'danger');
				badData = true;
			}

			if (badData) return;

			$('#weight_form_submit').prop('disabled', true);
			$('#spinner').show();

			try {
				const res = await ajaxPromise('/form/submit', {type: 'weight_save', data: postData});
				if (res.rc !== 'OK') throw res;

				weightdt.ajax.reload();
				closeform();
				displayNotification('Save Success', 'The importance rating was saved successfully.', 'success');
			} catch (error) {
				displayNotification('Save Error', 'There was an error saving this importance rating.', 'danger');
				logerror('weight submit', error);
			}

			$('#weight_form_submit').prop('disabled', false);
			$('#spinner').hide();
		});
	}
}

var importancedt: DataTables.Api = null;
if ($('#importance_table').length == 1) {
	$('a[href="#tab-vendorvalue"]').on('show.bs.tab', function (e) {
		if (importancedt == null) {
			init_importance();
		} else {
			importancedt.ajax.reload();
		}
	});
}

function init_importance() {
	// Vendor Value Importance
	var dtoptions: DataTables.Settings = {
		ajax: {
			url: '/data/importances_load',
		},
		columns: [
			{
				title: 'Value',
				data: 'importance_value',
			},
			{
				title: 'Label',
				data: 'importance_label',
			},
			{
				title: 'Description',
				data: 'importance_description',
			},
			{
				title: 'Actions',
				data: 'buttons',
				className: 'text-right',
				orderable: false,
				searchable: false,
			},
		],
		columnDefs: [
			{ responsivePriority: 1, targets: 0 },
			{ responsivePriority: 2, targets: -1 },
		],
		order: [[0, 'asc']],
	};

	importancedt = $('#importance_table')
		.on('init.dt, draw.dt', function () {})
		.DataTable(dtoptions);

	$('#importance_table').on('click', '.importance_edit', ({ target }) => {
		const data = getDtRowData(importancedt, target);
		importance_edit({
			id: data.importance_id,
			label: data.importance_label,
			description: data.importance_description,
			value: data.importance_value,
		});
	});

	$('#importance_add').off('click');
	$('#importance_add').on('click', function (e) {
		importance_edit({
			id: 0,
		});
		e.preventDefault();
	});

	function importance_edit(importance) {
		//logme('importance_edit:');
		//logme(importance);
		setTimeout(function () {
			highlight($('#importance_edit_container'));
			$(window).scrollTop($('#importance_edit_container').offset().top - 400);
		}, 400);

		inputFloatMinMax($('#importance_form_value'), 0, 9.99);

		if (importance.id === 0) {
			$('#importance_form_submit').html('Save');
			$('#importance_form :input').val('');
			$('#importance_form_value').change();
			$('#importance_form_archive').off('click');
			$('#importance_form_archive').hide();
		} // edit
		else {
			$('#importance_form_submit').html('Update');
			$('#importance_form_label').val(importance.label);
			$('#importance_form_description').val(importance.description);
			$('#importance_form_value').val(importance.value);
			$('#importance_form_archive').show().off('click').on('click', async (event) => {
				event.preventDefault();

				const confirmed = await confirmDialog({
					dialogTitle: 'Vendor Value Importance Delete',
					bodyText: 'Are you sure you would like to delete this Vendor Value Importance?',
					confirmText: 'Delete',
					confirmStyle: 'danger',
				});
				if (!confirmed) return;

				try {
					const postData = {
						type: 'importance_archive',
						data: importance.id,
					};
					const res = await ajaxPromise('/form/submit', postData);
					if (res.rc !== 'OK') throw res;

					displayNotification('Delete Success', 'The vendor value importance was deleted successfully.', 'success');
					importancedt.ajax.reload();
					closeform();
				} catch (error) {
					switch (error.rc) {
						case 'IN_USE':
							displayNotification('Delete Error', 'This vendor value importance is being referenced and cannot be deleted.', 'danger');
							break;
						default:
							displayNotification('Delete Error', 'There was an error deleting this vendor value importance.', 'danger');
							logerror('importance archive', error);
					}
				}
			});
		}
		$('#importance_edit_container').collapse('show');

		function closeform() {
			$('#importance_form :input').val('');
			$('#importance_edit_container').collapse('hide');
		}

		$('#importance_form_cancel').off('click');
		$('#importance_form_cancel').on('click', function () {
			closeform();
		});

		$('#importance_form_submit').off('click').on('click', async (event) => {
			event.preventDefault();

			const data = {
				...JSON.parse(JSON.stringify(importance)),
				label: $('#importance_form_label').val(),
				description: $('#importance_form_description').val(),
				value: Math.abs(parseFloat($('#importance_form_value').val().toString())),
			};

			$('#importance_form_submit').prop('disabled', true);
			$('#spinner').show();

			try {
				const postData = {
					type: 'importance_save',
					data,
				};
				const res = await ajaxPromise('/form/submit', postData);
				if (res.rc !== 'OK') throw res;

				displayNotification('Save Success', 'The vendor value importance was saved successfully.', 'success');
				importancedt.ajax.reload();
				closeform();
			} catch (error) {
				switch (error.rc) {
					case 'DUPE':
						displayNotification('Save Error', 'A duplicate vendor value importance value cannot be saved.', 'danger');
						break;
					default:
						displayNotification('Save Error', 'There was an error deleting this vendor value importance.', 'danger');
						logerror('importance submit', error);
						break;
				}
			}

			$('#importance_form_submit').prop('disabled', false);
			$('#spinner').hide();
		});
	}
}

var servicedt: DataTables.Api = null;
if ($('#services_table').length == 1) {
	$('a[href="#tab-servicecats"]').on('show.bs.tab', function (e) {
		if (servicedt == null) {
			init_services();
		} else {
			servicedt.ajax.reload();
		}
	});
}

function init_services() {
	// Service Categories
	var dtoptions: DataTables.Settings = {
		ajax: {
			url: '/data/services_load',
		},
		columns: [
			{
				title: 'Name',
				data: 'service_name',
			},
			{
				title: 'Actions',
				data: 'buttons',
				className: 'text-right',
				orderable: false,
				searchable: false,
			},
		],
		order: [[0, 'desc']],
	};

	servicedt = $('#services_table')
		.on('init.dt, draw.dt', function () {})
		.DataTable(dtoptions);

	$('#services_table').on('click', '.service_edit', ({ target }) => {
		const data = getDtRowData(servicedt, target);
		service_edit({
			id: data.service_id,
			name: data.service_name,
		});
	});

	$('#service_add').off('click');
	$('#service_add').on('click', function (e) {
		service_edit({
			id: 0,
		});
		e.preventDefault();
	});

	function service_edit(service) {
		//logme('service_edit:');
		//logme(service);
		setTimeout(function () {
			highlight($('#service_edit_container'));
			$(window).scrollTop($('#service_edit_container').offset().top - 400);
		}, 400);
		if (service.id === 0) {
			$('#service_form_submit').html('Save');
			$('#service_form_name').val('');
			$('#service_form_archive').off('click');
			$('#service_form_archive').hide();
		} // edit
		else {
			$('#service_form_submit').html('Update');
			$('#service_form_name').val(service.name);
			$('#service_form_archive').show().off('click').on('click', async (event) => {
				event.preventDefault();

				const confirmed = await confirmDialog({
					dialogTitle: 'Contract Delete',
					bodyText: 'Are you sure you would like to delete this Contract Category?',
					confirmText: 'Delete',
					confirmStyle: 'danger',
				});
				if (!confirmed) return;

				try {
					const postData = {
						type: 'service_archive',
						data: service.id,
					};
					const res = await ajaxPromise('/form/submit', postData);
					if (res.rc !== 'OK') throw res;

					displayNotification('Delete Success', 'The contract category was deleted successfully.', 'success');
					servicedt.ajax.reload();
					closeform();
				} catch (error) {
					switch (error.rc) {
						case 'IN_USE':
							displayNotification('Delete Error', 'This contract category is being referenced and cannot be deleted.', 'danger');
							break;
						default:
							displayNotification('Delete Error', 'There was an error deleting this contract category.', 'danger');
							logerror('service archive', error);
					}
				}
			});
		}
		$('#service_edit_container').collapse('show');
		$('#service_add').parent().hide();

		function closeform() {
			$('#service_form :input').val('');
			$('#service_edit_container').collapse('hide');
			$('#service_add').parent().show();
		}

		$('#service_form_cancel').off('click');
		$('#service_form_cancel').on('click', function () {
			closeform();
		});

		$('#service_form_submit').off('click').on('click', async (event) => {
			event.preventDefault();

			const data = {
				...JSON.parse(JSON.stringify(service)),
				name: $('#service_form_name').val(),
			};

			$('#service_form_submit').prop('disabled', true);
			$('#spinner').show();

			try {
				const postData = {
					type: 'service_save',
					data,
				};
				const res = await ajaxPromise('/form/submit', postData);
				if (res.rc !== 'OK') throw res;

				displayNotification('Save Success', 'The contract category was saved successfully.', 'success');
				servicedt.ajax.reload();
				closeform();
			} catch (error) {
				displayNotification('Save Error', 'There was an error saving this contract category.', 'danger');
				logerror('service submit', error);
			}

			$('#service_form_submit').prop('disabled', false);
			$('#spinner').hide();
		});
	}
}

var costcenterdt: DataTables.Api = null;
if ($('#costcenters_table').length == 1) {
	$('a[href="#tab-costcenters"]').on('show.bs.tab', function (e) {
		if (costcenterdt == null) {
			init_costcenters();
		} else {
			costcenterdt.ajax.reload();
		}
	});
}

function init_costcenters() {
	var dtoptions: DataTables.Settings = {
		ajax: {
			url: '/data/costcenters_load',
		},
		columns: [
			{
				title: 'Name',
				data: 'costcenter_name',
			},
			{
				title: 'Actions',
				data: 'buttons',
				className: 'text-right',
				orderable: false,
				searchable: false,
			},
		],
		order: [[0, 'desc']],
	};

	costcenterdt = $('#costcenters_table')
		.on('init.dt, draw.dt', function () {})
		.DataTable(dtoptions);

	$('#costcenters_table').on('click', '.costcenter_edit', ({ target }) => {
		const data = getDtRowData(costcenterdt, target);
		costcenter_edit({
			id: data.costcenter_id,
			name: data.costcenter_name,
		});
	});

	$('#costcenter_add').off('click');
	$('#costcenter_add').on('click', function (e) {
		costcenter_edit({
			id: 0,
		});
		e.preventDefault();
	});

	function costcenter_edit(costcenter) {
		//logme('costcenter_edit:');
		//logme(costcenter);
		setTimeout(function () {
			highlight($('#costcenter_edit_container'));
			$(window).scrollTop($('#costcenter_edit_container').offset().top - 400);
		}, 400);
		if (costcenter.id === 0) {
			$('#costcenter_form_submit').html('Save');
			$('#costcenter_form_name').val('');
			$('#costcenter_form_archive').off('click');
			$('#costcenter_form_archive').hide();
		} // edit
		else {
			$('#costcenter_form_submit').html('Update');
			$('#costcenter_form_name').val(costcenter.name);
			$('#costcenter_form_archive').show().off('click').on('click', async (event) => {
				event.preventDefault();

				const confirmed = await confirmDialog({
					dialogTitle: 'Cost Center Delete',
					bodyText: 'Are you sure you would like to delete this Cost Center?',
					confirmText: 'Delete',
					confirmStyle: 'danger',
				});
				if (!confirmed) return;

				try {
					const postData = {
						type: 'costcenter_archive',
						data: costcenter.id,
					};
					const res = await ajaxPromise('/form/submit', postData);
					if (res.rc !== 'OK') throw res;

					displayNotification('Delete Success', 'The cost center was deleted successfully.', 'success');
					costcenterdt.ajax.reload();
					closeform();
				} catch (error) {
					switch (error.rc) {
						case 'IN_USE':
							displayNotification('Delete Error', 'This cost center is being referenced by a contract and cannot be deleted.', 'danger');
							break;
						default:
							displayNotification('Delete Error', 'There was an error deleting this cost center.', 'danger');
							logerror('costcenter archive', error);
					}
				}
			});
		}
		$('#costcenter_edit_container').collapse('show');
		$('#costcenter_add').parent().hide();

		function closeform() {
			$('#costcenter_form :input').val('');
			$('#costcenter_edit_container').collapse('hide');
			$('#costcenter_add').parent().show();
		}

		$('#costcenter_form_cancel').off('click');
		$('#costcenter_form_cancel').on('click', function () {
			closeform();
		});

		$('#costcenter_form_submit').off('click').on('click', async (event) => {
			const data = {
				...JSON.parse(JSON.stringify(costcenter)),
				name: $('#costcenter_form_name').val(),
			};

			$('#costcenter_form_submit').prop('disabled', true);
			$('#spinner').show();

			try {
				const postData = {
					type: 'costcenter_save',
					data,
				};
				const res = await ajaxPromise('/form/submit', postData);
				if (res.rc !== 'OK') throw res;

				displayNotification('Save Success', 'The cost center was saved successfully.', 'success');
				costcenterdt.ajax.reload();
				closeform();
			} catch (error) {
				displayNotification('Save Error', 'There was an error saving this cost center.', 'danger');
				logerror('costcenter submit', error);
			}

			$('#costcenter_form_submit').prop('disabled', false);
			$('#spinner').hide();
		});
	}
}

const initTransactions = () => {
	const dtOptions: DataTables.Settings = {
		ajax: {
			url: '/data/tvers_load',
		},
		buttons: [
			{
				text: '<i class="fa fa-plus" style="margin-right: 1rem;"></i>Add Transaction Version',
				className: 'add ml-2 btn btn-sm btn-primary',
				action: () => editTvers(),
			},
		],
		columns: [
			{
				title: 'Title',
				data: 'title',
			},
			{
				title: 'Description',
				data: 'desc',
			},
			{
				title: 'Columns',
				data: 'tvcol_count',
				searchable: false,
			},
			{
				title: 'Actions',
				data: 'buttons',
				className: 'text-right',
				orderable: false,
				searchable: false,
			},
		],
		deferLoading: 1,
	};

	const transDt = $('#tvers_table').DataTable(dtOptions);

	$('#tvers_tab').on('show.bs.tab', () => transDt.ajax.reload());

	$('#tvers_table').on('click', '.tvers_edit', ({ target }) => {
		const tvers = getDtRowData(transDt, target);
		editTvers(tvers);
	});

	const $tvcolTemplate = $('#tvers_form_tvcols').children('.tvcol').remove();

	const resetTversForm = () => {
		$('#tvers_form_submit').html('Save');
		$('#tvers_form_title, #tvers_form_desc, #tvers_form_cat, #tvers_form_con_name, #tvers_form_con_cat, #tvers_form_con_imp').val('');
		$('#tvers_form_create_vendor, tvers_form_create_contract, #tvers_form_create_qset').prop('checked', true);
		$('#tvers_form_tvcols').empty();
		$('#tvers_form_submit').off('click');
		$('#tvers_form_delete').off('click').hide();
		$('#tvers_form_qset_label').html('<i>None selected</i>');
	};

	const editTvers = (tvers = null) => {
		resetTversForm();
		setTimeout(() => {
			highlight($('#tvers_form'));
			$(window).scrollTop($('#tvers_form').offset()!.top - 400);
		}, 400);

		const tvcolMap: Map<HTMLElement, any> = new Map();
		let selectedQs = null;
		const selectQset = (qs) => {
			$('#tvers_form_qset_label').empty().text(qs.qs_label);
			$('#tvers_form_qset_html').empty().html(qs.qs_data_nice);
			selectedQs = qs;
			updateSpreadsheetTable();
		};

		const addTvcol = (tvcol = null) => {
			const $tvcol = $tvcolTemplate.clone();
			if (tvcol) {
				$tvcol.find('.display_name').val(tvcol.display_name);
				$tvcol.find('.type').val(tvcol.type);
				$tvcol.find('.reportable').prop('checked', tvcol.reportable == 1);
			}
			$tvcol.find('.display_name').on('change', () => updateSpreadsheetTable());
			$tvcol.find('.reportable').bootstrapToggle();
			$tvcol.find('.delete').on('click', () => {
				$tvcol.remove();
				updateSpreadsheetTable();
			});

			tvcolMap.set($tvcol[0], tvcol);
			$('#tvers_form_tvcols').append($tvcol);
		};

		const updateSpreadsheetTable = () => {
			const qsData = selectedQs ? selectedQs.qs_data : [];
			const $colDisplayNames = $('#tvers_form_tvcols').find('.display_name');
			const $table = $(
				<table className="basic-table text-nowrap">
					<thead>
						<tr>
							<th className="text-center">A</th>
							<th className="text-center">B</th>
							<th className="text-center">C</th>
							{qsData.map((_, index) => <th className="text-center">{numberToLetter(index + 3)}</th>)}
							{$colDisplayNames.toArray().map((_, index) => <th className="text-center">{numberToLetter(index + 3 + selectedQs.qs_data.length)}</th>)}
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>Transaction Version</td>
							<td>Vendor ID</td>
							<td>Vendor Name</td>
							{qsData.map((question, index) => (
								<td className="text-center">
									Sla #{index + 1}
									<button
										type="button"
										className="btn btn-primary btn-xs header-tooltip"
										data-toggle="tooltip"
										title={question.text}
										data-originalTitle={question.text}
										data-container="body"
										style="display: inline-block; margin-left: 5px; border-radius: 50%; width: 25.5px;">
										<i className="fa fa-info"></i>
									</button>
								</td>
							))}
							{$colDisplayNames.toArray().map((input: HTMLInputElement) => <td>{input.value}</td>)}
						</tr>
					</tbody>
				</table>
			);
			$('#tvers_form_spreadsheet_table').empty().append($table);
			$('#tvers_form_spreadsheet_table').find('.header-tooltip').tooltip();
		};

		if (tvers) {
			$('#tvers_form_submit').html('Update');
			$('#tvers_form_title').val(tvers.title);
			$('#tvers_form_desc').val(tvers.desc);
			$('#tvers_form_cat').val(tvers.cat);
			$('#tvers_form_con_name').val(tvers.con_name);
			$('#tvers_form_con_cat').val(tvers.con_cat);
			$('#tvers_form_con_imp').val(tvers.con_imp);
			$('#tvers_form_create_vendor').prop('checked', tvers.create_vendor == 1);
			$('#tvers_form_create_contract').prop('checked', tvers.create_contract == 1);
			$('#tvers_form_create_qset').prop('checked', tvers.create_qset == 1);
			if (tvers.qs_id !== null) selectQset(tvers);

			$('#tvers_form_delete').show().off('click').on('click', async () => {
				const confirmed = await confirmDialog({
					dialogTitle: 'Transaction Delete',
					bodyText: 'Are you sure you would like to delete this Transaction?',
					confirmText: 'Delete',
					confirmStyle: 'danger',
				});
				if (!confirmed) return;

				try {
					const postData = {tvers_id: +tvers.tvers_id};
					const res = await ajaxPromise('/form/submit', { type: 'tvers_delete', data: JSON.stringify(postData) });
					if (res.rc !== 'OK') throw res;

					transDt.ajax.reload();
					$('#tvers_form').collapse('hide');
				} catch (error) {
					displayNotification('Delete Error', 'There was an error deleting this transaction.', 'danger');
					logerror('tvers delete', error);
				}
			});

			tvers.tvcols.forEach((tvcol) => addTvcol(tvcol));
		}

		updateSpreadsheetTable();

		$('#tvers_form_tvcols').sortable({
			handle: '.handle',
			placeholder: 'ui-state-highlight',
			forcePlaceholderSize: true,
			stop: () => updateSpreadsheetTable(),
		});

		$('#tvcols_add').off('click').on('click', () => {
			addTvcol();
			updateSpreadsheetTable();
		});

		$('#tvers_form_qset_select').off('click').on('click', () => {
			questionSetLoadModal({ type: 0, onQsetSelected: (rowData) => selectQset(rowData) });
		});

		$('#tvers_form').collapse('show');

		$('#tvers_form_submit')
			.off('click')
			.on('click', async () => {
				$('#tvers_form_submit').prop('disabled', true);
				$('#spinner').show();

				try {
					const tvcols = $('#tvers_form_tvcols')
						.find('.tvcol')
						.toArray()
						.map((element) => {
							const oldTvcol = tvcolMap.get(element);
							const $tvcol = $(element);
							return {
								tvcol_id: oldTvcol ? +oldTvcol.tvcol_id : null,
								display_name: $tvcol.find('.display_name').val().toString().trim(),
								type: +$tvcol.find('.type').val(),
								reportable: $tvcol.find('.reportable').is(':checked') ? 1 : 0,
							};
						});
					if (tvcols.find((tvcol) => tvcol.display_name === '')) throw Error('BAD_TVCOL');
					const postData = {
						tvers_id: tvers ? +tvers.tvers_id : null,
						title: $('#tvers_form_title').val().toString().trim(),
						desc: $('#tvers_form_desc').val().toString().trim(),
						cat: +$('#tvers_form_cat').val() || null,
						con_name: $('#tvers_form_con_name').val().toString().trim(),
						con_cat: +$('#tvers_form_con_cat').val() || null,
						con_imp: +$('#tvers_form_con_imp').val() || null,
						create_vendor: $('#tvers_form_create_vendor').is(':checked') ? 1 : 0,
						create_contract: $('#tvers_form_create_contract').is(':checked') ? 1 : 0,
						create_qset: $('#tvers_form_create_qset').is(':checked') ? 1 : 0,
						qs_id: +selectedQs.qs_id,
						tvcols,
					};
					if (postData.title === '') throw Error('BAD_TITLE');
					if (postData.create_vendor && !postData.cat) throw Error('BAD_VENDOR_UPSERT');
					if (postData.create_contract && (!postData.con_cat || !postData.con_imp)) throw Error('BAD_CONTRACT_UPSERT');
					const res = await ajaxPromise('/form/submit', { type: 'tvers_save', data: JSON.stringify(postData) });
					if (res.rc !== 'OK') throw Error(res);

					displayNotification('Transaction Save', 'Transaction saved successfully.', 'success');
					transDt.ajax.reload();
					$('#tvers_form').collapse('hide');
				} catch (error) {
					switch (error.message) {
						case 'BAD_TITLE':
							displayNotification('Transaction Save', 'Please specify a transaction title.', 'danger');
							break;
						case 'BAD_VENDOR_UPSERT':
							displayNotification('Transaction Save', 'Vendor Category is required when Create Vendor is checked.', 'danger');
							break;
						case 'BAD_CONTRACT_UPSERT':
							displayNotification('Transaction Save', 'Contract Category and Contract Importance are required when Create Contract is checked.', 'danger');
							break;
						case 'BAD_TVCOL':
							displayNotification('Transaction Save', 'Please fill out each transaction column defined.', 'danger');
							break;
						default:
							displayNotification('Transaction Save', 'There was an error saving this transaction.', 'danger');
							break;
					}
					logerror('tvers submit', error);
				}
				$('#tvers_form_submit').prop('disabled', false);
				$('#spinner').hide();
			});
	};

	$('#tvers_form').on('hidden.bs.collapse', () => resetTversForm());

	$('#tvers_form_cancel').off('click').on('click', () => $('#tvers_form').collapse('hide'));

	const historyDtOptions: DataTables.Settings = {
		ajax: {
			url: '/data/tvers_history_load',
		},
		columns: [
			{
				title: 'Date',
				data: 'trans_date_pretty',
			},
			{
				title: 'Status',
				data: 'trans_status_pretty',
				searchable: false,
			},
			{
				title: 'Message',
				data: 'trans_message',
			},
			{
				title: 'Rows Processed',
				data: 'trow_count',
			},
			{
				title: 'Actions',
				data: 'buttons',
				className: 'text-right',
				orderable: false,
				searchable: false,
			},
		],
		order: [[0, 'desc']],
	};
	const transHistoryDt = $('#tvers_history_table').DataTable(historyDtOptions);

	$('#tvers_history_table')
		.off('click', '.trans_download')
		.on('click', '.trans_download', ({ target }) => {
			const trans = getDtRowData(transHistoryDt, target);
			window.open(`/download/transaction/${trans.trans_guid}`, '_blank');
		});
};

if ($('#tvers_table').length === 1) initTransactions();

var businessunitdt: DataTables.Api = null;
if ($('#businessunits_table').length == 1) {
	$('a[href="#tab-businessunits"]').on('show.bs.tab', function (e) {
		if (businessunitdt == null) {
			init_businessunits();
		} else {
			businessunitdt.ajax.reload();
		}
	});
}

function init_businessunits() {
	var dtoptions: DataTables.Settings = {
		ajax: {
			url: '/data/businessunits_load',
		},
		columns: [
			{
				title: 'Name',
				data: 'businessunit_name',
			},
			{
				title: 'Actions',
				data: 'buttons',
				className: 'text-right',
				orderable: false,
				searchable: false,
			},
		],
		order: [[0, 'desc']],
	};

	businessunitdt = $('#businessunits_table')
		.on('init.dt, draw.dt', function () {})
		.DataTable(dtoptions);

	$('#businessunits_table').on('click', '.businessunit_edit', ({ target }) => {
		const data = getDtRowData(businessunitdt, target);
		businessunit_edit({
			id: data.businessunit_id,
			name: data.businessunit_name,
		});
	});

	$('#businessunit_add').off('click');
	$('#businessunit_add').on('click', function (e) {
		businessunit_edit({
			id: 0,
		});
		e.preventDefault();
	});

	function businessunit_edit(businessunit) {
		//logme('businessunit_edit:');
		//logme(businessunit);
		setTimeout(function () {
			highlight($('#businessunit_edit_container'));
			$(window).scrollTop($('#businessunit_edit_container').offset().top - 400);
		}, 400);
		if (businessunit.id === 0) {
			$('#businessunit_form_submit').html('Save');
			$('#businessunit_form_name').val('');
			$('#businessunit_form_archive').off('click');
			$('#businessunit_form_archive').hide();
		} // edit
		else {
			$('#businessunit_form_submit').html('Update');
			$('#businessunit_form_name').val(businessunit.name);
			$('#businessunit_form_archive').off('click').on('click', async (event) => {
				event.preventDefault();

				const confirmed = await confirmDialog({
					dialogTitle: 'Business Unit Delete',
					bodyText: 'Are you sure you would like to delete this Business Unit?',
					confirmText: 'Delete',
					confirmStyle: 'danger',
				});
				if (!confirmed) return;

				try {
					const postData = {
						type: 'businessunit_archive',
						data: businessunit.id,
					};
					const res = await ajaxPromise('/form/submit', postData);
					if (res.rc !== 'OK') throw res;

					displayNotification('Delete Success', 'The business unit was deleted successfully.', 'success');
					businessunitdt.ajax.reload();
					closeform();
				} catch (error) {
					switch (error.rc) {
						case 'IN_USE':
							displayNotification('Delete Error', 'This business unit is being referenced by a vendor and cannot be deleted.', 'danger');
							break;
						default:
							displayNotification('Delete Error', 'There was an error deleting this business unit.', 'danger');
							logerror('businessunit archive', error);
					}
				}
			});
		}
		$('#businessunit_edit_container').collapse('show');
		$('#businessunit_add').parent().hide();

		function closeform() {
			$('#businessunit_form :input').val('');
			$('#businessunit_edit_container').collapse('hide');
			$('#businessunit_add').parent().show();
		}

		$('#businessunit_form_cancel').off('click');
		$('#businessunit_form_cancel').on('click', function () {
			closeform();
		});

		$('#businessunit_form_submit').off('click').on('click', async (event) => {
			event.preventDefault();

			const data = {
				...JSON.parse(JSON.stringify(businessunit)),
				name: $('#businessunit_form_name').val(),
			};

			$('#businessunit_form_submit').prop('disabled', true);
			$('#spinner').show();

			try {
				const postData = {
					type: 'businessunit_save',
					data,
				};
				const res = await ajaxPromise('/form/submit', postData);
				if (res.rc !== 'OK') throw res;

				displayNotification('Save Success', 'The business unit was saved successfully.', 'success');
				businessunitdt.ajax.reload();
				closeform();
			} catch (error) {
				displayNotification('Save Error', 'There was an error saving this business unit.', 'danger');
				logerror('businessunit submit', error);
			}

			$('#businessunit_form_submit').prop('disabled', false);
			$('#spinner').hide();
		});
	}
}

var qcatdt: DataTables.Api = null;
if ($('#qcats_table').length == 1) {
	$('a[href="#tab-qcats"]').on('show.bs.tab', function (e) {
		if (qcatdt == null) {
			init_qcats();
		} else {
			qcatdt.ajax.reload();
		}
	});
}

function init_qcats() {
	var dtoptions: DataTables.Settings = {
		ajax: {
			url: '/data/questioncategories_load',
		},
		columns: [
			{
				title: 'Name',
				data: 'qcat_title',
			},
			{
				title: 'Sections',
				data: 'qcat_sections',
				orderable: false,
				searchable: false,
			},
			{
				title: 'Actions',
				data: 'buttons',
				className: 'text-right',
				orderable: false,
				searchable: false,
			},
		],
		columnDefs: [
			{ responsivePriority: 1, targets: 0 },
			{ responsivePriority: 2, targets: -1 },
		],
		order: [[0, 'asc']],
	};

	qcatdt = $('#qcats_table')
		.on('init.dt, draw.dt', function () {})
		.DataTable(dtoptions);

	$('#qcats_table').on('click', '.qcat_edit', ({ target }) => {
		const data = getDtRowData(qcatdt, target);
		qcat_edit({
			id: data.qcat_id,
			title: data.qcat_title,
			inherent: parseInt(data.qcat_inherent),
			residual: parseInt(data.qcat_residual),
			periodic: parseInt(data.qcat_periodic),
			kpi: parseInt(data.qcat_kpi),
		});
	});

	$('#qcat_add').off('click');
	$('#qcat_add').on('click', function (e) {
		qcat_edit({
			id: 0,
		});
		e.preventDefault();
	});

	var qcatqdt: DataTables.Api = null;

	function qcat_edit(qcat) {
		//logme('qcat_edit:');
		//logme(qcat);
		setTimeout(function () {
			highlight($('#qcat_edit_container'));
			$(window).scrollTop($('#qcat_edit_container').offset().top - 400);
		}, 400);

		if (qcatqdt !== null) {
			qcatqdt.destroy();
			qcatqdt = null;
		}

		if (qcat.id === 0) {
			$('#qcat_form_submit').html('Save');
			$('#qcat_form_title').val('');
			$('#qcat_form_inherent').prop('checked', false).change();
			$('#qcat_form_residual').prop('checked', false).change();
			$('#qcat_form_periodic').prop('checked', false).change();
			$('#qcat_form_kpi').prop('checked', false).change();
			$('#qcat_form_archive').off('click');
			$('#qcat_form_archive').hide();
		} // edit
		else {
			$('#qcat_form_submit').html('Update');
			$('#qcat_form_title').val(qcat.title);
			$('#qcat_form_inherent')
				.prop('checked', qcat.inherent ? true : false)
				.change();
			$('#qcat_form_residual')
				.prop('checked', qcat.residual ? true : false)
				.change();
			$('#qcat_form_periodic')
				.prop('checked', qcat.periodic ? true : false)
				.change();
			$('#qcat_form_kpi')
				.prop('checked', qcat.kpi ? true : false)
				.change();

			$('#qcat_form_archive').show().off('click').on('click', async (event) => {
				event.preventDefault();

				const confirmed = await confirmDialog({
					dialogTitle: 'Question Category Delete',
					bodyText: 'Are you sure you would like to delete this Question Category?',
					confirmText: 'Delete',
					confirmStyle: 'danger',
				});
				if (!confirmed) return;

				try {
					const postData = {
						type: 'qcat_archive',
						data: qcat.id,
					};
					const res = await ajaxPromise('/form/submit', postData);
					if (res.rc !== 'OK') throw res;

					displayNotification('Delete Success', 'The question category was deleted successfully.', 'success');
					qcatdt.ajax.reload();
					closeform();
				} catch (error) {
					switch (error.rc) {
						case 'IN_USE':
							displayNotification('Delete Error', 'This question category is being referenced and cannot be deleted.', 'danger');
							break;
						default:
							displayNotification('Delete Error', 'There was an error deleting this question category.', 'danger');
							logerror('qcat archive', error);
					}
				}
			});
		}
		$('#qcat_edit_container').collapse('show');
		$('#qcat_add').parent().hide();

		function closeform() {
			$('#qcat_form :input').val('');
			$('#qcat_edit_container').collapse('hide');
			$('#qcat_add').parent().show();
		}

		$('#qcat_form_cancel').off('click');
		$('#qcat_form_cancel').on('click', function () {
			closeform();
		});

		$('#qcat_form_submit').off('click').on('click', async (event) => {
			event.preventDefault();

			const data = {
				...JSON.parse(JSON.stringify(qcat)),
				title: $('#qcat_form_title').val(),
				inherent: $('#qcat_form_inherent').prop('checked') ? 1 : 0,
				residual: $('#qcat_form_residual').prop('checked') ? 1 : 0,
				periodic: $('#qcat_form_periodic').prop('checked') ? 1 : 0,
				kpi: $('#qcat_form_kpi').prop('checked') ? 1 : 0,
			};

			$('#qcat_form_submit').prop('disabled', true);
			$('#spinner').show();

			try {
				const postData = {
					type: 'qcat_save',
					data,
				};
				const res = await ajaxPromise('/form/submit', postData);
				if (res.rc !== 'OK') throw res;

				displayNotification('Save Success', 'The question category was saved successfully.', 'success');
				qcatdt.ajax.reload();
				closeform();
			} catch (error) {
				switch (error.rc) {
					case 'IN_USE':
						displayNotification('Save Error', `This question category is being referenced in ${error.usage.join(', ')} and cannot be removed from those features.`, 'danger');
						break;
					default:
						displayNotification('Save Error', 'There was an error saving this question category.', 'danger');
						break;
				}
			}

			$('#qcat_form_submit').prop('disabled', false);
			$('#spinner').hide();
		});
	}
}

var questiondt: DataTables.Api = null;

if ($('#questions_table').length == 1) {
	$('a[href="#tab-questions"]').on('show.bs.tab', function (e) {
		if (questiondt == null) {
			init_questions();
		} else {
			qcatsSet();
			questiondt.ajax.reload();
		}
	});
}

// QCats get & set are used to ensure filtering for the Question Library is always up-to-date.
const qcatsGet = async () => {
	try {
		const res = await ajaxPromise('/data/qcats_get');
		if (res.rc !== 'OK') throw res;
		return res.data;
	} catch (error) {
		displayNotification('Question Categories', 'There was an error loading the question categories.', 'danger');
		logerror('qcats get', error);
		return [];
	}
};

const qcatsSet = async () => {
	const qcats = await qcatsGet();
	const opts = [];
	opts.push('<option disabled>--</option>');
	qcats.forEach((qcat) => opts.push(`<option value="${qcat.qcat_id}">${qcat.qcat_title}</option>`));
	$('#question_table_filter_category').html(['<option value="0">No Filter</option>'].concat(opts).join(''));
	$('#question_edit_category').html(['<option value="0">Uncategorized</option>'].concat(opts).join(''));
}

function init_questions() {
	qcatsSet();

	function getFilters() {
		var d = {
			category: $('#question_table_filter_category').val(),
		};
		return d;
	}

	$('#question_table_filter_category').off('change');
	$('#question_table_filter_category').on('change', function () {
		questiondt.ajax.reload();
	});

	var dtoptions: DataTables.Settings = {
		ajax: {
			url: '/data/questions_load',
			data: (postData) => ({
				...postData,
				mode: 0,
				filters: getFilters(),
			}),
		},
		columns: [
			{
				title: 'Text',
				data: 'surveyq_text',
			},
			{
				title: 'Category',
				data: 'qcat_title',
			},
			{
				title: 'Actions',
				data: 'buttons',
				className: 'text-right',
				orderable: false,
				searchable: false,
			},
		],
		columnDefs: [
			{ responsivePriority: 1, targets: 0 },
			{ responsivePriority: 2, targets: -1 },
		],
		order: [[0, 'asc']],
	};

	questiondt = $('#questions_table')
		.on('init.dt, draw.dt', function () {})
		.DataTable(dtoptions);

	$('#questions_table').on('click', '.question_edit', ({ target }) => {
		const data = getDtRowData(questiondt, target);
		question_edit({
			id: data.surveyq_id,
			guid: data.surveyq_guid,
			text: data.surveyq_text,
			description: data.surveyq_description,
			category: parseInt(data.surveyq_cat),
			//				hidden:			data.surveyq_hidden,
			//				options:		data.surveyq_options, // should be useless now...
			answers: data.answers,
		});
	});

	$('#question_export_lib').off('click');
	$('#question_export_lib').on('click', function (e) {
		post('/export/questionlibrary', null, null);
		e.preventDefault();
	});

	$('#question_add').off('click');
	$('#question_add').on('click', function (e) {
		question_edit({
			id: 0,
		});
		e.preventDefault();
	});

	$('#question_edit_answers').sortable({
		handle: '.handle',
		placeholder: 'ui-state-highlight',
		forcePlaceholderSize: true,
	});

	$('#setup_question_answer_template')
		.find('.answer-value')
		.on('change', function () {
			var val = parseFloat($(this).val().toString());
			if (!isNaN(val)) {
				val = +val.toFixed(2); // correct to integer if
				// other assessments don't have a "limit" so we'll limit the input to -100/100
				if (val < -100 || val > 100) {
					highlight($(this));
				}
				val = +Math.min(Math.max(-100, val), 100).toFixed(2);
				$(this).val(val);
			} else {
				$(this).val(0);
				highlight($(this));
			}
		});

	function question_edit(question) {
		logme(question);

		// FROM ASSESSMENT

		// BUILD OUT ANSWERS HERE
		$('#question_edit_answers').html('');

		//var hidden = (question.hidden!=null && parseInt(question.hidden)==1);

		const answersObjs = [];
		const deleteAnswerIds = [];

		if (question.hasOwnProperty('answers')) {
			question.answers.forEach((answer) => {
				const $answer = $('#setup_question_answer_template>div').clone(true);
				$answer.find('.answer-text').val(answer.survqa_text);
				$answer.find('.answer-value').val(answer.survqa_value);
				$answer.find('.answer-delete').on('click', ({ target }) => {
					$(target).closest('.form-group').remove();
					deleteAnswerIds.push(answer.survqa_id);
				});

				$('#question_edit_answers').append($answer);
				answersObjs.push({
					$answer,
					answer,
				});
			});
		}

		$('#assessment_edit_answers_container').show();

		$('#assessment_edit_question_label>small').html($('#assessment_title').html() + ' SECTION');
		$('#assessment_edit_question_delete').show();
		$('#assessment_edit_question_modal').modal({ backdrop: 'static' });

		// Question Editor modal handlers
		$('#question_edit_answers_add')
			.off('click')
			.on('click', () => {
				const $answer = $('#setup_question_answer_template > div').clone(true);

				$('#question_edit_answers').append($answer);
				answersObjs.push({ $answer, answer: null });
			});

		// END FROM ASSESSMENT

		//logme('question_edit:');
		//logme(question);
		setTimeout(function () {
			highlight($('#question_edit_container'));
			$(window).scrollTop($('#question_edit_container').offset().top - 400);
		}, 400);

		if (question.id === 0) {
			$('#question_form_submit').html('Save');
			$('#question_edit_text').val('');
			$('#question_edit_description').val('');
			$('#question_edit_category').val(0);
			$('#question_form_archive').off('click');
			$('#question_form_archive').hide();
		} // edit
		else {
			$('#question_form_submit').html('Update');
			$('#question_edit_text').val(question.text);
			$('#question_edit_description').val(question.description);
			$('#question_edit_category').val(question.category);
			$('#question_edit_hidden').prop('checked', question.hidden != null && parseInt(question.hidden) == 1);

			$('#question_form_archive').show().off('click').on('click', async (event) => {
				event.preventDefault();

				const confirmed = await confirmDialog({
					dialogTitle: 'Question Delete',
					bodyText: 'Are you sure you would like to delete this Question?',
					confirmText: 'Delete',
					confirmStyle: 'danger',
				});
				if (!confirmed) return;

				try {
					const postData = {
						type: 'question_delete',
						data: {
							guid: question.guid,
						},
					};
					const res = await ajaxPromise('/form/submit', postData);
					if (res.rc !== 'OK') throw res;

					displayNotification('Delete Success', 'The question was deleted successfully.', 'success');
					questiondt.ajax.reload();
					closeform();
				} catch (error) {
					switch (error.rc) {
						case 'IN_USE':
							displayNotification('Delete Error', 'This question is being referenced and cannot be deleted.', 'danger');
							break;
						default:
							displayNotification('Delete Error', 'There was an error deleting this question.', 'danger');
							logerror('question archive', error);
					}
				}
			});
		}
		$('#question_edit_container').collapse('show');
		$('#question_add').parent().hide();

		function closeform() {
			$('#question_form :input').val('');
			$('#question_edit_container').collapse('hide');
			$('#question_add').parent().show();
		}

		$('#question_form_cancel').off('click');
		$('#question_form_cancel').on('click', function () {
			closeform();
		});

		$('#question_form_submit').off('click').on('click', async (event) => {
			event.preventDefault();

			const data = {
				...JSON.parse(JSON.stringify(question)),
				text: $('#question_edit_text').val().toString().trim(),
				description: $('#question_edit_description').val().toString().trim(),
				category: $('#question_edit_category').val(),
				hidden: $('#question_edit_hidden').prop('checked') ? 1 : 0,
				answers: [],
			};
			$('#question_edit_answers > .form-group').each((_, element) => {
				const { answer, $answer } = answersObjs.find(({ $answer }) => $answer.get(0) === element);
				data.answers.push({
					id: answer ? answer.survqa_id : null,
					text: $answer.find('textarea.answer-text').val().toString().trim(),
					value: $answer.find('input.answer-value').val().toString().trim(),
				});
			});

			let bad = false;

			if (data.text.length == 0) {
				displayNotification('Question Edit', 'Please add the question text.', 'danger');
				bad = true;
			}
			if (data.category == null || data.category == 0) {
				displayNotification('Question Edit', 'Please categorize this question.', 'danger');
				bad = true;
			}
			if (data.answers.length == 0) {
				displayNotification('Question Edit', 'Please add at least one answer choice.', 'danger');
				bad = true;
			}
			for (let i = 0; i < data.answers.length; i++) {
				if (data.answers[i].text.trim() == '' || data.answers[i].value == '' || isNaN(data.answers[i].value)) {
					displayNotification('Question Edit', 'Please fill out each answer with response text and numerical value.', 'danger');
					bad = true;
					break;
				}
			}

			if (deleteAnswerIds.length) {
				data.delete_answer_ids = deleteAnswerIds;
			}

			if (bad) return;

			$('#question_form_submit').prop('disabled', true);
			$('#spinner').show();

			try {
				const postData = {
					type: 'question_save',
					data,
				};
				const res = await ajaxPromise('/form/submit', postData);
				if (res.rc !== 'OK') throw res;

				displayNotification('Save Success', 'The question was saved successfully.', 'success');
				questiondt.ajax.reload();
				closeform();
			} catch (error) {
				displayNotification('Save Error', 'There was an error saving this question.', 'danger');
				logerror('question submit', error);
			}

			$('#question_form_submit').prop('disabled', false);
			$('#spinner').hide();
		});
	}
}

if ($('#dimensions_table').length == 1) {
	init_dimensions();
}

function init_dimensions() {
	var dtoptions: DataTables.Settings = {
		ajax: {
			url: '/data/dimensions_load',
		},
		columns: [
			{
				title: 'dim_id',
				data: 'dim_id',
				visible: false,
				orderable: false,
				searchable: false,
			},
			{
				title: 'Name',
				data: 'dim_title',
			},
			{
				title: 'Actions',
				data: 'buttons',
				className: 'text-right',
				orderable: false,
				searchable: false,
			},
		],
		columnDefs: [
			{ responsivePriority: 1, targets: 0 },
			{ responsivePriority: 2, targets: -1 },
		],
		order: [[1, 'desc']],
	};

	const dimensiondt = $('#dimensions_table')
		.on('init.dt, draw.dt', function () {})
		.DataTable(dtoptions);

	$('#dimensions_table').on('click', '.dim_edit', ({ target }) => {
		const data = getDtRowData(dimensiondt, target);
		dimension_edit({
			id: data.dim_id,
			title: data.dim_title,
		});
	});

	$('#dim_add').off('click');
	$('#dim_add').on('click', function (e) {
		dimension_edit({
			id: 0,
		});
		e.preventDefault();
	});

	function dimension_edit(dimension) {
		//logme('dimension_edit:');
		//logme(dimension);
		setTimeout(function () {
			highlight($('#dimension_edit_container'));
			$(window).scrollTop($('#dimension_edit_container').offset().top - 400);
		}, 400);
		if (dimension.id === 0) {
			$('#dimension_form_submit').html('Save');
			$('#dimension_form_title').val('');
			$('#dimension_form_archive').off('click');
			$('#dimension_form_archive').hide();
		} // edit
		else {
			$('#dimension_form_submit').html('Update');
			$('#dimension_form_title').val(dimension.title);
			$('#dimension_form_archive').show().off('click').on('click', async (event) => {
				event.preventDefault();

				$('#dimension_form_submit').prop('disabled', true);
				$('#dimension_form_spinner').show();

				try {
					const postData = {
						type: 'dimension_archive',
						data: dimension.id,
					};
					const res = await ajaxPromise('/form/submit', postData);
					if (res.rc !== 'OK') throw res;

					displayNotification('Delete Success', 'The dimension was archived successfully.', 'success');
					dimensiondt.ajax.reload();
					closeform();
				} catch (error) {
					displayNotification('Delete Error', 'There was an error deleting this dimension of risk.', 'danger');
					logerror('dimension archive', error);
				}

				$('#dimension_form_submit').prop('disabled', false);
				$('#dimension_form_spinner').hide();
			});
		}
		$('#dimension_edit_container').collapse('show');

		function closeform() {
			$('#dimension_form :input').val('');
			$('#dimension_edit_container').collapse('hide');
		}

		$('#dimension_form_cancel').off('click');
		$('#dimension_form_cancel').on('click', function () {
			closeform();
		});

		$('#dimension_form_submit').off('click').on('click', async (event) => {
			event.preventDefault();

			const data = {
				...JSON.parse(JSON.stringify(dimension)),
				title: $('#dimension_form_title').val(),
			};

			$('#dimension_form_submit').prop('disabled', true);
			$('#spinner').show();

			try {
				const postData = {
					type: 'dimension_save',
					data,
				};
				const res = await ajaxPromise('/form/submit', postData);
				if (res.rc !== 'OK') throw res;

				displayNotification('Save Success', 'The dimension was saved successfully.', 'success');
				dimensiondt.ajax.reload();
				closeform();
			} catch (error) {
				displayNotification('Save Error', 'There was an error saving this dimension.', 'danger');
				logerror('dimension submit', error);
			}

			$('#dimension_form_submit').prop('disabled', false);
			$('#spinner').hide();
		});
	}
}

function init_setup() {
	function template_add(template, target) {
		var clone = template.clone(true);
		target.append(clone);
	}

	function template_limit(target, btn, limit) {
		var count = target.length;
		if (count >= limit) {
			btn.prop('disabled', true);
		} else {
			btn.prop('disabled', false);
		}
	}

	$('#setup-inherent-threshold-add').on('click', function () {
		template_add($('#setup-template-threshold-inherent>div'), $('#setup-inherent-threshold'));
		inputIntMinMax($('#setup-inherent-threshold').find('input.value'), -1000, 1000);
		$('#setup-inherent-threshold').trigger('limitcheck');
		return false;
	});
	$('#setup-inherent-threshold').on('limitcheck', function () {
		template_limit($('#setup-inherent-threshold>div'), $('#setup-inherent-threshold-add'), 5);
		return false;
	});
	$('#setup-inherent-threshold').trigger('limitcheck');

	$('#setup-residual-threshold-add').on('click', function () {
		template_add($('#setup-template-threshold-percent>div'), $('#setup-residual-threshold'));
		inputIntMinMax($('#setup-residual-threshold').find('input.value'), -1000, 1000);
		$('#setup-residual-threshold').trigger('limitcheck');
		return false;
	});
	$('#setup-residual-threshold').on('limitcheck', function () {
		template_limit($('#setup-residual-threshold>div'), $('#setup-residual-threshold-add'), 5);
		return false;
	});
	$('#setup-residual-threshold').trigger('limitcheck');

	// Vendor Value

	$('#setup-vv-criteria-add').on('click', function () {
		template_add($('#setup-template-vv-criteria>div'), $('#setup-vv-criteria'));
		return false;
	});

	$('#setup-vv-rating-add').on('click', function () {
		template_add($('#setup-template-rating>div'), $('#setup-vv-rating'));
		inputFloatMinMax($('#setup-vv-rating').find('input.value'), -3, 3);
		$('#setup-vv-rating').trigger('limitcheck');
		return false;
	});
	$('#setup-vv-rating').on('limitcheck', function () {
		template_limit($('#setup-vv-rating>div'), $('#setup-vv-rating-add'), 4);
		return false;
	});
	$('#setup-vv-rating').trigger('limitcheck');

	$('#setup-vv-threshold-add').on('click', function () {
		template_add($('#setup-template-threshold-percent>div'), $('#setup-vv-threshold'));
		$('#setup-vv-threshold').trigger('limitcheck');
		return false;
	});
	$('#setup-vv-threshold').on('limitcheck', function () {
		template_limit($('#setup-vv-threshold>div'), $('#setup-vv-threshold-add'), 5);
		return false;
	});
	$('#setup-vv-threshold').trigger('limitcheck');

	// SLA & KPI

	$('#setup-sla-rating-add').on('click', function () {
		template_add($('#setup-template-rating>div'), $('#setup-sla-rating'));
		inputFloatMinMax($('#setup-sla-rating').find('input.value'), -3, 3);
		$('#setup-sla-rating').trigger('limitcheck');
		return false;
	});
	$('#setup-sla-rating').on('limitcheck', function () {
		template_limit($('#setup-sla-rating>div'), $('#setup-sla-rating-add'), SLA_RATINGS_LIMIT - 1);
		return false;
	});
	$('#setup-sla-rating').trigger('limitcheck');

	$('#setup-kpi-rating-add').on('click', function () {
		template_add($('#setup-template-rating>div'), $('#setup-kpi-rating'));
		inputFloatMinMax($('#setup-kpi-rating').find('input.value'), -3, 3);
		$('#setup-kpi-rating').trigger('limitcheck');
		return false;
	});
	$('#setup-kpi-rating').on('limitcheck', function () {
		template_limit($('#setup-kpi-rating>div'), $('#setup-kpi-rating-add'), KPI_RATINGS_LIMIT - 1);
		return false;
	});
	$('#setup-kpi-rating').trigger('limitcheck');

	$('#setup-sla-threshold-add').on('click', function () {
		template_add($('#setup-template-threshold-percent>div'), $('#setup-sla-threshold'));
		inputIntMinMax($('#setup-sla-threshold').find('input.value'), -300, 300);
		$('#setup-sla-threshold').trigger('limitcheck');
		return false;
	});
	$('#setup-sla-threshold').on('limitcheck', function () {
		template_limit($('#setup-sla-threshold>div'), $('#setup-sla-threshold-add'), SLA_SCORING_LIMIT);
		return false;
	});
	$('#setup-sla-threshold').trigger('limitcheck');

	$('#setup-kpi-threshold-add').on('click', function () {
		template_add($('#setup-template-threshold-percent>div'), $('#setup-kpi-threshold'));
		inputIntMinMax($('#setup-kpi-threshold').find('input.value'), -300, 300);
		$('#setup-kpi-threshold').trigger('limitcheck');
		return false;
	});
	$('#setup-kpi-threshold').on('limitcheck', function () {
		template_limit($('#setup-kpi-threshold>div'), $('#setup-kpi-threshold-add'), KPI_SCORING_LIMIT);
		return false;
	});
	$('#setup-kpi-threshold').trigger('limitcheck');

	$('#setup-inherent-threshold, #setup-residual-threshold, #setup-vv-criteria, #setup-vv-rating, #setup-vv-threshold, #setup-sla-rating, #setup-kpi-rating, #setup-sla-threshold, #setup-kpi-threshold').sortable({
		handle: '.handle',
		placeholder: 'ui-state-highlight',
		forcePlaceholderSize: true,
	});

	const criteriaContainerTypes = {
		'setup-vv-criteria': ASSESSMENT_VENDORVALUE,
	};
	const ratingsContainerTypes = {
		'setup-sla-rating': RATING_SLA,
		'setup-kpi-rating': RATING_KPI,
	};
	const thresholdContainerTypes = {
		'setup-inherent-threshold': THRESHOLD_INHERENT,
		'setup-residual-threshold': THRESHOLD_RESIDUAL,
		'setup-vv-threshold': THRESHOLD_VENDORVALUE,
		'setup-sla-threshold': THRESHOLD_SLA,
		'setup-kpi-threshold': THRESHOLD_KPI,
	};
	let deleteCriteria = {};
	let deleteRatings = {};
	let deleteThresholds = {};
	$('.setup-threshold-delete').on('click', ({ target }) => {
		const $formGroup = $(target).closest('.form-group');
		const $container = $formGroup.parent();
		const id = $formGroup.data('id');
		const contId = $container.prop('id');
		if (criteriaContainerTypes[contId]) {
			const type = criteriaContainerTypes[contId];
			if (!deleteCriteria[type]) deleteCriteria[type] = [];
			deleteCriteria[type].push(id);
		} else if (ratingsContainerTypes[contId]) {
			const type = ratingsContainerTypes[contId];
			if (!deleteRatings[type]) deleteRatings[type] = [];
			deleteRatings[type].push(id);
		} else if (thresholdContainerTypes[contId]) {
			const type = thresholdContainerTypes[contId];
			if (!deleteThresholds[type]) deleteThresholds[type] = [];
			deleteThresholds[type].push(id);
		}

		$formGroup.remove();
		$container.trigger('limitcheck');
		return false;
	});

	$('#setup_form_inherent_edit').off('click');
	$('#setup_form_inherent_edit').on('click', function () {
		post('/ui/assessment_edit', {
			type: 1,
			cat_id: 0,
		});
	});

	function thresholdSort($target, order) {
		var $th = $target.find('.form-group');
		$th.sort(function (a, b) {
			var an = parseFloat($(a).find('input.value').val().toString()),
				bn = parseFloat($(b).find('input.value').val().toString());
			if (order == 0) {
				// Ascending (Scoring Tolerances)
				if (an > bn) {
					return 1;
				}
				if (an < bn) {
					return -1;
				}
			} else {
				// Decending (Responses)
				if (an > bn) {
					return -1;
				}
				if (an < bn) {
					return 1;
				}
			}
			return 0;
		});
		$th.detach().appendTo($target);
	}

	const saveSettings = async ({$btn, context, postData}: {$btn: $, context: string, postData: any}) => {
		$btn.prop('disabled', true);
		$('#spinner').show();
		try {
			const res = await ajaxPromise('/form/submit', {type: 'setup_save', data: postData});
			if (res.rc !== 'OK') throw res;
			displayNotification('Save Success', context + ' settings saved.', 'success');
			await setTimeoutPromise(50);
			displayNotification({ context: 'Note', msg: 'Please note that updates are processing in the background.', style: 'info', time: 5000 });
		} catch (error) {
			switch (error.rc) {
				case 'BAD_IDNUMBERS':
					$('#setup-dashboard-idnumber-required').bootstrapToggle('off');
					$('#setup_bad_idnumbers_empty_vendors_cont').hide();
					$('#setup_bad_idnumbers_empty_vendors').empty();
					if (error.emptyVendors.length) {
						error.emptyVendors.forEach(({vend_id, vend_name}) => {
							const $li = $(
								<li><a href="javascript:void(0)">{vend_name}</a></li>
							);
							$li.find('a').on('click', () => post('/ui/vendor_edit', {id: vend_id}, 'blank'));
							$('#setup_bad_idnumbers_empty_vendors').append($li);
						});
						$('#setup_bad_idnumbers_empty_vendors_cont').show();
					}
					$('#setup_bad_idnumbers_duplicate_idnumber_vendors_cont').hide();
					$('#setup_bad_idnumbers_duplicate_idnumber_vendors').empty();
					if (error.duplicateIdNumberVendors.length) {
						error.duplicateIdNumberVendors.forEach(({idnumber, vendors}) => {
							const vendNameLinks = vendors.map(({vend_id, vend_name}) => `<a data-id="${vend_id}" href="javascript:void(0)">${htmlEsc(vend_name)}</a>`).join(', ');
							const $li = $(
								<li>
									<span>{idnumber}: </span>
									<span>{htmlToElement(vendNameLinks)}</span>
								</li>
							);
							$li.find('a').on('click', ({target}) => post('/ui/vendor_edit', {id: target.dataset.id}, 'blank'));
							$('#setup_bad_idnumbers_duplicate_idnumber_vendors').append($li);
						});
						$('#setup_bad_idnumbers_duplicate_idnumber_vendors_cont').show();
					}
					$('#setup_bad_idnumbers_modal').modal('show');
					break;
				default:
					displayNotification('Save Error', 'There was an error saving ' + context + ' settings.', 'danger');
					logerror('setup submit', error);
					break;
			}
		}

		$btn.prop('disabled', false);
		$('#spinner').hide();
	};

	type saveThresholdsParams = {
		$btn: $;
		type: number;
		postData: any;
		newThreshFormGroups?: Record<number, $>;
		newRatingsFormGroups?: Record<number, $>;
		newCriteriaFormGroups?: Record<number, $>;
	};
	const saveThresholds = ({ $btn, type, postData, newThreshFormGroups, newRatingsFormGroups, newCriteriaFormGroups }: saveThresholdsParams) => {
		$btn.prop('disabled', true);
		$('#spinner').show();

		const context =
			{
				[THRESHOLD_INHERENT]: 'Inherent',
				[THRESHOLD_RESIDUAL]: 'Residual',
				[THRESHOLD_VENDORVALUE]: 'Vendor Value',
				[THRESHOLD_SLA]: 'SLA',
				[THRESHOLD_KPI]: 'KPI',
			}[type] || 'Unknown';

		return ajaxPromise('/form/submit', { type: 'setup_save_thresholds', data: postData })
			.then((res) => {
				if (res.rc !== 'OK') {
					displayNotification('Save Error', `There was an error saving ${context} settings.`, 'danger');
				}

				if (res.newCriteriaIds) {
					Object.keys(res.newCriteriaIds).forEach((order) => {
						const id = res.newCriteriaIds[order];
						const $formGroup = newCriteriaFormGroups[order];
						if ($formGroup) $formGroup.data('id', id);
					});
				}
				if (res.newRatingIds) {
					Object.keys(res.newRatingIds).forEach((order) => {
						const id = res.newRatingIds[order];
						const $formGroup = newRatingsFormGroups[order];
						if ($formGroup) $formGroup.data('id', id);
					});
				}
				if (res.newThreshIds) {
					Object.keys(res.newThreshIds).forEach((threshOrder) => {
						const threshId = res.newThreshIds[threshOrder];
						const $formGroup = newThreshFormGroups[threshOrder];
						if ($formGroup) $formGroup.data('id', threshId);
					});
				}

				deleteCriteria[type] = [];
				deleteRatings[type] = [];
				deleteThresholds[type] = [];

				displayNotification('Save Success', `${context} settings saved.`, 'success');
				setTimeout(() => {
					displayNotification({ context: 'Note', msg: 'Please note that updates are processing in the background.', style: 'info', time: 5000 });
				}, 500);
			})
			.catch((error) => {
				logerror('setup submit', error);
			})
			.finally(() => {
				$('#spinner').hide();
				$btn.prop('disabled', false);
			});
	};

	// Dashboard

	inputIntMinMax($('#setup_auth_mfa_days'), 0, 365, true);
	inputIntMinMax($('#setup_notification_vendor_review_before'), 0, 1000);
	inputIntMinMax($('#setup_notification_vendor_review_repeat'), 0, 1000);
	inputIntMinMax($('#setup_notification_vendor_review_overdue'), 0, 1000);
	inputIntMinMax($('#setup_notification_service_cancel_before'), 0, 1000);
	inputIntMinMax($('#setup_notification_service_cancel_repeat'), 0, 1000);
	inputIntMinMax($('#setup_notification_document_renew_before'), 0, 1000);
	inputIntMinMax($('#setup_notification_document_renew_repeat'), 0, 1000);
	inputIntMinMax($('#setup_notification_document_renew_overdue'), 0, 1000);

	$('#setup_form_auth_save').off('click').on('click', (event) => {
		event.preventDefault();
		const postData = {
			auth_mfa_required: $('#setup_auth_mfa_required').is(':checked') ? 1 : 0,
			auth_mfa_days: +$('#setup_auth_mfa_days').val(),
		};
		saveSettings({$btn: $(event.target), context: 'Authentication', postData});
	});

	$('#setup_form_dashboard_save').off('click').on('click', (event) => {
		event.preventDefault();
		const postData = {
			dashboard_vendor_completion: $('#setup-dashboard-vendor-completion').is(':checked') ? 1 : 0,
			idnumber_required: $('#setup-dashboard-idnumber-required').is(':checked') ? 1 : 0,
		};
		saveSettings({$btn: $(event.target), context: 'Dashboard', postData});
	});

	$('#setup_form_configure_sso').off('click').on('click', () => window.location.href = '/ui/sso');

	$('#setup_notifications_form input[type=checkbox]').off('change');
	$('#setup_notifications_form input[type=checkbox]').on('change', function () {
		var $input = $(this).closest('.input-group').find('input[type=number]');
		if ($(this).is(':checked')) {
			$input.prop('disabled', false);
			$input.val(0);
		} else {
			$input.prop('disabled', true);
			$input.val('');
		}
	});

	// Repeat notifications cannot occur if the first setting is not set.
	$('#setup_notification_vendor_review_before_checkbox').change(function (event) {
		const input = event.target as HTMLInputElement;
		if (!input.checked) {
			$('#setup_notification_vendor_review_repeat_checkbox').prop('checked', false).trigger('change');
		}
	});
	$('#setup_notification_service_cancel_before_checkbox').change(function (event) {
		const input = event.target as HTMLInputElement;
		if (!input.checked) {
			$('#setup_notification_service_cancel_repeat_checkbox').prop('checked', false).trigger('change');
		}
	});
	$('#setup_notification_document_renew_before_checkbox').change(function (event) {
		const input = event.target as HTMLInputElement;
		if (!input.checked) {
			$('#setup_notification_document_renew_repeat_checkbox').prop('checked', false).trigger('change');
		}
	});

	$('#setup_notification_task_overdue_checkbox').on('change', ({target}) => {
		const input = target as HTMLInputElement;
		if (!input.checked) {
			$('#setup_notification_task_repeat_before_checkbox').prop('checked', false).trigger('change');
		}
	});

	$('#setup_form_notifications_save').off('click').on('click', (event) => {
		event.preventDefault();

		const postData = {
			notification_email_address: $('#setup_notification_email_address').val(),
			notification_summary: $('#setup_notification_summary').prop('checked') ? 1 : 0,
			notification_vendor_review_before: $('#setup_notification_vendor_review_before').val(),
			notification_vendor_review_repeat: $('#setup_notification_vendor_review_repeat').val(),
			notification_vendor_review_overdue: $('#setup_notification_vendor_review_overdue').val(),
			notification_service_cancel_before: $('#setup_notification_service_cancel_before').val(),
			notification_service_cancel_repeat: $('#setup_notification_service_cancel_repeat').val(),
			notification_document_renew_before: $('#setup_notification_document_renew_before').val(),
			notification_document_renew_repeat: $('#setup_notification_document_renew_repeat').val(),
			notification_document_renew_overdue: $('#setup_notification_document_renew_overdue').val(),
			notification_task_overdue: $('#setup_notification_task_overdue').val(),
			notification_task_overdue_repeat_before: $('#setup_notification_task_repeat_before').val(),
			notification_task_overdue_repeat_after: $('#setup_notification_task_repeat_after').val(),
		};
		saveSettings({$btn: $(event.target), context: 'Notifications', postData});
	});

	// Inherent

	inputIntMinMax($('#setup-inherent-threshold').find('input.value'), -1000, 1000);

	$('#setup_form_inherent_save')
		.off('click')
		.on('click', (event) => {
			event.preventDefault();

			thresholdSort($('#setup-inherent-threshold'), 0);

			if ($('#setup-inherent-threshold').find('.form-group').length == 0) {
				displayNotification('Inherent Risk', 'At least one Risk Tier is required, starting at 0 points.', 'danger');
				return;
			}

			const thresholds = [];
			const newThreshFormGroups = {};
			let blankThresholdFound = false;
			$('#setup-inherent-threshold')
				.find('.form-group')
				.each((index, element) => {
					const id = $(element).data('id') || null;
					const title = $(element).find('input.title').length == 1 ? $(element).find('input.title').val() : '';
					const value = $(element).find('input.value').length == 1 ? $(element).find('input.value').val() : '';
					const color = $(element).find('input.colorpicker').length == 1 ? $(element).find('input.colorpicker').val() : '';
					const review = $(element).find('select.review').length == 1 ? $(element).find('select.review').val() : '';

					if (title == '' || value == '' || color == '') {
						blankThresholdFound = true;
						return;
					}

					if (id === null) newThreshFormGroups[index] = $(element);
					thresholds.push({
						thresh_id: id,
						thresh_title: title,
						thresh_value: value,
						thresh_color: color,
						thresh_review: review,
						thresh_order: index,
					});
				});

			if (blankThresholdFound) {
				displayNotification('Risk Tiers', 'Please fill out each risk tier defined.', 'danger');
				return;
			}

			const postData: any = {
				thresh_type: THRESHOLD_INHERENT,
				thresholds,
				risk_inherent_override: $('#setup-inherent-ratingoverride').prop('checked') ? 1 : 0,
			};
			if (deleteThresholds[THRESHOLD_INHERENT] && deleteThresholds[THRESHOLD_INHERENT].length) postData.delete_thresh_ids = deleteThresholds[THRESHOLD_INHERENT];
			saveThresholds({
				$btn: $(event.target),
				type: THRESHOLD_INHERENT,
				postData,
				newThreshFormGroups,
			});
		});

	// Residual

	inputIntMinMax($('#setup-residual-threshold').find('input.value'), -1000, 1000);

	$('#setup_form_residual_save')
		.off('click')
		.on('click', (event) => {
			event.preventDefault();

			thresholdSort($('#setup-residual-threshold'), 0);

			if ($('#setup-residual-threshold').find('.form-group').length == 0) {
				displayNotification('Residual Risk', 'At least one Risk Tier is required, starting at 0 points.', 'danger');
				return;
			}

			const thresholds = [];
			const newThreshFormGroups = {};
			let blankThresholdFound = false;
			$('#setup-residual-threshold')
				.find('.form-group')
				.each((index, element) => {
					const id = $(element).data('id') || null;
					const title = $(element).find('input.title').length == 1 ? $(element).find('input.title').val() : '';
					const value = $(element).find('input.value').length == 1 ? $(element).find('input.value').val() : '';
					const color = $(element).find('input.colorpicker').length == 1 ? $(element).find('input.colorpicker').val() : '';

					if (title == '' || value == '' || color == '') {
						blankThresholdFound = true;
						return;
					}

					if (id === null) newThreshFormGroups[index] = $(element);
					thresholds.push({
						thresh_id: id,
						thresh_title: title,
						thresh_value: value,
						thresh_color: color,
						thresh_order: index,
					});
				});

			if (blankThresholdFound) {
				displayNotification('Risk Tiers', 'Please fill out each risk tier defined.', 'danger');
				return;
			}

			const postData: any = {
				thresh_type: THRESHOLD_RESIDUAL,
				thresholds,
			};
			if (deleteThresholds[THRESHOLD_RESIDUAL] && deleteThresholds[THRESHOLD_RESIDUAL].length) postData.delete_thresh_ids = deleteThresholds[THRESHOLD_RESIDUAL];
			saveThresholds({
				$btn: $(event.target),
				type: THRESHOLD_RESIDUAL,
				postData,
				newThreshFormGroups,
			});
		});

	// SLA

	inputFloatMinMax($('#setup-sla-rating').find('input.value'), -3, 3);
	inputIntMinMax($('#setup-sla-threshold').find('input.value'), -300, 300);

	$('#setup_form_contracts_sla_save')
		.off('click')
		.on('click', (event) => {
			event.preventDefault();

			if ($('#setup-sla-threshold').find('.form-group').length == 0) {
				displayNotification('SLA', 'At least one SLA Score Tier is required, starting at 0%.', 'danger');
				return;
			}
			if ($('#setup-sla-rating').find('.form-group').length == 0) {
				displayNotification('SLA', 'At least one SLA Rating is required.', 'danger');
				return;
			}

			thresholdSort($('#setup-sla-threshold'), 0);

			const thresholds = [];
			const newThreshFormGroups = {};
			let blankThresholdFound = false;
			$('#setup-sla-threshold')
				.find('.form-group')
				.each((index, element) => {
					const id = $(element).data('id') || null;
					const title = $(element).find('input.title').length == 1 ? $(element).find('input.title').val() : '';
					const value = $(element).find('input.value').length == 1 ? $(element).find('input.value').val() : '';
					const color = $(element).find('input.colorpicker').length == 1 ? $(element).find('input.colorpicker').val() : '';

					if (title == '' || value == '' || color == '') {
						blankThresholdFound = true;
						return;
					}

					if (id === null) newThreshFormGroups[index] = $(element);
					thresholds.push({
						thresh_id: id,
						thresh_title: title,
						thresh_value: value,
						thresh_color: color,
						thresh_order: index,
					});
				});

			if (blankThresholdFound) {
				displayNotification('Score Tiers', 'Please fill out each risk tier defined.', 'danger');
				return;
			}

			thresholdSort($('#setup-sla-rating'), 1);

			const contractRatings = [];
			const newRatingsFormGroups = {};
			let blankRatingFound = false;
			$('#setup-sla-rating')
				.find('.form-group')
				.each((index, element) => {
					const id = $(element).data('id') || null;
					const title = $(element).find('input.title').length == 1 ? $(element).find('input.title').val() : '';
					const value = $(element).find('input.value').length == 1 ? $(element).find('input.value').val() : '';
					if (title == '' || value == '') {
						blankRatingFound = true;
						return;
					}

					if (id === null) newRatingsFormGroups[index] = $(element);
					contractRatings.push({
						id,
						title,
						value,
						order: index,
					});
				});
			if (blankRatingFound) {
				displayNotification('Ratings', 'Please fill out each rating defined.', 'danger');
				return;
			}

			const postData: any = {
				thresh_type: THRESHOLD_SLA,
				thresholds,
				ratings: contractRatings,
			};
			if (deleteThresholds[THRESHOLD_SLA] && deleteThresholds[THRESHOLD_SLA].length) postData.delete_thresh_ids = deleteThresholds[THRESHOLD_SLA];
			if (deleteRatings[RATING_SLA] && deleteRatings[RATING_SLA].length) postData.delete_rating_ids = deleteRatings[RATING_SLA];
			saveThresholds({
				$btn: $(event.target),
				type: THRESHOLD_SLA,
				postData,
				newThreshFormGroups,
				newRatingsFormGroups,
			});
		});

	// KPI

	inputFloatMinMax($('#setup-kpi-rating').find('input.value'), -3, 3);
	inputIntMinMax($('#setup-kpi-threshold').find('input.value'), -300, 300);

	$('#setup_form_contracts_kpi_save')
		.off('click')
		.on('click', (event) => {
			event.preventDefault();

			if ($('#setup-kpi-threshold').find('.form-group').length == 0) {
				displayNotification('KPI', 'At least one KPI Score Tier is required, starting at 0%.', 'danger');
				return;
			}
			if ($('#setup-kpi-rating').find('.form-group').length == 0) {
				displayNotification('KPI', 'At least one KPI Rating is required.', 'danger');
				return;
			}

			thresholdSort($('#setup-kpi-threshold'), 0);

			const thresholds = [];
			const newThreshFormGroups = {};
			let blankThresholdFound = false;
			$('#setup-kpi-threshold')
				.find('.form-group')
				.each((index, element) => {
					const id = $(element).data('id') || null;
					const title = $(element).find('input.title').length == 1 ? $(element).find('input.title').val() : '';
					const value = $(element).find('input.value').length == 1 ? $(element).find('input.value').val() : '';
					const color = $(element).find('input.colorpicker').length == 1 ? $(element).find('input.colorpicker').val() : '';

					if (title == '' || value == '' || color == '') {
						blankThresholdFound = true;
						return;
					}

					if (id === null) newThreshFormGroups[index] = $(element);
					thresholds.push({
						thresh_id: id,
						thresh_title: title,
						thresh_value: value,
						thresh_color: color,
						thresh_order: index,
					});
				});

			if (blankThresholdFound) {
				displayNotification('Score Tiers', 'Please fill out each risk tier defined.', 'danger');
				return;
			}

			thresholdSort($('#setup-kpi-rating'), 1);

			const contractRatings = [];
			const newRatingsFormGroups = {};
			let blankRatingFound = false;
			$('#setup-kpi-rating')
				.find('.form-group')
				.each((index, element) => {
					const id = $(element).data('id') || null;
					const title = $(element).find('input.title').length == 1 ? $(element).find('input.title').val() : '';
					const value = $(element).find('input.value').length == 1 ? $(element).find('input.value').val() : '';
					if (title == '' || value == '') {
						blankRatingFound = true;
						return;
					}

					if (id === null) newRatingsFormGroups[index] = $(element);
					contractRatings.push({
						id,
						title,
						value,
						order: index,
					});
				});
			if (blankRatingFound) {
				displayNotification('Ratings', 'Please fill out each rating defined.', 'danger');
				return;
			}

			const postData: any = {
				thresh_type: THRESHOLD_KPI,
				thresholds,
				ratings: contractRatings,
			};
			if (deleteThresholds[THRESHOLD_KPI] && deleteThresholds[THRESHOLD_KPI].length) postData.delete_thresh_ids = deleteThresholds[THRESHOLD_KPI];
			if (deleteRatings[RATING_KPI] && deleteRatings[RATING_KPI].length) postData.delete_rating_ids = deleteRatings[RATING_KPI];
			saveThresholds({
				$btn: $(event.target),
				type: THRESHOLD_KPI,
				postData,
				newThreshFormGroups,
				newRatingsFormGroups,
			});
		});

	// Vendor Value

	inputFloatMinMax($('#setup-vv-rating').find('input.value'), -3, 3);

	$('#setup_form_vendorvalue_save')
		.off('click')
		.on('click', (event) => {
			event.preventDefault();

			if ($('#setup-vv-criteria').find('.form-group').length == 0) {
				displayNotification('Vendor Value', 'At least one Criteria is required.', 'danger');
				return;
			}
			if ($('#setup-vv-rating').find('.form-group').length == 0) {
				displayNotification('Vendor Value', 'At least one Rating is required.', 'danger');
				return;
			}
			if ($('#setup-vv-threshold').find('.form-group').length == 0) {
				displayNotification('Vendor Value', 'At least one Value is required, starting at 0%.', 'danger');
				return;
			}

			const criteria = [];
			const newCriteriaFormGroups = {};
			let blankCriteriaFound = false;
			$('#setup-vv-criteria')
				.find('.form-group')
				.each((index, element) => {
					const id = $(element).data('id') || null;
					const title = $(element).find('input.title').length == 1 ? $(element).find('input.title').val() : 'Unknown';
					const description = $(element).find('textarea.value').length == 1 ? $(element).find('textarea.value').val() : '';
					if (title == '' || description == '') {
						blankCriteriaFound = true;
						return;
					}

					if (id === null) newCriteriaFormGroups[index] = $(element);
					criteria.push({
						id,
						title,
						description,
						order: index,
					});
				});
			if (blankCriteriaFound) {
				displayNotification('Criteria', 'Please fill out each criteria defined.', 'danger');
				return;
			}

			thresholdSort($('#setup-vv-rating'), 1);

			const contractRatings = [];
			const newRatingsFormGroups = {};
			let blankRatingFound = false;
			$('#setup-vv-rating')
				.find('.form-group')
				.each((index, element) => {
					const id = $(element).data('id') || null;
					const title = $(element).find('input.title').length == 1 ? $(element).find('input.title').val() : '';
					const value = $(element).find('input.value').length == 1 ? $(element).find('input.value').val() : '';
					if (title == '' || value == '') {
						blankRatingFound = true;
						return;
					}

					if (id === null) newRatingsFormGroups[index] = $(element);
					contractRatings.push({
						id,
						title,
						value,
						order: index,
					});
				});
			if (blankRatingFound) {
				displayNotification('Ratings', 'Please fill out each rating defined.', 'danger');
				return;
			}

			thresholdSort($('#setup-vv-threshold'), 0);

			const thresholds = [];
			const newThreshFormGroups = {};
			let blankThresholdFound = false;
			$('#setup-vv-threshold')
				.find('.form-group')
				.each((index, element) => {
					const id = $(element).data('id') || null;
					const title = $(element).find('input.title').length == 1 ? $(element).find('input.title').val() : '';
					const value = $(element).find('input.value').length == 1 ? $(element).find('input.value').val() : '';
					const color = $(element).find('input.colorpicker').length == 1 ? $(element).find('input.colorpicker').val() : '';

					if (title == '' || value == '' || color == '') {
						blankThresholdFound = true;
						return;
					}

					if (id === null) newThreshFormGroups[index] = $(element);
					thresholds.push({
						thresh_id: id,
						thresh_title: title,
						thresh_value: value,
						thresh_color: color,
						thresh_order: index,
					});
				});

			if (blankThresholdFound) {
				displayNotification('Score Tiers', 'Please fill out each risk tier defined.', 'danger');
				return;
			}

			const postData: any = {
				thresh_type: THRESHOLD_VENDORVALUE,
				criteria,
				ratings: contractRatings,
				thresholds,
			};
			if (deleteCriteria[ASSESSMENT_VENDORVALUE] && deleteCriteria[ASSESSMENT_VENDORVALUE].length) postData.delete_criteria_ids = deleteCriteria[ASSESSMENT_VENDORVALUE];
			if (deleteRatings[RATING_VENDORVALUE] && deleteRatings[RATING_VENDORVALUE].length) postData.delete_rating_ids = deleteRatings[RATING_VENDORVALUE];
			if (deleteThresholds[THRESHOLD_VENDORVALUE] && deleteThresholds[THRESHOLD_VENDORVALUE].length) postData.delete_thresh_ids = deleteThresholds[THRESHOLD_VENDORVALUE];
			saveThresholds({
				$btn: $(event.target),
				type: THRESHOLD_VENDORVALUE,
				postData,
				newCriteriaFormGroups,
				newRatingsFormGroups,
				newThreshFormGroups,
			});
		});
}

var userdt: DataTables.Api = null;
if ($('#user_form').length == 1) {
	$('a[href="#tab-users"]').on('show.bs.tab', function (e) {
		if (userdt == null) {
			init_users();
		} else {
			userdt.ajax.reload();
		}
	});
}

function init_users() {
	let inviteAllDt: DataTables.Api | null = null;
	var dtoptions: DataTables.Settings = {
		ajax: {
			url: '/data/users_load',
			data: (postData) => ({
				...postData,
				mode: 0,
				filters: {
					inactive: $('#user_search_inactive').is(':checked') ? 1 : 0,
				},
			}),
			dataSrc: (table) => {
				$('#user_invite_all').off('click');
				$('#user_invite_all_cont').hide();
				if ($('#user_invite_all_cont').length && table.inviteUsers) {
					$('#user_invite_all_count').text(table.inviteUsers.length);
					$('#user_invite_all').on('click', () => {
						const tableColumns: DataTables.ColumnSettings[] = [
							{
								data: null,
								defaultContent: '',
								orderable: false,
								searchable: false,
								className: 'select-checkbox',
							},
							{
								title: 'First',
								data: 'first',
							},
							{
								title: 'Last',
								data: 'last',
							},
							{
								title: 'Email',
								data: 'email',
							},
						];
						if (inviteAllDt) inviteAllDt.destroy();
						inviteAllDt = $('#invite_all_table')
						.DataTable({
							columns: tableColumns,
							data: table.inviteUsers,
							initComplete: function () {
								$('#invite_all_modal').find('.datatables-select-all').prop('checked', true);
								this.api().rows().select();
							},
							order: [[2, 'asc'], [1, 'asc']],
							paging: false,
							processing: false,
							responsive: false,
							scrollY: 'calc(65vh - 120px)',
							select: {
								style: 'os',
								selector: 'td:first-child',
							},
							serverSide: false,
						});

						$('#invite_all_modal').on('change', '.datatables-select-all', ({ target }) => {
							const isChecked = $(target).is(':checked');
							if (isChecked) inviteAllDt.rows().select();
							else inviteAllDt.rows().deselect();
						});

						$('#invite_all_confirm').off('click').on('click', async () => {
							$('#invite_all_confirm').prop('disabled', true);

							const ids = inviteAllDt.rows({ selected: true }).data().toArray().map(({id}) => id);
							try {
								const res = await ajaxPromise('/form/submit', {type: 'users_invite', data: {ids}});
								if (res.rc !== 'OK') throw new Error(res);
								displayNotification('Invite Users', 'Invited users to 3PT.', 'success');
								$('#invite_all_modal').modal('hide');
							}
							catch (error) {
								displayNotification('Invite Users', 'An error occured while inviting users', 'danger');
								logerror('user invite', error);
							}

							$('#invite_all_confirm').prop('disabled', false);
							userdt.ajax.reload();
						});
						$('#invite_all_modal').modal('show').one('shown.bs.modal', () => $(window).trigger('resize'));
					});
					$('#user_invite_all_cont').show();
				}

				return table.data;
			},
		},
		columns: [
			{
				title: 'First',
				data: 'usr_firstname',
			},
			{
				title: 'Last',
				data: 'usr_lastname',
			},
			{
				title: 'Email',
				data: 'usr_email',
			},
			{
				title: 'Role',
				data: 'usr_role_nice',
			},
			{
				title: 'Start',
				data: 'usr_startdate_short',
			},
			{
				title: 'End',
				data: 'usr_enddate_short',
			},
			{
				title: 'Status',
				data: 'usr_status_nice',
			},
			{
				title: 'Actions',
				data: 'buttons',
				className: 'text-right',
				orderable: false,
				searchable: false,
			},
		],
		columnDefs: [
			{ responsivePriority: 1, targets: 0 },
			{ responsivePriority: 2, targets: -1 },
		],
		order: [
			[1, 'asc'],
			[0, 'asc'],
			[2, 'asc'],
		],
	};

	userdt = $('#users_table')
		.on('init.dt, draw.dt', function () {})
		.DataTable(dtoptions);

	$('#users_table').on('click', '.user_edit', ({ target }) => {
		const data = getDtRowData(userdt, target);
		user_edit({
			id: data.usr_id,
			status: parseInt(data.usr_status),
			fname: data.usr_firstname,
			lname: data.usr_lastname,
			email: data.usr_email,
			role: data.usr_role,
			phone: data.usr_phone,
			address1: data.usr_address1,
			address2: data.usr_address2,
			city: data.usr_city,
			state: data.usr_state,
			postalcode: data.usr_postalcode,
			country: data.usr_country,
			startdate: data.usr_startdate,
			enddate: data.usr_enddate,
			invited: data.usr_invited,
			groups: data.groups,
		});
	});

	$('#users_table').on('click', '.user_reactivate', ({ target }) => {
		const data = getDtRowData(userdt, target);
		userArchive(
			{
				id: data.usr_id,
				fname: data.usr_firstname,
				lname: data.usr_lastname,
			},
			USER_ACTIVE
		);
	});

	$('#user_search_inactive').on('change', function () {
		setTimeout(function () {
			userdt.ajax.reload();
		}, 500);
	});

	$('#user_add').off('click');
	$('#user_add').on('click', function (e) {
		user_edit({
			id: 0,
		});
		e.preventDefault();
	});

	const userArchive = async (user, status: number) => {
		const language: {[status: string]: {[key: string]: string, style: BootstrapFlavor}} = {
			[USER_ACTIVE]: {title: 'Reactivate', verb: 'reactivate', action: 'reactivated', style: 'success'},
			[USER_INACTIVE]: {title: 'Deactivate', verb: 'deactivate', action: 'deactivated', style: 'warning'},
		};
		const {title, verb, action, style} = language[status];

		const confirmed = await confirmDialog({
			dialogTitle: `User ${title}`,
			bodyText: `Are you sure you would like to ${verb} user "${user.fname} ${user.lname}"?`,
			confirmText: title,
			confirmStyle: style,
		});
		if (!confirmed) return;

		try {
			const res = await ajaxPromise('/form/submit', {
				type: 'user_archive',
				data: {
					id: user.id,
					status,
				},
			});
			if (res.rc !== 'OK') throw res;
			displayNotification(`${title} Success`, `User ${action}.`, 'success');
			userdt.ajax.reload();
			closeform();
			$('#delete_user').modal('hide');
		}
		catch (error) {
			switch (error.rc) {
				case 'NO_SELF': // archive only
					displayNotification(`${title} Failed`, `You cannot ${verb} yourself.`, 'danger');
					break;
				case 'MANAGER_ELEVATE':
					displayNotification(`${title} Error`, 'You cannot edit other Managers or Admins as a Manager.', 'danger');
					break;
				default:
					displayNotification(`${title} Failed`, 'There was an error updating the user.', 'danger');
					logerror('user archive', error);
					break;
			}
		}
	};

	function closeform() {
		$('#user_form :input').val('');
		$('#user_edit_container').collapse('hide');
		$('#user_add').parent().show();
	}

	var uservendorassignmentdt: DataTables.Api = null;

	function user_vendor_assignments(user) {
		// user list with actions to "add to" or "replace" this user's assignments with another's.

		//table
		if (uservendorassignmentdt !== null) {
			uservendorassignmentdt.destroy();
			uservendorassignmentdt = null;
		}

		var dtoptions: DataTables.Settings = {
			ajax: {
				url: '/data/users_load',
				data: (postData) => ({
					...postData,
					mode: 1,
					filters: {
						userid: user.id,
						inactive: $('#user_vendor_assignment_search_inactive').is(':checked') ? 1 : 0,
					},
				}),
			},
			columns: [
				{
					title: 'First',
					data: 'usr_firstname',
				},
				{
					title: 'Last',
					data: 'usr_lastname',
				},
				{
					title: 'Email',
					data: 'usr_email',
				},
				{
					title: 'Status',
					data: 'usr_status_nice',
				},
				{
					title: 'Actions',
					data: 'buttons',
					className: 'text-right',
					orderable: false,
					searchable: false,
				},
			],
			columnDefs: [
				{ responsivePriority: 1, targets: 0 },
				{ responsivePriority: 2, targets: -1 },
			],
			order: [
				[1, 'asc'],
				[0, 'asc'],
				[2, 'asc'],
			],
		};

		uservendorassignmentdt = $('#user_vendor_assignment_table')
			.on('init.dt, draw.dt', function () {})
			.DataTable(dtoptions);

		$('#user_vendor_assignment_table').on('click', '.user_addfrom', async ({ target }) => {
			const data = getDtRowData(uservendorassignmentdt, target);

			const confirmed = await confirmDialog({
				dialogTitle: 'Add Assignments From...',
				bodyText: `Are you sure you would like to add the assignments from ${data.usr_firstname} ${data.usr_lastname} to ${user.fname} ${user.lname}?`,
				confirmText: 'Add',
				confirmStyle: 'success',
			});
			if (!confirmed) return;

			try {
				const res = await ajaxPromise('/form/submit', {
					type: 'user_assignments_addreplace',
					data: {
						mode: 0,
						from_id: data.usr_id,
						to_id: user.id,
					},
				});
				if (res.rc !== 'OK') throw res;
				displayNotification('Add Success', `The assignments were added to ${user.fname} ${user.lname} successfully.`, 'success');
				$('#setup_user_vendor_assignment_container').modal('hide');
			}
			catch (error) {
				displayNotification('Add Error', 'There was an error adding the assignments.', 'danger');
				logerror('user assignment add', error);
			}
		});

		$('#user_vendor_assignment_table').on('click', '.user_replacefrom', async ({ target }) => {
			const data = getDtRowData(uservendorassignmentdt, target);

			const confirmed = await confirmDialog({
				dialogTitle: 'Replace Assignments With...',
				bodyText: `Are you sure you would like to replace the assignments of ${user.fname} ${user.lname} with ${data.usr_firstname} ${data.usr_lastname}'s?`,
				confirmText: 'Add',
				confirmStyle: 'success',
			});
			if (!confirmed) return;

			try {
				const res = await ajaxPromise('/form/submit', {
					type: 'user_assignments_addreplace',
					data: {
						mode: 1,
						from_id: data.usr_id,
						to_id: user.id,
					},
				});
				if (res.rc !== 'OK') throw res;
				displayNotification('Replace Success', `The assignments for ${user.fname} ${user.lname} were replaced successfully.`, 'success');
				$('#setup_user_vendor_assignment_container').modal('hide');
			}
			catch (error) {
				displayNotification('Replace Error', 'There was an error replacing the assignments.', 'danger');
				logerror('user assignment replace', error);
			}
		});

		$('#user_vendor_assignment_search_inactive').on('change', function () {
			setTimeout(function () {
				uservendorassignmentdt.ajax.reload();
			}, 500);
		});

		$('#setup_user_vendor_assignment_container').modal();
	}

	toggleSlide($('#user_form_permissions_toggle'), $('#user_form_permissions_container'));

	let permissionsDt: DataTables.Api;
	$(window).on('resize', async () => {
		if (!permissionsDt) return;
		await setTimeoutPromise(0);
		permissionsDt.fixedColumns().relayout();
	});

	function user_edit(user) {
		setTimeout(function () {
			highlight($('#user_edit_container'));
			$(window).scrollTop($('#user_edit_container').offset().top - 400);
		}, 400);

		function user_role_hint(role) {
			var hint = '';

			switch (parseInt(role)) {
				case SITEROLE_READONLY:
					hint = '<strong>Read-only:</strong> Can only access vendors within the group to which it is assigned and can only view the functions (read-only) to which it is assigned.';
					break;
				case SITEROLE_AUDITOR:
					hint = '<strong>Auditor:</strong> Has read-only access to all functions across all vendors and can access Reporting. An Auditor cannot access any Setup functions.';
					break;
				case SITEROLE_USER:
					hint = '<strong>User:</strong> Can only access vendors within the group(s) to which it is assigned and perform only the vendor functions assigned.';
					break;
				case SITEROLE_MANAGER:
					hint = '<strong>Manager:</strong> Can add members, vendors, groups and assign vendors & members to groups and assign vendor functions to members within groups. Manager can access Reporting. A Manager cannot access any setup functions pertaining to scoring, risk tiers, etc.';
					break;
				case SITEROLE_ADMIN:
					hint = '<strong>Admin:</strong> Superuser can do anything within 3PT.';
					break;
				case SITEROLE_RELOWNER:
					hint = '<strong>Relationship Owner:</strong> Can be listed as a Relationship Owner for Vendors, but cannot access any features of 3PT.';
					break;
				case SITEROLE_ALERTUSR:
					hint = '<strong>Alert User:</strong> Can be listed as a Relationship Owner for Vendors and/or Contracts, but cannot access any features of 3PT.';
					break;
			}

			$('#user_form_role_hint').html(hint);
		}

		$('#user_form_role').on('change', function () {
			user_role_hint($(this).val());
		});

		//logme('user_edit:');
		//logme(user);
		$('#user_form_invite').hide().off('click');
		if (user.id === 0) {
			$('#user_form_id').html('');
			$('#user_form_submit').html('Save');
			$('#user_form_fname').val('');
			$('#user_form_lname').val('');
			$('#user_form_email').val('');
			$('#user_form_role').val('-1');
			$('#user_form_phone').val('');
			$('#user_form_address1').val('');
			$('#user_form_address2').val('');
			$('#user_form_city').val('');
			$('#user_form_state').val('');
			$('#user_form_postalcode').val('');
			$('#user_form_country').val('');
			user_role_hint(user.role);
			$('#user_form_startdate').val('');
			$('#user_form_enddate').val('');
			$('#user_form_invite_toggle').prop('checked', true);
			$('#user_form_invite_toggle_cont').show();
			$('#user_form_archive').off('click').hide();
			$('#user_form_vendor_assignments').off('click').hide();
			$('#user_form_permissions_section').hide();
			//			$('#user_form_groups_list').html('').hide();
		} // edit
		else {
			$('#user_form_id').html(user.id);
			$('#user_form_submit').html('Update');
			$('#user_form_fname').val(user.fname);
			$('#user_form_lname').val(user.lname);
			$('#user_form_email').val(user.email);
			$('#user_form_role').val(user.role);
			$('#user_form_phone').val(user.phone);
			$('#user_form_address1').val(user.address1);
			$('#user_form_address2').val(user.address2);
			$('#user_form_city').val(user.city);
			$('#user_form_state').val(user.state);
			$('#user_form_postalcode').val(user.postalcode);
			$('#user_form_country').val(user.country);
			user_role_hint(user.role);
			if (user.startdate !== null) {
				$('#user_form_startdate_checkbox').prop('checked', true);
				$('#user_form_startdate').val(user.startdate).prop('disabled', false);
			} else {
				$('#user_form_startdate_checkbox').prop('checked', false);
				$('#user_form_startdate').prop('disabled', true);
			}
			if (user.enddate !== null) {
				$('#user_form_enddate_checkbox').prop('checked', true);
				$('#user_form_enddate').val(user.enddate);
			} else {
				$('#user_form_enddate_checkbox').prop('checked', false);
				$('#user_form_enddate').prop('disabled', true);
			}

			$('#user_form_invite_toggle_cont').hide();

			$('#user_form_archive').show().off('click').on('click', (event) => {
				event.preventDefault();
				userArchive(user, USER_INACTIVE);
			});

			$('#user_form_permissions_section').show();
			$('#user_form_permissions_table_name').text(`${user.fname} ${user.lname}`);

			$('#user_form_permissions_toggle').find('i').addClass('fa-caret-down').removeClass('fa-caret-up');
			$('#user_form_permissions_container').hide();

			$('#user_form_permissions_container')
				.off('3pt.toggle.show')
				.one('3pt.toggle.show', () => {
					const roleColumnsMap = {
						role_details_access: ACCESS_DETAILS,
						role_inherent_access: ACCESS_INHERENT,
						role_diligence_access: ACCESS_DILIGENCE,
						role_review_access: ACCESS_REVIEW,
						role_vendorvalue_access: ACCESS_VALUE,
						role_contracts_access: ACCESS_CONTRACTS,
						role_performance_access: ACCESS_ASSESSMENT,
						role_documents_access: ACCESS_DOCUMENTS,
					};
					const roleColumns = Object.keys(roleColumnsMap);

					const roleControl = (col, value) => `
						<select class="role-col form-control btn-success" data-col="${col}">
							<option value="${PRIV_NONE}">None</option>
							<option value="${PRIV_READ}" ${value == PRIV_READ ? 'selected' : ''}>Read</option>
							${col == 'role_documents_access' ? `<option value="${PRIV_WRITE}" ${value == PRIV_WRITE ? 'selected' : ''}>Write</option>` : ''}
							<option value="${PRIV_MANAGE}" ${value == PRIV_MANAGE ? 'selected' : ''}>Manage</option>
						</select>
					`;
					const notifyControl = (col, value) => `
						<select class="${col} form-control btn-success" data-col="${col}">
							<option value="0">No</option>
							<option value="1" ${value == 1 ? 'selected' : ''}>Yes</option>
						</select>
					`;

					// https://datatables.net/manual/data/orthogonal-data
					const dtColumns: DataTables.ColumnSettings[] = [
						{
							title: 'Vendor',
							data: 'vend_name',
							className: 'pt-col-divider-end',
							render: (val) => htmlEsc(val),
						},
						{
							title: 'Relationship Owner',
							data: 'vrel_rank',
							render: (value, type) => {
								if (type === 'display') {
									return `
									<select class="vrel_rank form-control btn-success" data-col="vrel_rank">
										<option value="${RELOWNER_NONE}">No</option>
										<option value="${RELOWNER_ANALYST}" ${value == RELOWNER_ANALYST ? 'selected' : ''}>Analyst</option>
										<option value="${RELOWNER_SECONDARY}" ${value == RELOWNER_SECONDARY ? 'selected' : ''}>Secondary</option>
										<option value="${RELOWNER_PRIMARY}" ${value == RELOWNER_PRIMARY ? 'selected' : ''}>Primary</option>
									</select>
								`;
								}
								if (type === 'sort') {
									const rank_sorting_values = [null, RELOWNER_NONE, RELOWNER_ANALYST, RELOWNER_SECONDARY, RELOWNER_PRIMARY];
									const sort_value = rank_sorting_values.indexOf(+value);
									return sort_value;
								}
								return value;
							},
							searchable: false,
						},
						{
							title: 'Access/Details',
							data: 'role_details_access',
							render: (value, type) => {
								if (type === 'display') return roleControl('role_details_access', value);
								return value;
							},
							searchable: false,
						},
						{
							title: 'Inherent Risk',
							data: 'role_inherent_access',
							render: (value, type) => {
								if (type === 'display') return roleControl('role_inherent_access', value);
								return value;
							},
							searchable: false,
						},
						{
							title: 'Due Diligence',
							data: 'role_diligence_access',
							render: (value, type) => {
								if (type === 'display') return roleControl('role_diligence_access', value);
								return value;
							},
							searchable: false,
						},
						{
							title: 'Periodic Review',
							data: 'role_review_access',
							render: (value, type) => {
								if (type === 'display') return roleControl('role_review_access', value);
								return value;
							},
							searchable: false,
						},
						{
							title: 'Vendor Value',
							data: 'role_vendorvalue_access',
							render: (value, type) => {
								if (type === 'display') return roleControl('role_vendorvalue_access', value);
								return value;
							},
							searchable: false,
						},
						{
							title: 'Contracts',
							data: 'role_contracts_access',
							render: (value, type) => {
								if (type === 'display') return roleControl('role_contracts_access', value);
								return value;
							},
							searchable: false,
						},
						{
							title: 'SLA / KPI',
							data: 'role_performance_access',
							render: (value, type) => {
								if (type === 'display') return roleControl('role_performance_access', value);
								return value;
							},
							searchable: false,
						},
						{
							title: 'Documents',
							data: 'role_documents_access',
							render: (value, type) => {
								if (type === 'display') return roleControl('role_documents_access', value);
								return value;
							},
							searchable: false,
						},
						{
							title: 'Notify Reviews',
							data: 'vrel_reviews',
							className: 'pt-col-divider-start',
							render: (value, type) => {
								if (type === 'display') return notifyControl('vrel_reviews', value);
								return value;
							},
							searchable: false,
						},
						{
							title: 'Notify Contracts',
							data: 'vrel_contracts',
							render: (value, type) => {
								if (type === 'display') return notifyControl('vrel_contracts', value);
								return value;
							},
							searchable: false,
						},
						{
							title: 'Notify Documents',
							data: 'vrel_documents',
							render: (value, type) => {
								if (type === 'display') return notifyControl('vrel_documents', value);
								return value;
							},
							searchable: false,
						},
						{
							title: 'Has Value',
							data: 'has_value',
							visible: false,
							searchable: false,
						},
					];
					const dtColIndicies = dtColumnIndicies(dtColumns);
					const dtOptions: DataTables.Settings = {
						ajax: {
							url: '/data/user_permissions_load',
							data: {
								usr_id: user.id,
							},
						},
						buttons: [
							{
								text: '<i class="fa fa-file-csv me-2"></i> Export',
								className: 'ml-2 btn btn-sm btn-secondary',
								action: ({target}) => {
									$(target).prop('disabled', true);
									post('/export', { type: 'user_permissions', data: user.id }, '_blank');
									setTimeout(() => $(target).prop('disabled', false), 1000);
								},
							},
						],
						columns: dtColumns,
						drawCallback: () => triggerWindowResize(),
						fixedColumns: true,
						initComplete: () => {
							const $scroll = $('#user_form_permissions_table_wrapper').find('.dataTables_scroll');
							$scroll.find('.dataTables_scrollBody').doubleScroll({ $insertBefore: $scroll });
						},
						language: {
							info: "Showing <span class='txt-color-darken'>_START_</span> to <span class='txt-color-darken'>_END_</span> of <span class='text-primary'>_TOTAL_</span> vendors",
							infoEmpty: 'No vendors to show',
							infoFiltered: "(filtered from <span class='txt-color-darken'>_MAX_</span> total vendors)",
							lengthMenu: 'Show _MENU_ vendors',
						},
						order: [
							[dtColIndicies.has_value, 'desc'],
							[dtColIndicies.vend_name, 'asc'],
						],
						responsive: false,
						scrollX: true,
						deferLoading: 1,
					};

					const isRoleCol = (col) => roleColumns.includes(col);

					const setColorPriv = ($select: $) => {
						$select.removeClass('btn-success btn-warning btn-info');
						const value = +$select.val();
						if (isRoleCol($select.data('col'))) {
							const classes = {
								[PRIV_NONE]: '',
								[PRIV_READ]: 'btn-warning',
								[PRIV_WRITE]: 'btn-info',
								[PRIV_MANAGE]: 'btn-success',
							};
							const classForPriv = classes[value];
							$select.addClass(classForPriv);
						} else if (value > 0) $select.addClass('btn-success');
					};

					const selectChange = async ($select: $) => {
						setColorPriv($select);

						const col = $select.data('col');
						const data = getDtRowData(permissionsDt, $select.get(0));
						const value = +$select.val();

						$('#spinner').show();

						if (isRoleCol(col)) {
							const postData = {
								usr_id: user.id,
								role_id: data.role_id,
								vend_id: data.vend_id,
								privilege_key: roleColumnsMap[col],
								privilege_value: value,
								update_all: 0,
							};

							try {
								const res = await ajaxPromise('/form/submit', {type: 'user_permissions_save', data: postData});
								if (res.rc !== 'OK') throw res;

								displayNotification('Permissions Success', 'Permission changed successfully.', 'success');
								permissionsDt.ajax.reload();
							} catch (error) {
								displayNotification('Permissions Error', 'There was an error changing the permission.', 'danger');
								logerror('user permissions submit', error);
							}
						} else {
							const postData = {
								user_id: user.id,
								vendor_id: data.vend_id,
								[col]: value,
							};

							try {
								const res = await ajaxPromise('/form/submit', {type: 'user_vendor_add', data: postData});
								if (res.rc !== 'OK') throw res;

								if (col === 'vrel_rank' && value == RELOWNER_PRIMARY && res.old_primary_usr_name) {
									displayNotification({
										context: 'Relationship Owners',
										msg: `Primary Relationship Owner moved from <b>${res.old_primary_usr_name}</b> to <b>${user.fname} ${user.lname}</b>.`,
										style: 'success',
										time: 10000,
									});
								} else displayNotification('Permissions Success', 'Permission changed successfully.', 'success');
								permissionsDt.ajax.reload();
							} catch (error) {
								displayNotification('Permissions Error', 'There was an error changing the permission.', 'danger');
								logerror('user permissions submit', error);
							}
						}

						$('#spinner').hide();
					};

					const selectSetAllChange = async ($select: $) => {
						setColorPriv($select);

						if ($select.val() === '') return;

						const col = $select.data('col');
						const value = +$select.val();

						const resetSelect = () => {
							$select.val('');
							setColorPriv($select);
						};

						if (isRoleCol(col)) {
							const postData = {
								usr_id: user.id,
								role_id: null,
								vend_id: null,
								privilege_key: roleColumnsMap[col],
								privilege_value: value,
								from_vendor_edit: 0,
								update_all: 'user',
							};

							const title = dtColumns.find(({ data }) => data === col).title;
							const allPrivTexts = {
								[PRIV_NONE]: 'None',
								[PRIV_READ]: 'Read',
								[PRIV_WRITE]: 'Write',
								[PRIV_MANAGE]: 'Manage',
							};
							const privText = allPrivTexts[value];

							const confirmed = await confirmDialog({
								dialogTitle: `Change ${title} Permissions`,
								bodyText: `Are you sure you would like to change all <b>${title}</b> permissions to <b>${privText}</b>?`,
								confirmText: 'Save',
								confirmStyle: 'success',
							});
							if (!confirmed) {
								resetSelect();
								return;
							}

							try {
								const res = await ajaxPromise('/form/submit', {type: 'user_permissions_save', data: postData});
								if (res.rc !== 'OK') throw res;

								displayNotification('Permissions Success', 'All permissions for this user have been changed successfully.', 'success');
								permissionsDt.ajax.reload();
							} catch (error) {
								resetSelect();
								displayNotification('Permissions Error', 'There was an error changing the permissions.', 'danger');
								logerror('user set all permissions submit', error);
							}
						} else {
							const postData = {
								user_id: user.id,
								vendor_id: 'all',
								from_vendor_edit: 0,
								changetype: null,
								[col]: value,
							};

							const action = value ? 'add' : 'remove';
							const actionCapitalized = action.charAt(0).toUpperCase() + action.slice(1);
							const actionClass = value ? 'success' : 'danger';

							if (col == 'vrel_rank') {
								if (value == RELOWNER_PRIMARY) {
									const replaceOrMerge = await optionsDialog({
										dialogTitle: 'Primary Relationship',
										bodyText: 'Would you like to replace or merge with existing Primary Relationship Owners?',
										buttons: [
											{
												id: 'replace',
												confirmText: 'Replace',
												confirmStyle: 'warning',
											},
											{
												id: 'merge',
												confirmText: 'Merge',
												confirmStyle: 'success',
											},
										],
									});
									if (!replaceOrMerge) {
										resetSelect();
										return;
									}

									postData.changetype = replaceOrMerge;
								} else {
									const confirmed = await confirmDialog({
										dialogTitle: `${actionCapitalized} Relationship Owner`,
										bodyText: `Are you sure you would like to ${action} this ownership?`,
										confirmText: actionCapitalized,
										confirmStyle: actionClass,
									});
									if (!confirmed) {
										resetSelect();
										return;
									}
								}
							} else {
								const confirmed = await confirmDialog({
									dialogTitle: `${actionCapitalized} Notifications`,
									bodyText: `Are you sure you would like to ${action} these notifications?`,
									confirmText: actionCapitalized,
									confirmStyle: actionClass,
								});
								if (!confirmed) {
									resetSelect();
									return;
								}
							}

							try {
								const res = await ajaxPromise('/form/submit', {type: 'user_vendor_add', data: postData});
								if (res.rc !== 'OK') throw res;

								displayNotification('Permissions Success', 'All permissions for this user have been changed successfully.', 'success');
								permissionsDt.ajax.reload();
							} catch (error) {
								resetSelect();
								displayNotification('Permissions Error', 'There was an error changing the permissions.', 'danger');
								logerror('user set all permissions submit', error);
							}
						}
					};

					if (permissionsDt) permissionsDt.destroy();
					permissionsDt = $('#user_form_permissions_table').DataTable(dtOptions);

					$('#user_form_permissions_container').off('draw.dt').on('draw.dt', () => {
						$('#user_form_permissions_table select, #user_form_permissions_table_select_all_row select').each((_, element) => setColorPriv($(element)));
						$('#user_form_permissions_table_select_all_row')
							.find('select')
							.off('change')
							.on('change', ({ target }) => selectSetAllChange($(target)));
						permissionsDt
							.rows()
							.eq(0)
							.each((index) => {
								const row = permissionsDt.row(index) as any;
								const data = row.data();
								if (data.vrel_rank > 0) {
									const $row = $(row.node());
									$row.find('select.role-col').each((_, element) => {
										const $select = $(element);
										const bgColor = $select.css('background-color');
										$select
											.css({ cursor: 'not-allowed', 'background-color': `${bgColor} !important`, opacity: 0.7 })
											.addClass('vrel_locked')
											.attr('disabled', 'disabled');
									});
								}
							});
						$('#user_form_permissions_table')
							.find('select')
							.not('.vrel_locked')
							.off('change')
							.on('change', ({ target }) => selectChange($(target)));
					});
				});

			$('#user_form_vendor_assignments').show().off('click').on('click', (event) => {
				event.preventDefault();
				user_vendor_assignments(user);
			});

			if (+user.invited === 0) {
				$('#user_form_invite').show().on('click', async () => {
					const confirmed = await confirmDialog({
						dialogTitle: 'Invite User',
						bodyText: `Are you sure you want to invite "${user.fname} ${user.lname}" to 3PT?`,
						confirmText: 'Invite',
						confirmStyle: 'success',
					});
					if (!confirmed) return;

					try {
						const res = await ajaxPromise('/form/submit', {type: 'users_invite', data: {ids: [user.id]}});
						if (res.rc !== 'OK') throw res;
						displayNotification('Invite User', 'Invited user to 3PT.', 'success');
						$('#user_form_invite').hide();
						userdt.ajax.reload();
					}
					catch (error) {
						displayNotification('Invite User', 'An error occured while inviting the user', 'danger');
						logerror('user invite', error);
					}
				});
			}
		}
		$('#user_edit_container').collapse('show');
		$('#user_add').parent().hide();

		$('#user_form_cancel').off('click');
		$('#user_form_cancel').on('click', function () {
			closeform();
		});

		$('#user_form_startdate_checkbox, #user_form_enddate_checkbox').off('change');
		$('#user_form_startdate_checkbox, #user_form_enddate_checkbox').on('change', function () {
			var $input = $(this).closest('.input-group').find('input[type=date]');
			if ($(this).is(':checked')) {
				$input.prop('disabled', false);
			} else {
				$input.prop('disabled', true);
				$input.val('');
			}
		});

		$('#user_form_submit').off('click');
		$('#user_form_submit').on('click', async function (event) {
			event.preventDefault();
			var data = JSON.parse(JSON.stringify(user));

			data.fname = $('#user_form_fname').val();
			data.lname = $('#user_form_lname').val();
			data.email = $('#user_form_email').val();
			data.role = parseInt($('#user_form_role').val().toString());
			data.phone = $('#user_form_phone').val();
			data.address1 = $('#user_form_address1').val();
			data.address2 = $('#user_form_address2').val();
			data.city = $('#user_form_city').val();
			data.state = $('#user_form_state').val();
			data.postalcode = $('#user_form_postalcode').val();
			data.country = $('#user_form_country').val();
			data.startdate = $('#user_form_startdate').val();
			data.enddate = $('#user_form_enddate').val();
			if (user.id === 0) data.invite = $('#user_form_invite_toggle').is(':checked') ? 1 : 0;
			var bad = false;
			$('#user_form').find('div').removeClass('has-error');

			if (data.fname == '') {
				bad = true;
				$('#user_form_fname').parent().addClass('has-error');
			} else {
				$('#user_form_fname').parent().removeClass('has-error');
			}
			if (data.lname == '') {
				bad = true;
				$('#user_form_lname').parent().addClass('has-error');
			} else {
				$('#user_form_lname').parent().removeClass('has-error');
			}
			if (data.email == '' || !validEmail(data.email)) {
				bad = true;
				$('#user_form_email').parent().addClass('has-error');
			} else {
				$('#user_form_email').parent().removeClass('has-error');
			}
			if ($('#user_form_startdate_checkbox').is(':checked') && data.startdate == '') {
				bad = true;
			}
			if ($('#user_form_enddate_checkbox').is(':checked') && data.enddate == '') {
				bad = true;
			}

			if ((data.role != 0 && data.role == '') || data.role == -1 || data.role == null || isNaN(data.role)) {
				bad = true;
				$('#user_form_role').parent().addClass('has-error');
			} else {
				$('#user_form_role').parent().removeClass('has-error');
			}

			if (bad) {
				displayNotification('User Save Error', 'Please fill out all of the required fields.', 'danger');
				return;
			}

			$('#user_form_submit').prop('disabled', true);
			$('#user_form_archive').prop('disabled', true);
			$('#spinner').show();

			try {
				const res = await ajaxPromise('/form/submit', {
					type: 'user_save',
					data,
				});
				if (res.rc !== 'OK') throw res;
				displayNotification('Save Success', 'User saved.', 'success');
				userdt.ajax.reload();
				closeform();
			}
			catch (error) {
				switch (error.rc) {
					case 'BAD_EMAIL':
						displayNotification('Save Error', 'User already exists.', 'danger');
						break;
					case 'OWN_ROLE':
						displayNotification('Save Error', 'You cannot change your own Role.', 'danger');
						break;
					case 'MANAGER_ELEVATE':
						displayNotification('Save Error', 'You cannot edit other Managers or Admins as a Manager.', 'danger');
						break;
					default:
						displayNotification('Save Error', 'There was a general error saving user.', 'danger');
						logerror('user submit', error);
						break;
				}
			}

			$('#user_form_submit').prop('disabled', false);
			$('#user_form_archive').prop('disabled', false);
			$('#spinner').hide();
		});
	}
}

var groupdt: DataTables.Api = null;
if ($('#group_form').length == 1) {
	$('a[href="#tab-groups"]').on('show.bs.tab', function (e) {
		if (groupdt == null) {
			init_groups();
		} else {
			groupdt.ajax.reload();
		}
	});
}

function init_groups() {
	var dtoptions: DataTables.Settings = {
		ajax: {
			url: '/data/groups_load',
			data: (postData) => ({
				...postData,
				inactive: $('#group_search_inactive').is(':checked') ? 1 : 0,
			}),
		},
		columns: [
			{
				title: 'Name',
				data: 'group_name',
			},
			{
				title: 'Summary',
				data: 'group_summary',
				orderable: false,
				searchable: false,
			},
			{
				title: 'Status',
				data: 'group_status_nice',
			},
			{
				title: 'Actions',
				data: 'buttons',
				className: 'text-right',
				orderable: false,
				searchable: false,
			},
		],
		columnDefs: [
			{ responsivePriority: 1, targets: 0 },
			{ responsivePriority: 2, targets: -1 },
		],
		language: {
			info: "Showing <span class='txt-color-darken'>_START_</span> to <span class='txt-color-darken'>_END_</span> of <span class='text-primary'>_TOTAL_</span> groups",
			infoEmpty: 'No groups to show',
			infoFiltered: "(filtered from <span class='txt-color-darken'>_MAX_</span> total groups)",
			lengthMenu: 'Show _MENU_ groups',
		},
		order: [[0, 'asc']],
	};

	groupdt = $('#groups_table')
		.on('init.dt, draw.dt', function () {})
		.DataTable(dtoptions);

	$('#groups_table').on('click', '.group_edit', ({ target }) => {
		const data = getDtRowData(groupdt, target);
		group_edit({
			id: data.group_id,
			name: data.group_name,
			status: parseInt(data.group_status),
			startdate: data.group_startdate,
			enddate: data.group_enddate,
			vendors_count: data.vendors_count,
			members_count: data.members_count,
		});
	});

	$('#groups_table').on('click', '.group_reactivate', ({ target }) => {
		const data = getDtRowData(groupdt, target);
		groupArchive(
			{
				id: data.group_id,
				name: data.group_name,
			},
			GROUP_ACTIVE
		);
	});

	$('#group_search_inactive').on('change', function () {
		setTimeout(function () {
			groupdt.ajax.reload();
		}, 500);
	});

	$('#group_add').off('click');
	$('#group_add').on('click', function (e) {
		group_edit({
			id: 0,
		});
		e.preventDefault();
	});

	const groupArchive = async (group, status: number) => {
		const language: {[status: number]: {[key: string]: string, style: BootstrapFlavor}} = {
			[GROUP_ACTIVE]: {title: 'Reactivate', verb: 'reactivate', action: 'reactivated', verbing: 'reactivating', style: 'success'},
			[GROUP_DELETED]: {title: 'Deactivate', verb: 'deactivate', action: 'deactivated', verbing: 'deactivating', style: 'warning'},
		};
		const {title, verb, action, verbing, style} = language[status];

		const confirmed = await confirmDialog({
			dialogTitle: `Group ${title}`,
			bodyText: `Are you sure you would like to ${verb} group "${group.name}"?`,
			confirmText: title,
			confirmStyle: style,
		});
		if (!confirmed) return;

		try {
			const postData = {
				type: 'group_archive',
				data: {
					id: group.id,
					status,
				},
			};
			const res = await ajaxPromise('/form/submit', postData);
			if (res.rc !== 'OK') throw res;
			displayNotification(`${title} Success`, `The group was ${action} successfully.`, 'success');
			groupdt.ajax.reload();
			closeform();
		} catch (error) {
			displayNotification(`${title} Error`, `There was an error ${verbing} this group.`, 'danger');
			logerror('group archive', error);
		}
	};

	function closeform() {
		$('#group_form :input').val('');
		$('#group_edit_container').collapse('hide');
		$('#group_add').prop('disabled', true);
		$('#groups_table').find('.group_edit').prop('disabled', true);
		$('#group_add').parent().show();
		setTimeout(function () {
			// delay draw because of string data.
			if (groupVendorsDt !== null) {
				groupVendorsDt.destroy();
				groupVendorsDt = null;
			}
			if (groupmemberdt !== null) {
				groupmemberdt.destroy();
				groupmemberdt = null;
			}
			$('#groups_vendors_container').collapse('hide');
			$('#groups_members_container').collapse('hide');
			$('#group_add').prop('disabled', false);
			$('#groups_table').find('.group_edit').prop('disabled', false);
		}, 500);
	}

	let groupVendorsDt: DataTables.Api = null;
	$(window).on('resize', async () => {
		if (!groupVendorsDt) return;
		await setTimeoutPromise(0);
		groupVendorsDt.fixedColumns().relayout()
	});

	let groupvendorlistdt: DataTables.Api = null;
	let groupmemberdt: DataTables.Api = null;
	let groupmemberlistdt: DataTables.Api = null;

	function group_edit(group) {
		//logme('group_edit:');
		//logme(group);

		setTimeout(function () {
			$(window).scrollTop($('#group_edit_container').offset().top - 400);
		}, 400);

		if (groupmemberdt !== null) {
			//logme('groupmemberdt reset');
			groupmemberdt.destroy();
			groupmemberdt = null;
		}
		$('#groups_vendors_container').collapse('hide');
		$('#groups_members_container').collapse('hide');

		if (group.id === 0) {
			$('#group_form_submit').html('Save');
			$('#group_form_name').val('');
			$('#group_vendors_count').html('');
			$('#group_members_count').html('');

			$('#groups_components_container').hide();
			$('#group_form_archive').off('click');
			$('#group_form_archive').hide();
		} // edit
		else {
			$('#group_form_submit').html('Update');
			$('#group_form_name').val(group.name);
			$('#group_vendors_count').html(group.vendors_count);
			$('#group_members_count').html(group.members_count);

			$('#groups_components_container').show();

			// Set the Archive button action
			switch (group.status) {
				case GROUP_ACTIVE:
				default:
					$('#group_form_archive').html('Deactivate');
					$('#group_form_archive').data('status', GROUP_DELETED);
					break;
				case GROUP_DELETED:
					$('#group_form_archive').html('Reactivate');
					$('#group_form_archive').data('status', 0);
					break;
			}

			$('#group_form_archive').show().off('click').on('click', (event) => {
				event.preventDefault();
				groupArchive(group, GROUP_DELETED);
			});
			$('#group_form_archive').show();

			setCollapse($('#group_form_vendors_open'), $('#groups_vendors_container'), function () {
				groupVendorsDt.draw();
			});
			setCollapse($('#group_form_members_open'), $('#groups_members_container'), function () {
				groupmemberdt.draw();
			});

			// group vendor list modal & table

			$('#group_form_vendor_list_load').off('click');
			$('#group_form_vendor_list_load').on('click', function () {
				$('#group_vendor_list_add_all').off('click').on('click', async () => {
					const confirmed = await confirmDialog({
						dialogTitle: 'Add All Vendors',
						bodyText: 'Are you sure you would like to add all active vendors to this group?',
						confirmText: 'Add All',
						confirmStyle: 'success',
					});
					if (!confirmed) return;

					try {
						const postData = {
							type: 'group_vendor_add_all',
							data: {
								group_id: group.id,
								role_active: 1,
							},
						};
						const res = await ajaxPromise('/form/submit', postData);
						if (res.rc !== 'OK') throw res;
						displayNotification('Add Success', 'All active vendors have been added to this group successfully.', 'success');
						groupvendorlistdt.ajax.reload();
						groupVendorsDt.ajax.reload();
						groupdt.ajax.reload();
						$('#group_vendors_count').html(res.count);
					} catch (error) {
						displayNotification('Add Error', 'There was an error adding all active vendors to this group.', 'danger');
						logerror('group vendor add all submit', error);
					}
				});

				$('#group_vendor_list_remove_all').off('click').on('click', async () => {
					const confirmed = await confirmDialog({
						dialogTitle: 'Remove All Vendors',
						bodyText: 'Are you sure you would like to remove all active vendors from this group?',
						confirmText: 'Remove All',
						confirmStyle: 'danger',
					});
					if (!confirmed) return;

					try {
						const postData = {
							type: 'group_vendor_add_all',
							data: {
								group_id: group.id,
								role_active: 0,
							},
						};
						const res = await ajaxPromise('/form/submit', postData);
						if (res.rc !== 'OK') throw res;
						displayNotification('Remove Success', 'All active vendors have been removed from this group successfully.', 'success');
						groupvendorlistdt.ajax.reload();
						groupVendorsDt.ajax.reload();
						groupdt.ajax.reload();
						$('#group_vendors_count').html(res.count);
					} catch (error) {
						displayNotification('Remove Error', 'There was an error removing all active vendors from this group.', 'danger');
						logerror('group vendor remove all submit', error);
					}
				});

				//table
				if (groupvendorlistdt !== null) {
					groupvendorlistdt.destroy();
					groupvendorlistdt = null;
				}

				$('#setup_group_list_vendor_manage_label').text('Manage Vendors in "' + group.name + '"');

				var dtoptions: DataTables.Settings = {
					ajax: {
						url: '/data/group_vendors_list_load',
						data: (postData) => ({
							...postData,
							groupid: group.id,
						}),
					},
					columns: [
						{
							title: 'Vendor',
							data: 'vend_name',
						},
						{
							title: 'Included?',
							data: 'list_select',
							searchable: false,
						},
					],
					order: [
						[1, 'desc'],
						[0, 'asc'],
					],
					responsive: false,
					language: {
						info: "Showing <span class='txt-color-darken'>_START_</span> to <span class='txt-color-darken'>_END_</span> of <span class='text-primary'>_TOTAL_</span> vendors",
						infoEmpty: 'No vendors to show',
						infoFiltered: "(filtered from <span class='txt-color-darken'>_MAX_</span> total vendors)",
						lengthMenu: 'Show _MENU_ vendors',
					},
				};

				function setColorActive(tar) {
					var value = tar.val();
					if (value == 1) {
						tar.addClass('btn-success');
					} else {
						tar.removeClass('btn-success');
					}
				}

				groupvendorlistdt = $('#groups_vendors_list_table')
					.on('init.dt, draw.dt', function () {
						$('#groups_vendors_list_table')
							.find('select')
							.each(function () {
								setColorActive($(this));
							});

						$('#groups_vendors_list_table').find('select').off('change').on('change', async (event) => {
							event.preventDefault();

							const $select = $(event.target);
							setColorActive($select);
							const data = getDtRowData(groupvendorlistdt, $select.get(0));

							const change = {
								group_id: group.id,
								vendor_id: data.vend_id,
								role_active: $select.val(),
							};

							$select.prop('disabled', true);
							$('#spinner').show();

							try {
								const postData = {
									type: 'group_vendor_add',
									data: change,
								};
								const res = await ajaxPromise('/form/submit', postData);
								if (res.rc !== 'OK') throw res;

								displayNotification('Update Success', 'The vendor was updated successfully.', 'success');
								groupVendorsDt.ajax.reload();
								groupdt.ajax.reload();
								$('#group_vendors_count').html(res.count);
							} catch (error) {
								displayNotification('Update Error', 'There was an error updating the vendor.', 'danger');
								logerror('group vendor update submit', error);
							}

							$select.prop('disabled', false);
							$('#spinner').hide();
						});
					})
					.DataTable(dtoptions);

				$('#setup_group_list_vendor_manage_container').modal();
			});

			const roleColumnsMap = {
				role_details_access: ACCESS_DETAILS,
				role_inherent_access: ACCESS_INHERENT,
				role_diligence_access: ACCESS_DILIGENCE,
				role_review_access: ACCESS_REVIEW,
				role_vendorvalue_access: ACCESS_VALUE,
				role_contracts_access: ACCESS_CONTRACTS,
				role_performance_access: ACCESS_ASSESSMENT,
				role_documents_access: ACCESS_DOCUMENTS,
			};
			const roleColumns = Object.keys(roleColumnsMap);

			const roleControl = (col, value) => `
				<select class="role-col form-control btn-success" data-col="${col}">
					<option value="${PRIV_NONE}">None</option>
					<option value="${PRIV_READ}" ${value == PRIV_READ ? 'selected' : ''}>Read</option>
					${col == 'role_documents_access' ? `<option value="${PRIV_WRITE}" ${value == PRIV_WRITE ? 'selected' : ''}>Write</option>` : ''}
					<option value="${PRIV_MANAGE}" ${value == PRIV_MANAGE ? 'selected' : ''}>Manage</option>
				</select>
			`;

			// https://datatables.net/manual/data/orthogonal-data
			const dtColumns: DataTables.ColumnSettings[] = [
				{
					title: 'Vendor',
					data: 'vend_name',
					className: 'pt-col-divider-end',
				},
				{
					title: 'Access/Details',
					data: 'role_details_access',
					render: (value, type) => {
						if (type === 'display') return roleControl('role_details_access', value);
						return value;
					},
					searchable: false,
				},
				{
					title: 'Inherent Risk',
					data: 'role_inherent_access',
					render: (value, type) => {
						if (type === 'display') return roleControl('role_inherent_access', value);
						return value;
					},
					searchable: false,
				},
				{
					title: 'Due Diligence',
					data: 'role_diligence_access',
					render: (value, type) => {
						if (type === 'display') return roleControl('role_diligence_access', value);
						return value;
					},
					searchable: false,
				},
				{
					title: 'Periodic Review',
					data: 'role_review_access',
					render: (value, type) => {
						if (type === 'display') return roleControl('role_review_access', value);
						return value;
					},
					searchable: false,
				},
				{
					title: 'Vendor Value',
					data: 'role_vendorvalue_access',
					render: (value, type) => {
						if (type === 'display') return roleControl('role_vendorvalue_access', value);
						return value;
					},
					searchable: false,
				},
				{
					title: 'Contracts',
					data: 'role_contracts_access',
					render: (value, type) => {
						if (type === 'display') return roleControl('role_contracts_access', value);
						return value;
					},
					searchable: false,
				},
				{
					title: 'SLA / KPI',
					data: 'role_performance_access',
					render: (value, type) => {
						if (type === 'display') return roleControl('role_performance_access', value);
						return value;
					},
					searchable: false,
				},
				{
					title: 'Documents',
					data: 'role_documents_access',
					render: (value, type) => {
						if (type === 'display') return roleControl('role_documents_access', value);
						return value;
					},
					searchable: false,
				},
				{
					title: 'Has Value',
					data: 'has_value',
					visible: false,
					searchable: false,
				},
			];
			const dtColIndicies = dtColumnIndicies(dtColumns);
			const dtOptions: DataTables.Settings = {
				ajax: {
					url: '/data/group_vendors_load',
					data: {
						group_id: group.id,
					},
				},
				columns: dtColumns,
				drawCallback: () => triggerWindowResize(),
				fixedColumns: true,
				initComplete: () => {
					const $scroll = $('#groups_vendors_table_wrapper').find('.dataTables_scroll');
					$scroll.find('.dataTables_scrollBody').doubleScroll({ $insertBefore: $scroll });
				},
				language: {
					info: "Showing <span class='txt-color-darken'>_START_</span> to <span class='txt-color-darken'>_END_</span> of <span class='text-primary'>_TOTAL_</span> vendors",
					infoEmpty: 'No vendors to show',
					infoFiltered: "(filtered from <span class='txt-color-darken'>_MAX_</span> total vendors)",
					lengthMenu: 'Show _MENU_ vendors',
				},
				order: [
					[dtColIndicies.has_value, 'desc'],
					[dtColIndicies.vend_name, 'asc'],
				],
				responsive: false,
				scrollX: true,
			};

			const setColorPriv = ($select: $) => {
				$select.removeClass('btn-success btn-warning btn-info');
				const value = +$select.val();
				const classes = {
					[PRIV_NONE]: '',
					[PRIV_READ]: 'btn-warning',
					[PRIV_WRITE]: 'btn-info',
					[PRIV_MANAGE]: 'btn-success',
				};
				const classForPriv = classes[value];
				$select.addClass(classForPriv);
			};

			const selectChange = async ($select: $) => {
				setColorPriv($select);

				const $row = $select.closest('tr');
				const col = $select.data('col');
				const data = groupVendorsDt.row($row).data() as any;
				const value = +$select.val();

				$('#spinner').show();

				try {
					const postData = {
						group_id: group.id,
						role_id: data.role_id,
						vend_id: data.vend_id,
						privilege_key: roleColumnsMap[col],
						privilege_value: value,
						update_all: 0,
					};
					const res = await ajaxPromise('/form/submit', {
						type: 'group_vendor_save',
						data: postData,
					});
					if (res.rc !== 'OK') throw res;

					displayNotification('Permissions Success', 'Permission changed successfully.', 'success');
					groupVendorsDt.ajax.reload();
				} catch (error) {
					displayNotification('Permissions Error', 'There was an error changing the permission.', 'danger');
					logerror('group permissions submit', error);
				}

				$('#spinner').hide();
			};

			const selectSetAllChange = async ($select: $) => {
				setColorPriv($select);

				if ($select.val() === '') return;

				const col = $select.data('col');
				const value = +$select.val();

				const resetSelect = () => {
					$select.val('');
					setColorPriv($select);
				};

				const postData = {
					group_id: group.id,
					role_id: null,
					vend_id: null,
					privilege_key: roleColumnsMap[col],
					privilege_value: value,
					update_all: 1,
				};

				const title = dtColumns.find(({ data }) => data === col).title;
				const allPrivTexts = {
					[PRIV_NONE]: 'None',
					[PRIV_READ]: 'Read',
					[PRIV_WRITE]: 'Write',
					[PRIV_MANAGE]: 'Manage',
				};
				const privText = allPrivTexts[value];

				const confirmed = await confirmDialog({
					dialogTitle: `Change ${title} Permissions`,
					bodyText: `Are you sure you would like to change all <b>${title}</b> permissions to <b>${privText}</b>?`,
					confirmText: 'Save',
					confirmStyle: 'success',
				});
				if (!confirmed) return;

				try {
					const res = await ajaxPromise('/form/submit', {
						type: 'group_vendor_save',
						data: postData,
					});
					if (res.rc !== 'OK') throw res;

					displayNotification('Permissions Success', 'All permissions for this group have been changed successfully.', 'success');
					groupVendorsDt.ajax.reload();
				} catch (error) {
					resetSelect();
					displayNotification('Permissions Error', 'There was an error changing the permissions.', 'danger');
					logerror('group set all permissions submit', error);
				}
			};

			if (groupVendorsDt) groupVendorsDt.clear().destroy();
			groupVendorsDt = $('#groups_vendors_table').DataTable(dtOptions);

			$('#groups_vendors_container').off('draw.dt').on('draw.dt', () => {
				$('#groups_vendors_table select, #groups_vendors_table_select_all_row select').each((_, element) => setColorPriv($(element)));
				$('#groups_vendors_table_select_all_row')
					.find('select')
					.off('change')
					.on('change', ({ target }) => selectSetAllChange($(target)));
				groupVendorsDt
					.rows()
					.eq(0)
					.each((index) => {
						const row = groupVendorsDt.row(index);
						const data = row.data();
					});
				$('#groups_vendors_table')
					.find('select')
					.off('change')
					.on('change', ({ target }) => selectChange($(target)));
			});

			$('#group_form_member_list_load').off('click');
			$('#group_form_member_list_load').on('click', function () {
				$('#group_member_list_add_all').off('click').on('click', async () => {
					const confirmed = await confirmDialog({
						dialogTitle: 'Add All Users',
						bodyText: 'Are you sure you would like to add all active users as members of this group?',
						confirmText: 'Add All',
						confirmStyle: 'success',
					});
					if (!confirmed) return;

					try {
						const postData = {
							type: 'group_member_add_all',
							data: {
								group_id: group.id,
								member_active: 1,
							},
						};
						const res = await ajaxPromise('/form/submit', postData);
						if (res.rc !== 'OK') throw res;

						displayNotification('Group Edit Success', 'All active users have been added as members of this group.', 'success');
						groupmemberlistdt.ajax.reload();
						groupmemberdt.ajax.reload();
						groupdt.ajax.reload();
						$('#group_members_count').html(res.count);
					} catch (error) {
						displayNotification('Group Edit Error', 'There was an error adding users to this group.', 'danger');
						logerror('group member add all submit', error);
					}
				});

				$('#group_member_list_remove_all').off('click').on('click', async () => {
					const confirmed = await confirmDialog({
						dialogTitle: 'Remove All Users',
						bodyText: 'Are you sure you would like to remove all active users as members of this group?',
						confirmText: 'Remove All',
						confirmStyle: 'danger',
					});
					if (!confirmed) return;

					try {
						const postData = {
							type: 'group_member_add_all',
							data: {
								group_id: group.id,
								member_active: 0,
							},
						};
						const res = await ajaxPromise('/form/submit', postData);
						if (res.rc !== 'OK') throw res;

						displayNotification('Group Edit Success', 'All active users have been removed from this group.', 'success');
						groupmemberlistdt.ajax.reload();
						groupmemberdt.ajax.reload();
						groupdt.ajax.reload();
						$('#group_members_count').html(res.count);
					} catch (error) {
						displayNotification('Group Edit Error', 'There was an error removing users from this group.', 'danger');
						logerror('group member remove all submit', error);
					}
				});

				//table
				if (groupmemberlistdt !== null) {
					groupmemberlistdt.destroy();
					groupmemberlistdt = null;
				}

				var dtoptions: DataTables.Settings = {
					ajax: {
						url: '/data/group_members_list_load',
						data: (postData) => ({
							...postData,
							groupid: group.id,
						}),
					},
					columns: [
						{
							title: 'First',
							data: 'usr_firstname',
						},
						{
							title: 'Last',
							data: 'usr_lastname',
						},
						{
							title: 'Email',
							data: 'usr_email',
						},
						{
							title: 'Included?',
							data: 'list_select',
							searchable: false,
						},
					],
					language: {
						info: "Showing <span class='txt-color-darken'>_START_</span> to <span class='txt-color-darken'>_END_</span> of <span class='text-primary'>_TOTAL_</span> users",
						infoEmpty: 'No users to show',
						infoFiltered: "(filtered from <span class='txt-color-darken'>_MAX_</span> total users)",
						lengthMenu: 'Show _MENU_ users',
					},
					order: [
						[3, 'desc'],
						[1, 'asc'],
						[0, 'asc'],
						[2, 'asc'],
					],
					responsive: false,
				};

				function setColorActive(tar) {
					var value = tar.val();
					if (value == 1) {
						tar.addClass('btn-success');
					} else {
						tar.removeClass('btn-success');
					}
				}

				groupmemberlistdt = $('#groups_members_list_table')
					.on('init.dt, draw.dt', function () {
						$('#groups_members_list_table')
							.find('select')
							.each(function () {
								setColorActive($(this));
							});

						$('#groups_members_list_table').find('select').off('change').on('change', async (event) => {
							const $select = $(event.target);
							setColorActive($select);
							const data = getDtRowData(groupmemberlistdt, $select.get(0));

							const change = {
								group_id: group.id,
								user_id: data.usr_id,
								member_active: $select.val(),
							};

							$select.prop('disabled', true);
							$('#spinner').show();

							try {
								const postData = {
									type: 'group_member_add',
									data: change,
								};
								const res = await ajaxPromise('/form/submit', postData);
								if (res.rc !== 'OK') throw res;

								displayNotification('Membership Edit', 'The membership has been changed successfully.', 'success');
								groupmemberdt.ajax.reload();
								groupdt.ajax.reload();
								$('#group_members_count').html(res.count);
							} catch (error) {
								displayNotification('Membership Edit Error', 'There was an error changing the membership.', 'danger');
								logerror('group member update submit', error);
							}

							$select.prop('disabled', false);
							$('#spinner').hide();
						});
					})
					.DataTable(dtoptions);

				$('#setup_group_list_member_manage_container').modal();
			});

			// groups_members_table
			var dtoptions: DataTables.Settings = {
				ajax: {
					url: '/data/group_members_load',
					data: (postData) => ({
						...postData,
						groupid: group.id,
					}),
				},
				columns: [
					{
						title: 'First',
						data: 'usr_firstname',
					},
					{
						title: 'Last',
						data: 'usr_lastname',
					},
					{
						title: 'Email',
						data: 'usr_email',
					},
					{
						title: 'Phone',
						data: 'usr_phone',
						orderable: false,
					},
				],
				language: {
					info: "Showing <span class='txt-color-darken'>_START_</span> to <span class='txt-color-darken'>_END_</span> of <span class='text-primary'>_TOTAL_</span> members",
					infoEmpty: 'No members to show',
					infoFiltered: "(filtered from <span class='txt-color-darken'>_MAX_</span> total members)",
					lengthMenu: 'Show _MENU_ members',
				},
				order: [
					[1, 'asc'],
					[0, 'asc'],
					[2, 'asc'],
				],
			};

			const setColorBi = (tar) => {
				var value = parseInt(tar.val());
				if (value == 1) {
					tar.addClass('btn-success');
				} else {
					tar.removeClass('btn-success');
				}
			}

			groupmemberdt = $('#groups_members_table')
				.on('init.dt, draw.dt', function () {
					$('#groups_members_table')
						.find('select')
						.each(function () {
							setColorBi($(this));
						});

					$('#groups_members_table').find('select').off('change').on('change', async (event) => {
						event.preventDefault();

						const $select = $(event.target);
						setColorBi($select);
						const data = getDtRowData(groupmemberdt, $select.get(0));

						const change = {
							member_id: data.member_id,
							member_privilege: $select.val(),
							user_id: data.usr_id,
							group_id: group.id,
						};

						$select.prop('disabled', true);
						$('#spinner').show();

						try {
							const postData = {
								type: 'group_member_save',
								data: change,
							};
							const res = await ajaxPromise('/form/submit', postData);
							if (res.rc !== 'OK') throw res;

							displayNotification('Member Edit Success', 'Membership edit successful.', 'success');
							groupmemberdt.ajax.reload();
						} catch (error) {
							displayNotification('Member Edit Error', 'There was an error editing this membership.', 'danger');
							logerror('group member submit', error);
						}

						$select.prop('disabled', false);
						$('#spinner').hide();
					});
				})
				.DataTable(dtoptions);
		}
		setTimeout(function () {
			// delay draw because of string data.
			$('#group_edit_container').collapse('show');
			$('#group_add').parent().hide();
		}, 250);

		$('#group_form_cancel').off('click');
		$('#group_form_cancel').on('click', function () {
			closeform();
		});

		$('#group_form_submit').off('click').on('click', async (event) => {
			event.preventDefault();

			const data = {
				...JSON.parse(JSON.stringify(group)),
				name: $('#group_form_name').val(),
			};

			if (data.name == null || data.name.trim() == '') {
				displayNotification('Group Save Error', 'Please enter a name for this group.', 'danger');
				return;
			}

			$('#group_form_submit').prop('disabled', true);
			$('#spinner').show();

			try {
				const postData = {
					type: 'group_save',
					data,
				};
				const res = await ajaxPromise('/form/submit', postData);
				if (res.rc !== 'OK') throw res;

				displayNotification('Group Save', 'Group name saved.', 'success');
				groupdt.ajax.reload();
				closeform();
			} catch (error) {
				displayNotification('Group Save Error', 'There was a problem saving this group.', 'danger');
				logerror('group submit', error);
			}

			$('#group_form_submit').prop('disabled', false);
			$('#spinner').hide();
		});
	}
}
