import { VENDOR_ACTIVE, VENDOR_PENDING } from './constants';
import { ajaxPromise, confirmDialog, displayNotification, getDtRowData, highlight, htmlEsc, logerror } from './utils';

const initBulkExport = () => {
	$('#bulkexport_form select.chosen').chosen({ width: '100%' });

	$('#bulkexport_add').off('click').on('click', (event) => {
		event.preventDefault();
		bulkExportEdit();
	});

	const dtOptions: DataTables.Settings = {
		ajax: {
			url: '/data/bulkexports_load',
		},
		columns: [
			{
				title: 'Title',
				data: 'be_title',
			},
			{
				title: 'User',
				data: 'be_usrname',
			},
			{
				title: 'Status',
				data: 'be_status_nice',
			},
			{
				title: 'Request Date',
				data: 'be_requestdate_nice',
			},
			{
				title: 'Ready Date',
				data: 'be_readydate_nice',
			},
			{
				title: 'Expiration Date',
				data: 'be_expiredate_nice',
			},
			{
				title: 'Actions',
				data: 'buttons',
				className: 'text-right',
				orderable: false,
				searchable: false,
			},
		],
		columnDefs: [
			{ targets: 0, responsivePriority: 1 },
			{ targets: -1, responsivePriority: 2, render: null  },
			{ targets: '_all', render: (val) => htmlEsc(val)  },
		],
		order: [[3, 'desc']],
	};

	const bulkExportDt = $('#bulkexport_table').DataTable(dtOptions);

	$('#bulkexport_table').on('click', '.bulkexport_delete', ({ target }) => {
		const rowData = getDtRowData(bulkExportDt, target);
		bulkExportDelete({
			title: rowData.be_title,
			guid: rowData.be_guid,
		});
	});

	const bulkExportDelete = async (be: {title: string, guid: string}) => {
		const confirmed = await confirmDialog({
			dialogTitle: 'Export Delete',
			bodyText: `Are you sure you would like to delete the export "${be.title}"?`,
			confirmText: 'Delete',
			confirmStyle: 'danger',
		});
		if (!confirmed) return;

		try {
			const postData = {
				type: 'bulkexport_delete',
				data: {
					guid: be.guid,
				},
			};
			const res = await ajaxPromise('/form/submit', postData);
			if (res.rc !== 'OK') throw res;

			displayNotification('Delete Success', 'The export was deleted successfully.', 'success');
			bulkExportDt.ajax.reload();
		} catch (error) {
			displayNotification('Delete Error', 'There was an error deleting this export.', 'danger');
			logerror('export delete', error);
		}
	};

	const closeBulkExportEdit = () => {
		$('#bulkexport_form_title').val('');
		$('#bulkexport_filter_categories').val('').trigger('chosen:updated');
		$('#bulkexport_filter_vendors').val('').trigger('chosen:updated');
		$('#bulkexport_filter_inherent').val('').trigger('chosen:updated');
		$('#bulkexport_filter_residual').val('').trigger('chosen:updated');
		$('#bulkexport_filter_status').val([VENDOR_ACTIVE.toString(), VENDOR_PENDING.toString()]).trigger('chosen:updated');
		$('#bulkexport_filter_critical').val(-1).trigger('chosen:updated');
		$('#bulkexport_include_inherent').prop('checked', true);
		$('#bulkexport_include_duediligence').prop('checked', true);
		$('#bulkexport_include_periodicreview').prop('checked', true);
		$('#bulkexport_edit_container').collapse('hide');
		$('#bulkexport_add').parent().show();
	};

	const bulkExportProcess = async (guid: string) => {
		$('#spinner').show();

		try {
			const postData = {
				type: 'bulkexport_process',
				data: {
					guid,
				},
			};
			const res = await ajaxPromise('/form/submit', postData);
			if (res.rc !== 'OK') throw res;
			displayNotification('Export Ready', 'The export was processed and is now ready for download.', 'success');
			bulkExportDt.ajax.reload();
		} catch (error) {
			logerror('bulkexport process', error);
		}

		$('#spinner').hide();
	};

	const bulkExportEdit = () => {
		setTimeout(() => {
			highlight($('#bulkexport_edit_container'));
			$(window).scrollTop($('#bulkexport_edit_container').offset().top - 400);
		}, 400);

		$('#bulkexport_edit_container').collapse('show');
		$('#bulkexport_add').parent().hide();

		$('#bulkexport_form_cancel').off('click').on('click', () => {
			closeBulkExportEdit();
			return false;
		});

		$('#bulkexport_form_submit').off('click').on('click', async (event) => {
			event.preventDefault();

			const data = {
				id: 0,
				title: $('#bulkexport_form_title').val(),
				options: {
					filters: {
						category: $('#bulkexport_filter_categories').val(),
						vendors: $('#bulkexport_filter_vendors').val(),
						inherent: $('#bulkexport_filter_inherent').val(),
						residual: $('#bulkexport_filter_residual').val(),
						status: $('#bulkexport_filter_status').val() !== '' ? $('#bulkexport_filter_status').val() : -1,
						critical: $('#bulkexport_filter_critical').val() !== '' ? $('#bulkexport_filter_critical').val() : -1,
					},
					includes: {
						inherent: $('#bulkexport_include_inherent').is(':checked') ? 1 : 0,
						diligence: $('#bulkexport_include_duediligence').is(':checked') ? 1 : 0,
						review: $('#bulkexport_include_periodicreview').is(':checked') ? 1 : 0,
					},
				},
			};

			if (data.title == null || data.title.length == 0) {
				displayNotification('Save Error', 'Please fill out the export title.', 'danger');
				return;
			}

			// Submit the form
			$('#bulkexport_form_submit').prop('disabled', true);
			$('#spinner').show();

			try {
				const postData = {
					type: 'bulkexport_save',
					data,
				};
				const res = await ajaxPromise('/form/submit', postData);
				if (res.rc !== 'OK') throw res;
				displayNotification('Save Success', 'The export was requested successfully.', 'success');
				bulkExportDt.ajax.reload();
				closeBulkExportEdit();
				bulkExportProcess(res.guid);
			} catch (error) {
				displayNotification('Save Error', 'There was an error processing this request.', 'danger');
				logerror('bulkexport submit', error);
			}

			$('#bulkexport_form_submit').prop('disabled', false);
			$('#spinner').hide();
		});
	};
};

if ($('#bulkexport_form').length == 1) {
	initBulkExport();
}
