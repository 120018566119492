import { ASSESSMENT_VENDORVALUE } from './constants';
import { historyTable } from './history';
import { ajaxPromise, confirmDialog, displayNotification, htmlEsc, logerror, post } from './utils';

if ($('.response_form_outer_container').length) {
	$('.response_form_outer_container').each(function () {
		init_response($(this));
	});
}

function init_response($main) {
	// Assessment Response
	let dirty = false;

	let response = JSON.parse(atob($main.find('.response_form_container').data('params')));
	const isExternal = response.is_external;
	const showSubmit = response.show_submit;
	delete response.is_external;
	delete response.show_submit;

	response.answers = [];

	if (response.vend_id) {
		$('#task_add_modal').on('show.bs.modal', () => {
			$('#task_add_modal').find('#task_add_vendor').val([response.vend_id]).trigger('chosen:updated');
		});
	}

	//logme(response);

	$(document).on('scroll', function () {
		if ($(document).scrollTop() > 160) {
			$main.find('#scroll-top').show();
		} else {
			$main.find('#scroll-top').hide();
		}
	});

	$main
		.find('#response_export')
		.off('click')
		.on('click', () => {
			post('/export/response', { surveyresp_guid: response.resp_guid }, '_blank');
		});

	$main
		.find('#response_print')
		.off('click')
		.on('click', () => {
			post('/printout/assessment', { surveyresp_guid: response.resp_guid }, '_blank');
		});

	$main.find('#scroll-top-btn').off('click');
	$main.find('#scroll-top-btn').on('click', function () {
		$(window).scrollTop(0);
	});

	$main.find('#response_form .answer_importance_tooltip').popover({
		html: true,
		content: $main.find('#importance_tooltip_content').html(),
		trigger: 'hover focus',
	});

	$main.find('.response_form_question_add').on('click', function () {
		let section = $main.find(this).parent();
		let clone = $main.find('#response_form_question_template>div').clone(true);
		section.find('p.noquestions').remove();
		section.append(clone).show();
		return false;
	});

	$main.find('.response_form_question_remove').on('click', function () {
		$main.find(this).closest('.response_form_question_custom').remove();
		return false;
	});

	if (response.resp_id !== null && response.resp_guid !== null) {
		// In-progress Assessment
		checkQuestionDisplay();

		if (checkComplete()) {
			$main.find('#response_form_lock').show();
			$main.find('#response_form_lock').on('click', function (e) {
				lock($main.find(this), e);
			});
		}

		$main.find('#response_form select.answer_value').chosen({ width: '100%' });
	}

	function checkQuestionDisplay() {
		$main.find('#response_form [data-hidden]').hide();
		$main.find('#response_form select').each((_, select) => {
			const $select = $(select);

			// Show targets that are selected
			if ($select.children('option:selected').data('target')) {
				const ref = $select.children(':selected').data('target').split(',');
				ref.forEach((target) => {
					const $target = $main.find("#response_form [data-questionid='" + target + "']");
					if ($target.length == 1) {
						$target.data('hidden', 0);
						$target.show();
					}
				});
			}
		});
	}

	// Completed Assessment
	$main.find('#response_form .answer').each((_, element) => {
		const $answer = $(element);
		if (!$answer.data('target')) return;
		const ref = JSON.parse(atob($answer.data('target')));
		ref.forEach((target) => {
			const $target = $main.find("#response_form [data-questionid='" + target + "']");
			if ($target.length == 1) {
				$target.data('hidden', 0);
				$target.show();
			}
		});
	});

	const saveProgress = async ({ taskSubmit = false } = {}) => {
		let data = JSON.parse(JSON.stringify(response));

		//empty og answers if present.
		data.answers = [];

		let bad = [];

		$main.find('#response_form [data-questionid]').find('select').parent().removeClass('has-error');

		$main.find('#response_form [data-questionid]').each((_, element) => {
			const $question = $(element);
			if (parseInt($question.data('hidden')) === 1) return;

			const value = $question.find('select.answer_value').val() != null && $question.find('select.answer_value').val() != '' ? $question.find('select.answer_value').val() : '';
			const importance = $question.find('select.answer_importance').val() != null && $question.find('select.answer_importance').val() != '' ? parseFloat($question.find('select.answer_importance').val().toString()) : '';

			if (value == '' && importance == '') return;

			const ans: Record<string, any> = {
				guid: $question.data('questionid'),
				value,
				text: $question.find('select.answer_value > :selected').length == 1 ? $question.find('select.answer_value > :selected').text() : '',
				comment: $question.find('textarea.answer_comment').length == 1 ? $question.find('textarea.answer_comment').val() : '', //Not required
			};

			// Include Importance if in Vendor Value
			if (parseInt(data.type) == ASSESSMENT_VENDORVALUE) {
				ans.importance = importance;
			}

			// Check the other required fields, assuming value is set
			if (ans.guid == '' || ans.text == '' || ans.value == '' || (parseInt(data.type) == ASSESSMENT_VENDORVALUE && ans.text != 'N/A' && ans.importance == '')) {
				bad.push($question);
				$question.find('select').parent().addClass('has-error');
				return;
			}

			// All set!
			data.answers.push(ans);
		});

		if (!bad.length && !data.answers.length) {
			//No answers (good or bad)
			displayNotification('Assessment', 'Please fill out an answer.', 'danger');
			return;
		}

		if (bad.length) {
			displayNotification('Assessment', 'Please fill out each response defined.', 'danger');
			return;
		}

		data.reviewedby = $main.find('#response_form_reviewedby').val() || response.reviewedby;
		data.date = $main.find('#response_form_date').val();
		data.complete = checkComplete() ? 1 : 0;
		data.task_submit = taskSubmit ? 1 : 0;

		if (isExternal && taskSubmit) {
			const confirmed = await confirmDialog({
				dialogTitle: 'Assessment Submit',
				bodyText: 'Are you sure you would like to save and submit this assessment?  The assessment will be closed.',
				confirmText: 'Submit',
				confirmStyle: 'success',
				cancelText: 'Cancel',
			});
			if (!confirmed) return;
		}

		// Submit the form
		$main.find('#response_form_submit').prop('disabled', true);
		$main.find('#response_form_spinner').show();

		try {
			const postData = {
				type: 'response_save',
				data,
			};
			const res = await ajaxPromise('/form/submit', postData);
			if (res.rc !== 'OK') throw res;

			response.resp_guid = res.guid;
			dataCheckDirty(false);
			if (historyTable) historyTable.ajax.reload();
			displayNotification('Assessment', 'Assessment progress saved.', 'success');
			if (isExternal && taskSubmit) window.location.reload();
			if (isExternal && !taskSubmit) {
				await confirmDialog({
					dialogTitle: 'Assessment Saved',
					bodyText: 'Your progress has been saved.  You can continue editing and submit the assessment when you have finished, or close this window if you have finished but would like to leave the assessment open for others.',
					confirmText: 'Ok',
					confirmStyle: 'primary',
					showCancelButton: false,
				});
			}
		} catch (error) {
			displayNotification('Assessment', 'There was an error saving progress.', 'danger');
			logerror('response submit', error);
		}

		$main.find('#response_form_spinner').hide();
		$main.find('#response_form_submit').prop('disabled', false);
	};

	function checkComplete() {
		const $incompleteQuestion = $main
			.find('#response_form [data-questionid]')
			.toArray()
			.find((element: HTMLElement) => {
				const $question = $(element);
				if (parseInt($question.data('hidden')) === 1) return false; // Conditional/hidden questions don't count

				const $value = $question.find('select.answer_value');
				const value = $value.val() != null ? $value.val() : '';
				const $importance = $question.find('select.answer_importance');
				const importance = $importance.val() != null ? parseFloat($importance.val().toString()) : '';

				if (value == '' && importance == '') return true; //Incomplete question found

				const ans = {
					guid: $question.data('questionid'),
					value,
					text: $value.children(':selected').length == 1 ? $value.children(':selected').text() : '',
					importance,
				};

				// Check the other required fields, assuming value is set
				return ans.guid == '' || ans.text == '' || ans.value == '' || (parseInt(response.type) == ASSESSMENT_VENDORVALUE && ans.text != 'N/A' && ans.importance == '');
			});

		return !$incompleteQuestion || !!$incompleteQuestion.length;
	}

	function lock(btn, e) {
		if (checkComplete()) {
			locking($main.find(this), 1);
		} else {
			displayNotification('Assessment', 'Cannot lock, all available questions must be answered first.', 'danger');
		}
		e.preventDefault();
	}

	function dataCheckDirty(isdirty) {
		dirty = isdirty;
		if (isdirty || showSubmit) {
			$main.find('#response_form_submit').fadeIn(300);
			$main.find('#response_form_task_submit_external').fadeIn(300);
			$main.find('#response_form_submit').off('click');
			$main.find('#response_form_submit').on('click', (event) => {
				event.preventDefault();
				saveProgress();
			});
			$main.find('#response_form_cancel').text('Cancel & Return To Vendor');

			$main.find('#response_form_lock').hide();
			$main.find('#response_form_lock').off('click');
		} else {
			// is clean to lock
			if (checkComplete()) {
				$main.find('#response_form_lock').fadeIn(300);
				$main.find('#response_form_lock').off('click');
				$main.find('#response_form_lock').on('click', function (e) {
					lock($main.find(this), e);
				});
			}
			$main.find('#response_form_cancel').text('Return To Vendor');

			$main.find('#response_form').off('submit');
			if (!isExternal) $main.find('#response_form_submit').hide();
		}
	}

	$main
		.find('#response_form_task_submit_external')
		.off('click')
		.on('click', (event) => {
			event.preventDefault();
			saveProgress({ taskSubmit: true });
		});

	$main.find('#response_form :input').on('change', function () {
		checkQuestionDisplay();
		dataCheckDirty(true);
	});

	$main.find('#response_form_reviewedby, #response_form_date').on('change', function () {
		dataCheckDirty(true);
	});

	$main.find('#response_form_question_template :input').on('change', function () {
		dataCheckDirty(true);
	});

	function closeform() {
		if (dirty) {
			$main.find('#response_form_cancel_modal_confirm').off('click');
			$main.find('#response_form_cancel_modal_confirm').on('click', function () {
				$main.find('#response_form :input').val('');
				post('/ui/vendor_edit', { id: response.vend_id });
			});
			$main.find('#response_form_cancel_modal').modal();
		} else {
			$main.find('#response_form :input').val('');
			let t = '';
			switch (parseInt(response.type)) {
				case 1:
					t = 'inherent';
					break;
				case 2:
					t = 'residual';
					break;
				case 3:
					t = 'review';
					break;
				case 4:
					t = 'vendvalue';
					break;
			}
			post('/ui/vendor_edit', { id: response.vend_id, start: t });
		}
	}

	const locking = async ($btn: $, status: number) => {
		$btn.prop('disabled', true);

		try {
			const postData = {
				type: 'response_lock',
				data: {
					resp_guid: response.resp_guid,
					status,
					type: response.type,
				},
			};
			const res = await ajaxPromise('/form/submit', postData);
			if (res.rc !== 'OK') throw res;
			post('/ui/assessment', {
				surv_guid: response.resp_guid,
				vend_id: response.vend_id,
				cat_id: response.cat_id,
				type: response.type,
				review: response.review,
			});
		} catch (error) {
			switch (error.rc) {
				case 'NO_BASIS':
					displayNotification('Assessment', 'All answers cannot be N/A.', 'danger');
					break;
				default:
					displayNotification('Assessment', 'There was an error marking this Assessment complete.', 'danger');
					logerror('response lock', error);
			}
		}

		$btn.prop('disabled', false);
	};

	if ($main.find('#response_form_unlock').length == 1) {
		$main.find('#response_form_unlock').off('click');
		$main.find('#response_form_unlock').on('click', function (e) {
			locking($main.find(this), 0);
			e.preventDefault();
		});
		if ($main.find('#response_form_override_update').length == 1) {
			// Disable override reason if override is empty
			if ($main.find('#response_form_override').val() == '') {
				$main.find('#response_form_override_reason').prop('disabled', true);
			} else {
				$main.find('#response_form_override_reason').prop('disabled', false);
			}

			// Disable override reason if override is empty, also on change
			$main.find('#response_form_override').off('change');
			$main.find('#response_form_override').on('change', function () {
				if ($main.find(this).val() == '') {
					$main.find('#response_form_override_reason').val('');
					$main.find('#response_form_override_reason').prop('disabled', true);
				} else {
					$main.find('#response_form_override_reason').prop('disabled', false);
				}
			});

			$main.find('#response_form_override_update').off('click').on('click', async () => {
				const overrideId = $main.find('#response_form_override').val();
				const overrideTitle = $main.find('#response_form_override > option:selected').text();
				const reason = $main.find('#response_form_override_reason').val();

				if (overrideId != '' && reason == '') {
					displayNotification('Override', 'Please provide a reason for the override.', 'danger');
					return;
				}

				try {
					const postData = {
						type: 'response_override',
						data: {
							resp_guid: response.resp_guid,
							override: overrideId,
							reason,
						},
					};
					const res = await ajaxPromise('/form/submit', postData);
					if (res.rc !== 'OK') throw res;

					displayNotification('Override', 'Override set.', 'success');
					const rating = $main.find('#response_rating').data('rating');
					if (overrideId == '' || overrideId == null) {
						$main.find('#response_rating').html(htmlEsc(rating));
					} else {
						$main.find('#response_rating').html(htmlEsc(`${overrideTitle} (scored ${rating})`));
					}
					if (historyTable) historyTable.ajax.reload();
				} catch (error) {
					displayNotification('Override', 'There was an error setting the override.', 'danger');
					logerror('response override submit', error);
				}
			});
		}
	}

	$main.find('#response_form_cancel').off('click');
	$main.find('#response_form_cancel').on('click', function (e) {
		closeform();
		e.preventDefault();
	});

	if (isExternal && showSubmit) dataCheckDirty(false);
}

if ($('#assessment_print_container').length == 1) {
	setTimeout(function () {
		window.print();
	}, 1000);
}
